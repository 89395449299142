import React from "react";

export default function PublicFooter() {
  return (
    <div
      style={{
        textAlign: "center",
        color: "grey",
        fontSize: "0.8em",
        marginTop: 50,
        marginBottom: 30
      }}
    >
      &#169; 2018-2020 ePAYNS | Yayasan Islam Darul Ehsan
      <br />
      Tel: 03-55103931 &#183; Fax: 03-55101245 &#183; Email: info@yide.com.my
    </div>
  );
}
