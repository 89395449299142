import React from "react";
import { Button, Classes } from "@blueprintjs/core";

export default function DeletePrompt({ title, message, proceed }) {
  return (
    <div style={{ padding: 15 }}>
      <p>
        <b>{title}</b>
      </p>
      <p>{message}</p>
      <div style={{ textAlign: "right" }}>
        <Button style={{ marginRight: 5 }} className={Classes.POPOVER_DISMISS}>
          Batal
        </Button>
        <Button intent="danger" onClick={proceed} className={Classes.POPOVER_DISMISS}>
          Ya, Hapus
        </Button>
      </div>
    </div>
  );
}
