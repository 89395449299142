import React from "react";
import { Dialog, Button } from "@blueprintjs/core";
import AppToaster from "../components/AppToaster";
import axios from "axios";
import { API_URL } from "../constant";
import serialize from "form-serialize";

class Modal extends React.Component {
  state = {
    loadingData: true,
    senaraiDaerah: [],
    senaraiBank: [],
    loadingSubmit: false
  };

  submit = async e => {
    e.preventDefault();

    try {
      this.setState({
        loadingSubmit: true
      });

      const data = serialize(e.target, { hash: true, empty: true, disabled: true });

      if (data.nama === "") {
        throw Error("Nama masjid tidak boleh dibiarkan kosong");
      }

      const res = await axios.post(
        `${API_URL}/masjid/new`,
        {
          data
        },
        {
          withCredentials: true
        }
      );

      if (!res.data.success) {
        throw Error(res.data.message);
      }

      this.setState({
        loadingSubmit: false
      });

      AppToaster.show({
        message: "Rekod telah berjaya ditambah",
        intent: "success",
        icon: "tick"
      });

      this.props.closeFn(true);
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign"
      });

      this.setState({
        loadingSubmit: false
      });
    }
  };

  fetch = async () => {
    try {
      const res = await Promise.all([axios.get(`${API_URL}/bank`), axios.get(`${API_URL}/daerah`)]);

      this.setState({
        loadingData: false,
        senaraiBank: res[0].data.result,
        senaraiDaerah: res[1].data.result
      });
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign"
      });
    }
  };

  render = () => {
    const { isOpen, closeFn, daerahCode } = this.props;
    const { loadingData, senaraiBank, senaraiDaerah } = this.state;

    return (
      <Dialog
        transitionDuration={0}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        isOpen={isOpen}
        title="Daftar Masjid Baru"
        style={{ width: 800 }}
        onOpening={this.fetch}
        onClose={() => {
          closeFn();
        }}
      >
        <div style={{ padding: 20 }}>
          {loadingData ? (
            "Loading..."
          ) : (
            <form onSubmit={this.submit}>
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td colSpan={2}>Butiran Umum</td>
                  </tr>
                  <tr>
                    <td style={{ width: 180 }}>
                      <b>Nama Masjid</b>
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        type="text"
                        name="nama"
                        className="bp3-input bp3-fill"
                        autoComplete="off"
                        required
                        placeholder="Nama Masjid"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Alamat</b>
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        type="text"
                        name="alamat"
                        className="bp3-input bp3-fill"
                        autoComplete="off"
                        placeholder="Alamat"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Daerah</b>
                    </td>
                    <td>:</td>
                    <td>
                      <div className="bp3-select">
                        <select
                          style={{ minWidth: 300 }}
                          defaultValue={daerahCode}
                          name="daerahCode"
                          disabled
                        >
                          {senaraiDaerah.map(daerah => (
                            <option key={daerah.code} value={daerah.code}>
                              {daerah.description}
                            </option>
                          ))}
                        </select>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Bandar</b>
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        type="text"
                        name="bandar"
                        className="bp3-input bp3-fill"
                        autoComplete="off"
                        placeholder="Bandar"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>No.Telefon</b>
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        type="text"
                        name="noTelefon"
                        className="bp3-input bp3-fill"
                        autoComplete="off"
                        placeholder="No Telefon"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>No.Faks</b>
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        type="text"
                        name="noFaks"
                        className="bp3-input bp3-fill"
                        autoComplete="off"
                        placeholder="No Faks"
                      />
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={2} style={{ paddingTop: 15 }}>
                      Nazir
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Nama Nazir</b>
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        type="text"
                        name="namaNazir"
                        className="bp3-input bp3-fill"
                        autoComplete="off"
                        placeholder="Nama Nazir"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>No Telefon Nazir</b>
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        type="text"
                        name="noTelefonNazir"
                        className="bp3-input bp3-fill"
                        autoComplete="off"
                        placeholder="No Telefon Nazir"
                      />
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={2} style={{ paddingTop: 15 }}>
                      Imam
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Nama Imam</b>
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        type="text"
                        name="namaImam"
                        className="bp3-input bp3-fill"
                        autoComplete="off"
                        placeholder="Nama Imam"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>No Telefon Imam</b>
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        type="text"
                        name="noTelefonImam"
                        className="bp3-input bp3-fill"
                        autoComplete="off"
                        placeholder="No Telefon Imam"
                      />
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={2} style={{ paddingTop: 15 }}>
                      Setiausaha
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Nama Setiausaha</b>
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        type="text"
                        name="namaSetiausaha"
                        className="bp3-input bp3-fill"
                        autoComplete="off"
                        placeholder="Nama Setiausaha"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>No Telefon Setiausaha</b>
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        type="text"
                        name="noTelefonSetiausaha"
                        className="bp3-input bp3-fill"
                        autoComplete="off"
                        placeholder="No Telefon Setiausaha"
                      />
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={2} style={{ paddingTop: 15 }}>
                      Bendahari
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Nama Bendahari</b>
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        type="text"
                        name="bendahari"
                        className="bp3-input bp3-fill"
                        autoComplete="off"
                        placeholder="Nama Bendahari"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>No Telefon Bendahari</b>
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        type="text"
                        name="noTelefonBendahari"
                        className="bp3-input bp3-fill"
                        autoComplete="off"
                        placeholder="No Telefon Bendahari"
                      />
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={2} style={{ paddingTop: 15 }}>
                      Maklumat Bank
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Nama Bank</b>
                    </td>
                    <td>:</td>
                    <td>
                      <div className="bp3-select">
                        <select style={{ minWidth: 300 }} name="bankId">
                          <option value="">--Tiada--</option>
                          {senaraiBank.map(bank => (
                            <option key={bank._id} value={bank._id}>
                              {bank.description}
                            </option>
                          ))}
                        </select>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>No Akaun</b>
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        type="text"
                        name="noAkaunBank"
                        className="bp3-input bp3-fill"
                        autoComplete="off"
                        placeholder="No Akaun Bank"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Pemilik Akaun</b>
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        type="text"
                        name="namaPemilikAkaunBank"
                        className="bp3-input bp3-fill"
                        autoComplete="off"
                        placeholder="Nama Pemilik Akaun Bank"
                      />
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={2} style={{ paddingTop: 15 }}>
                      Lain-Lain
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Emel</b>
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        type="text"
                        name="emel"
                        className="bp3-input bp3-fill"
                        autoComplete="off"
                        placeholder="Emel"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Website</b>
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        type="text"
                        name="website"
                        className="bp3-input bp3-fill"
                        autoComplete="off"
                        placeholder="Website"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Facebook</b>
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        type="text"
                        name="facebook"
                        className="bp3-input bp3-fill"
                        autoComplete="off"
                        placeholder="Facebook"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <Button
                intent="primary"
                style={{ float: "right", marginTop: 20 }}
                icon="tick"
                type="submit"
              >
                Simpan
              </Button>
            </form>
          )}
        </div>
      </Dialog>
    );
  };
}

export default Modal;
