import React from "react";
import { Route } from "react-router-dom";
import { TOKEN_KEY, TOKEN_EXPIRE_KEY, TOKEN_EXPIRE_MINUTE } from "../constant";
import Public from "../pages/Public";
import moment from "moment";
import resetExpire from "../reset-expire";

const PrivateRoute = ({ component: Component, ...rest }) => {
  let internalProceed = false;
  const tokenExists = localStorage.getItem(TOKEN_KEY) ? true : false;
  const tokenExpire = localStorage.getItem(TOKEN_EXPIRE_KEY);

  if (tokenExists && tokenExpire) {
    // bandingkan dengan masa semasa
    // jika lebih dari 1 jam (60 saat), logout

    const past = moment(tokenExpire);
    const now = moment();
    const minutesDiff = now.diff(past, "minutes");

    if (minutesDiff <= TOKEN_EXPIRE_MINUTE) {
      resetExpire();
      internalProceed = true;
    } else {
      localStorage.clear();
    }
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (internalProceed) {
          return <Component {...props} />;
        } else {
          return <Public />;
          //return <Redirect to={{ pathname: "/", state: { from: props.location } }} />;
        }
      }}
    />
  );
};

export default PrivateRoute;
