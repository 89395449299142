import React, { useEffect, useState } from "react";
import Internal from "../components/Internal";
import { Card, HTMLTable, HTMLSelect, Spinner } from "@blueprintjs/core";
import AppToaster from "../components/AppToaster";
import { API_URL } from "../constant";
import axios from "axios";
import moment from "moment";
import "moment/locale/ms-my";
import { Bar } from "react-chartjs-2";
import { SEBAB_KEMATIAN } from "../constant";

const data = {
  labels: [],
  datasets: [
    {
      label: "Anak Yatim",
      backgroundColor: "rgba(58, 130, 132,0.5)",
      borderColor: "rgba(35, 90, 91,1)",
      borderWidth: 1,
      hoverBackgroundColor: "rgba(255,99,132,0.4)",
      hoverBorderColor: "rgba(255,99,132,1)",
      data: [],
    },
  ],
};

export default function Ringkasan() {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [year, setYear] = useState(moment().format("YYYY"));
  const [labels, setLabels] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [jumlahKematian, setJumlahKematian] = useState({});

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);

        // chart
        const res = await axios.get(`${API_URL}/ringkasan/tahun/${year}`, {
          withCredentials: true,
        });

        setLabels(res.data.labels);
        setChartData(res.data.data);
        setList(res.data.list || []);
        setJumlahKematian(res.data.yatim);
        setLoading(false);
      } catch (e) {
        AppToaster.show({
          message: e.message,
          intent: "danger",
          icon: "warning-sign",
        });

        setLoading(false);
      }
    }

    fetchData();
  }, [year]);

  let totalLelaki = 0;
  let totalPerempuan = 0;
  let totalTakpasti = 0;

  data.labels = labels;
  data.datasets[0].data = chartData;

  /* useEffect(() => {
    if (loading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [loading]); */

  return (
    <>
      <Internal />

      {loading && (
        <div
          style={{
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: 90,
            left: 0,
            right: 0,
            bottom: 0,
            background: "rgba(255,255,255, .7)",
            zIndex: 99,
          }}
        >
          <Spinner />
        </div>
      )}

      <div className="wrapper fixed" style={{ marginBottom: 30 }}>
        <h2>Ringkasan</h2>

        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td colSpan="3" style={{ paddingTop: 5, paddingRight: 0 }}>
                <Card>
                  <div style={{ textAlign: "center" }}>
                    <div>
                      Bilangan Anak Yatim Seluruh Negeri Tahun
                      <HTMLSelect
                        style={{ marginLeft: 10 }}
                        value={year}
                        onChange={(e) => setYear(e.target.value)}
                      >
                        <YearSelection />
                      </HTMLSelect>
                    </div>
                    <p style={{ marginTop: 10 }}>
                      Berumur 16 tahun dan ke bawah pada tarikh semasa:{" "}
                      {moment().format("D MMMM YYYY")}
                    </p>
                  </div>
                  <Bar
                    data={data}
                    width={100}
                    height={300}
                    options={{
                      maintainAspectRatio: false,
                    }}
                  />
                </Card>
                <Card style={{ marginTop: 20 }}>
                  <HTMLTable
                    interactive
                    striped
                    small
                    style={{ border: "1px solid lightgrey", width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th>Daerah</th>
                        <th style={{ textAlign: "center" }}>Lelaki</th>
                        <th style={{ textAlign: "center" }}>Perempuan</th>
                        <th style={{ textAlign: "center" }}>
                          Tidak Dikenalpasti
                        </th>
                        <th style={{ textAlign: "center" }}>Jumlah</th>
                      </tr>
                    </thead>
                    <tbody>
                      {list.map((rec, index) => {
                        totalLelaki += rec.lelaki;
                        totalPerempuan += rec.perempuan;
                        totalTakpasti += rec.tidakDikenalpasti;

                        return (
                          <tr key={index}>
                            <td>{rec.daerahName}</td>
                            <td style={{ textAlign: "center" }}>
                              {rec.lelaki}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {rec.perempuan}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {rec.tidakDikenalpasti}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {rec.lelaki +
                                rec.perempuan +
                                rec.tidakDikenalpasti}
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td>
                          <b>JUMLAH</b>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <b>{totalLelaki}</b>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <b>{totalPerempuan}</b>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <b>{totalTakpasti}</b>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <b>{totalLelaki + totalPerempuan + totalTakpasti}</b>
                        </td>
                      </tr>
                    </tbody>
                  </HTMLTable>
                </Card>
              </td>
            </tr>
          </tbody>
        </table>

        <Card style={{ marginTop: 20 }}>
          <h3 style={{ margin: 0, marginBottom: 15, color: "#333" }}>
            Jumlah Berdasarkan Kematian
          </h3>
          <HTMLTable
            interactive
            striped
            small
            style={{ border: "1px solid lightgrey", width: "100%" }}
          >
            <thead>
              <tr>
                <th>
                  Sebab Kematian
                  <br />
                  <small style={{ fontWeight: "normal" }}>
                    <i>(berdasarkan sebab kematian bapa)</i>
                  </small>
                </th>
                <th style={{ textAlign: "center" }}>Jumlah</th>
              </tr>
            </thead>
            <tbody>
              {SEBAB_KEMATIAN.map((jenis) => (
                <tr key={jenis}>
                  <td style={{ textTransform: "capitalize" }}>
                    {jenis.toLowerCase()}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {jumlahKematian[jenis] || 0}
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td>Jumlah Anak Yatim</td>
                <td style={{ textAlign: "center" }}>
                  {jumlahKematian["YATIM_JUMLAH"] || 0}
                </td>
              </tr>
            </tfoot>
          </HTMLTable>
        </Card>
      </div>
    </>
  );
}

function YearSelection() {
  let elem = [];
  let currentYear = Number(moment().format("YYYY"));
  let minYear = 2018;

  for (let y = minYear; y <= currentYear; y++) {
    elem.push(
      <option key={y} value={y}>
        {y}
      </option>
    );
  }

  return elem;
}
