import React from "react";
import { Button } from "@blueprintjs/core";
import AppToaster from "../components/AppToaster";
import axios from "axios";
import { API_URL, TOKEN_KEY } from "../constant";
import { withRouter } from "react-router-dom";
import resetExpire from "../reset-expire";

class PublicHeader extends React.Component {
  state = {
    showLoginFields: false,
    loadingLogin: false,
    loadingLogout: false
  };

  login = async e => {
    e.preventDefault();

    const username = e.target.yide_username.value;
    const password = e.target.yide_password.value;

    try {
      if (!username || username === "") {
        throw Error("Sila masukkan username");
      }

      if (!password || password === "") {
        throw Error("Sila masukkan password");
      }

      const res = await axios.post(
        `${API_URL}/login`,
        {
          username,
          password
        },
        {
          withCredentials: true
        }
      );

      this.setState({
        loadingLogin: true
      });

      if (!res.data.success) {
        throw Error(res.data.message);
      }

      localStorage.setItem(TOKEN_KEY, res.data.token);
      localStorage.setItem("yide_username", username);
      resetExpire();

      this.props.history.replace("/");
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign"
      });

      this.setState({
        loadingLogin: false
      });
    }
  };

  render = () => {
    const { showLoginFields, loadingLogin, loadingLogout } = this.state;
    const { loggedIn } = this.props;

    return (
      <React.Fragment>
        <div className="public topbar">
          <div className="wrapper">
            <p className="left">
              Sistem Pengurusan Maklumat Anak Yatim Negeri Selangor
            </p>
            <p className="right">Yayasan Islam Darul Ehsan</p>
          </div>
        </div>

        <div className="public secondbar">
          <div className="wrapper">
            <img className="logo" src="/logo.png" alt="Logo" />

            {loggedIn ? (
              <div className="user-info">
                <span>
                  Selamat datang, <b>{localStorage.getItem("yide_username")}</b>
                  .
                </span>
                <br />
                <Button
                  icon="log-out"
                  small
                  onClick={this.logout}
                  loading={loadingLogout}
                >
                  Logout
                </Button>
              </div>
            ) : showLoginFields ? (
              <form className="login" onSubmit={this.login}>
                <input
                  className="bp3-input"
                  type="text"
                  placeholder="Username"
                  name="yide_username"
                  style={{ width: 200 }}
                  ref="loginUsername"
                  disabled={loadingLogin}
                />
                <input
                  className="bp3-input"
                  type="password"
                  placeholder="Password"
                  name="yide_password"
                  style={{ width: 200, marginLeft: 5 }}
                  disabled={loadingLogin}
                />
                <Button
                  type="submit"
                  style={{ marginLeft: 5 }}
                  intent="primary"
                  loading={loadingLogin}
                >
                  Login
                </Button>
                <Button
                  style={{ marginLeft: 5 }}
                  minimal
                  onClick={() =>
                    this.setState({
                      showLoginFields: false,
                      loadingLogin: false
                    })
                  }
                >
                  Batal
                </Button>
              </form>
            ) : (
              <Button
                className="button-login"
                icon="lock"
                onClick={() => {
                  this.setState({ showLoginFields: true }, () => {
                    this.refs.loginUsername.focus();
                  });
                }}
              >
                Staff Login
              </Button>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  };
}

export default withRouter(PublicHeader);
