import React from "react";
import { Tooltip, Button } from "@blueprintjs/core";
import { withRouter } from "react-router-dom";

const OperasiTitle = props => (
  <h2>
    <Tooltip content="Kembali ke Operasi" position="bottom" hoverOpenDelay={500}>
      <Button
        icon="arrow-left"
        style={{ marginRight: 10 }}
        onClick={() => props.history.replace("/operasi")}
      />
    </Tooltip>
    {props.title}
  </h2>
);
export default withRouter(OperasiTitle);
