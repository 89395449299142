import React from "react";
import { Spinner, Callout, Button } from "@blueprintjs/core";

const InternalPageLoading = props => {
  if (props.error) {
    return (
      <Callout intent="danger" title="Error">
        <p>
          Berlaku masalah teknikal yang tidak dapat dikenalpasti.
          <br />
          Sila cuba sekali lagi.
        </p>
        {props.retryFn ? (
          <Button onClick={props.retryFn} intent="danger" style={{ margin: "15px 0" }}>
            Cuba Lagi
          </Button>
        ) : null}
      </Callout>
    );
  }

  return (
    <div style={{ textAlign: "center" }}>
      <Spinner intent="primary" size={25} />
      <p style={{ margin: "10px 0" }}>Loading...</p>
    </div>
  );
};

export default InternalPageLoading;
