import React from "react";
import Internal from "../components/Internal";
import InternalPageLoading from "../components/InternalPageLoading";
import OperasiTitle from "../components/OperasiTitle";
import { Card, Button, Switch, HTMLSelect, NumericInput, Popover } from "@blueprintjs/core";
import axios from "axios";
import { API_URL } from "../constant";
import AppToaster from "../components/AppToaster";
import moment from "moment";
import replaceall from "replaceall";
import ModalButiranPendaftaran from "../modals/ButiranPendaftaran";
import ModalEditPendaftaran from "../modals/EditPendaftaran";
import ReactTable, { ReactTableDefaults } from "react-table";
import DeletePrompt from "../components/DeletePrompt";

Object.assign(ReactTableDefaults, {
  NextComponent: state => (
    <Button fill rightIcon="fast-forward" disabled={state.disabled} onClick={state.onClick}>
      Next
    </Button>
  ),
  PreviousComponent: state => (
    <Button fill icon="fast-backward" disabled={state.disabled} onClick={state.onClick}>
      Previous
    </Button>
  )
});

class StatusSumbangan extends React.Component {
  state = {
    loadingPage: true,
    senaraiSumbangan: [],
    jenis: "3",
    tahun: moment().format("YYYY"),
    senaraiDaerah: [],
    selectedDaerahCode: "01",
    loadingPapar: false,
    selectedMasjidId: "",
    senaraiMasjid: [],
    senaraiTitle: "",
    noKpPenjaga: "",
    noKpAnakYatim: "",
    noSiriBorang: "",
    openButiranPendaftaran: false,
    butiranPendaftaranId: null,
    openEditPendaftaran: false,
    editPendaftaranId: null,
    showResult: false,
    loadingChangeStatusId: null,
    loadingSenaraiMasjidParam: false,
    loadingHapusId: false
  };

  componentDidMount = () => {
    this.initParam();
  };

  initParam = async () => {
    this.setState({
      loadingPage: true
    });

    try {
      const { selectedDaerahCode } = this.state;

      const res = await Promise.all([
        axios.get(`${API_URL}/daerah`),
        axios.get(`${API_URL}/masjid/daerah/${selectedDaerahCode}`)
      ]);

      for (let n = 0; n < res.length; n++) {
        if (!res[n].data.success) {
          throw Error(res[n].data.message);
        }
      }

      this.setState({
        loadingPage: false,
        senaraiDaerah: res[0].data.result,
        senaraiMasjid: res[1].data.result,
        selectedMasjidId: res[1].data.result[0]._id
      });
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign"
      });
    }
  };

  changeStatus = async ({
    //pendaftaranIndex,
    //anakYatimIndex,
    //pendaftaranId,
    anakYatimId,
    value
  }) => {
    let { tahun } = this.state;

    this.setState({
      loadingChangeStatusId: anakYatimId
    });

    try {
      const res = await axios.post(
        `${API_URL}/sumbangan/status/ubah`,
        { serahSumbangan: value, anakYatimId, tahun },
        { withCredentials: true }
      );

      if (!res.data.success) {
        throw Error(res.data.message);
      }

      this.setState(prevState => {
        let { senaraiSumbangan } = prevState;
        let record = senaraiSumbangan.find(r => r.anakYatimId === anakYatimId);

        if (record) {
          record.serahSumbangan = value;
        }

        return {
          senaraiSumbangan,
          loadingChangeStatusId: null
        };
      });
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign"
      });

      this.setState({
        loadingChangeStatusId: null
      });
    }
  };

  handleChange = e => {
    const { name, value } = e.target;

    this.setState(
      {
        [name]: value
      },
      () => {
        if (name === "jenis") {
          this.setState({
            showResult: false
          });
        } else if (name === "selectedDaerahCode") {
          this.fetchSenaraiMasjidParam(value);
        }
      }
    );
  };

  fetchSenaraiMasjidParam = async daerahCode => {
    try {
      this.setState({
        loadingSenaraiMasjidParam: true
      });

      const res = await axios.get(`${API_URL}/masjid/daerah/${daerahCode}`);

      if (!res.data.success) {
        throw Error(res.data.message);
      }

      this.setState({
        loadingSenaraiMasjidParam: false,
        senaraiMasjid: res.data.result,
        selectedMasjidId: res.data.result.length > 0 ? res.data.result[0]._id : null
      });
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign"
      });

      this.setState({
        loadingSenaraiMasjidParam: false
      });
    }
  };

  paparSenarai = async () => {
    const {
      jenis,
      tahun,
      selectedDaerahCode,
      senaraiDaerah,
      selectedMasjidId,
      noKpPenjaga,
      noKpAnakYatim,
      noSiriBorang
    } = this.state;

    try {
      this.setState({
        loadingPapar: true
      });

      let res;
      let senaraiTitle = "";
      let daerah;
      let modNoSiriBorang;

      switch (jenis) {
        // case "1":
        //   res = await axios.get(`${API_URL}/sumbangan/${tahun}/status/belum`);
        //   senaraiTitle = `Senarai Anak Yatim - Sumbangan Belum Diagih Tahun ${tahun}`;
        //   break;

        // case "2":
        //   res = await axios.get(`${API_URL}/sumbangan/${tahun}/status/sudah`);
        //   senaraiTitle = `Senarai Anak Yatim - Sumbangan Sudah Diagih Tahun ${tahun}`;
        //   break;

        case "3":
          res = await axios.get(`${API_URL}/sumbangan/${tahun}/daerah/${selectedDaerahCode}`, {
            withCredentials: true
          });
          daerah = senaraiDaerah.find(d => d.code === selectedDaerahCode);
          senaraiTitle = `Senarai Anak Yatim - Daerah ${daerah.description} Tahun ${tahun}`;
          break;

        case "4":
          res = await axios.get(`${API_URL}/sumbangan/${tahun}/masjid/${selectedMasjidId}`, {
            withCredentials: true
          });
          daerah = senaraiDaerah.find(d => d.code === selectedDaerahCode);
          senaraiTitle = `Senarai Anak Yatim - Daerah ${daerah.description} Tahun ${tahun}`;
          break;

        case "5":
          if (!noKpPenjaga || noKpPenjaga === "") {
            throw Error("Sila masukkan No. Kad Pengenalan/Passport Penjaga");
          }

          res = await axios.get(`${API_URL}/sumbangan/nokp/penjaga/${noKpPenjaga}`, {
            withCredentials: true
          });
          senaraiTitle = `Senarai Anak Yatim - No.KP Penjaga: "${noKpPenjaga}"`;
          break;

        case "6":
          if (!noKpAnakYatim || noKpAnakYatim === "") {
            throw Error("Sila masukkan No. Kad Pengenalan/Passport Anak Yatim");
          }
          res = await axios.get(`${API_URL}/sumbangan/nokp/anak/${noKpAnakYatim}`, {
            withCredentials: true
          });
          senaraiTitle = `Senarai Anak Yatim - No.KP Anak Yatim: "${noKpAnakYatim}"`;
          break;

        case "7":
          if (!noSiriBorang || noSiriBorang === "") {
            throw Error("Sila masukkan No. Siri Borang Pendaftaran");
          }

          modNoSiriBorang = replaceall("/", "-", noSiriBorang).trim();

          res = await axios.get(`${API_URL}/sumbangan/nosiri/${modNoSiriBorang}`, {
            withCredentials: true
          });
          senaraiTitle = `Senarai Anak Yatim - No.Siri: "${noSiriBorang}"`;
          break;

        case "8":
          res = await axios.get(`${API_URL}/sumbangan/semua/tahun/${tahun}`, {
            withCredentials: true
          });
          senaraiTitle = `Senarai Anak Yatim - Tahun ${tahun}"`;
          break;

        default:
          break;
      }

      if (!res.data.success) {
        throw Error(res.data.message);
      }

      this.setState({
        loadingPapar: false,
        senaraiSumbangan: res.data.result,
        senaraiTitle,
        showResult: true
      });
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign"
      });

      this.setState({
        loadingPapar: false
      });
    }
  };

  showModalDetailPendaftaran = pendaftaranId => {
    this.setState({
      openButiranPendaftaran: true,
      butiranPendaftaranId: pendaftaranId
    });
  };

  closeModalDetailPendaftaran = () => {
    this.setState({
      openButiranPendaftaran: false,
      butiranPendaftaranId: null
    });
  };

  showModalEditPendaftaran = pendaftaranId => {
    this.setState({
      openEditPendaftaran: true,
      editPendaftaranId: pendaftaranId
    });
  };

  closeModalEditPendaftaran = refetch => {
    this.setState({
      openEditPendaftaran: false,
      editPendaftaranId: null
    });

    if (refetch) {
      this.paparSenarai();
    }
  };

  stopPropagation = e => {
    e.stopPropagation();
  };

  hapus = async row => {
    console.log(row.original);
    const { index } = row;

    try {
      const { anakYatimId, anakYatimNoKp } = row.original;

      this.setState({ loadingHapusId: anakYatimId });

      const res = await axios.post(
        `${API_URL}/sumbangan/hapus`,
        {
          anakYatimId,
          anakYatimNoKp
        },
        {
          withCredentials: true
        }
      );

      if (!res.data.success) {
        throw Error(res.data.message);
      }

      this.setState(prevState => {
        let { senaraiSumbangan } = prevState;
        senaraiSumbangan.splice(index, 1);

        return {
          senaraiSumbangan,
          loadingHapusId: null
        };
      });
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign"
      });

      this.setState({ loadingHapusId: null });
    }
  };

  render = () => {
    const {
      loadingPage,
      senaraiSumbangan,
      jenis,
      tahun,
      selectedDaerahCode,
      senaraiDaerah,
      loadingPapar,
      senaraiMasjid,
      selectedMasjidId,
      senaraiTitle,
      noKpPenjaga,
      noKpAnakYatim,
      noSiriBorang,
      openButiranPendaftaran,
      butiranPendaftaranId,
      editPendaftaranId,
      openEditPendaftaran,
      showResult,
      loadingChangeStatusId,
      loadingSenaraiMasjidParam,
      loadingHapusId
    } = this.state;

    return (
      <React.Fragment>
        <Internal />

        <ModalButiranPendaftaran
          isOpen={openButiranPendaftaran}
          closeFn={this.closeModalDetailPendaftaran}
          pendaftaranId={butiranPendaftaranId}
        />

        <ModalEditPendaftaran
          isOpen={openEditPendaftaran}
          closeFn={this.closeModalEditPendaftaran}
          pendaftaranId={editPendaftaranId}
        />

        <div className="wrapper" style={{ marginBottom: 50 }}>
          <OperasiTitle title="Senarai Anak Yatim & Status Sumbangan" />
          {loadingPage ? (
            <InternalPageLoading />
          ) : (
            <React.Fragment>
              <Card style={{ marginBottom: 15 }}>
                <p>
                  <b>Papar senarai anak yatim :</b>
                </p>

                <table>
                  <tbody>
                    <tr>
                      <td style={{ width: 130 }}>Berdasarkan </td>
                      <td>
                        <HTMLSelect
                          disabled={loadingPapar || loadingSenaraiMasjidParam}
                          value={jenis}
                          options={[
                            // { value: "1", label: "Sumbangan Belum Diagih" },
                            // { value: "2", label: "Sumbangan Sudah Diagih" },
                            { value: "8", label: "Semua" },
                            { value: "3", label: "Daerah" },
                            { value: "4", label: "Daerah dan Masjid" }
                            // { value: "5", label: "Nombor Kad Pengenalan/Passport Penjaga" },
                            // { value: "6", label: "Nombor Kad Pengenalan/Passport Anak Yatim" }
                            // { value: "7", label: "Nombor Siri Borang" }
                          ]}
                          style={{ width: 500 }}
                          name="jenis"
                          onChange={this.handleChange}
                        />
                      </td>
                    </tr>

                    {jenis === "3" || jenis === "4" ? (
                      <tr>
                        <td>Daerah</td>
                        <td>
                          <div className="bp3-select">
                            <select
                              style={{ width: 500 }}
                              onChange={this.handleChange}
                              value={selectedDaerahCode}
                              name="selectedDaerahCode"
                              disabled={loadingPapar || loadingSenaraiMasjidParam}
                            >
                              {senaraiDaerah.map(daerah => (
                                <option key={daerah.code} value={daerah.code}>
                                  {daerah.description}
                                </option>
                              ))}
                            </select>
                          </div>
                        </td>
                      </tr>
                    ) : null}

                    {jenis === "4" ? (
                      <tr>
                        <td>Masjid</td>
                        <td>
                          <div className="bp3-select">
                            <select
                              style={{ minWidth: 500 }}
                              onChange={this.handleChange}
                              value={selectedMasjidId}
                              name="selectedMasjidId"
                              disabled={loadingPapar || loadingSenaraiMasjidParam}
                            >
                              {senaraiMasjid.map(masjid => (
                                <option key={masjid._id} value={masjid._id}>
                                  {masjid.bandar && masjid.bandar !== ""
                                    ? `${masjid.nama} - (${masjid.bandar})`
                                    : masjid.nama}
                                </option>
                              ))}
                            </select>
                          </div>
                        </td>
                      </tr>
                    ) : null}

                    {jenis === "8" ||
                    jenis === "1" ||
                    jenis === "2" ||
                    jenis === "3" ||
                    jenis === "4" ? (
                      <tr>
                        <td>Tahun </td>
                        <td>
                          <NumericInput
                            value={tahun}
                            disabled={loadingPapar || loadingSenaraiMasjidParam}
                            placeholder="Tahun"
                            onValueChange={val => this.setState({ tahun: val })}
                            maxLength={4}
                            minLength={4}
                            min={2018}
                          />
                        </td>
                      </tr>
                    ) : null}

                    {jenis === "5" ? (
                      <tr>
                        <td>No.KP Penjaga</td>
                        <td>
                          <input
                            type="text"
                            className="bp3-input"
                            placeholder="No Kad Pengenalan/Passport Penjaga"
                            name="noKpPenjaga"
                            disabled={loadingPapar}
                            value={noKpPenjaga}
                            onChange={this.handleChange}
                            style={{ width: 500 }}
                          />
                        </td>
                      </tr>
                    ) : null}

                    {jenis === "6" ? (
                      <tr>
                        <td>No.KP Anak Yatim</td>
                        <td>
                          <input
                            type="text"
                            className="bp3-input"
                            placeholder="No Kad Pengenalan/Passport Anak Yatim"
                            name="noKpAnakYatim"
                            disabled={loadingPapar}
                            value={noKpAnakYatim}
                            onChange={this.handleChange}
                            style={{ width: 500 }}
                          />
                        </td>
                      </tr>
                    ) : null}

                    {jenis === "7" ? (
                      <tr>
                        <td>No. Siri Borang</td>
                        <td>
                          <input
                            type="text"
                            className="bp3-input"
                            placeholder="No Siri Borang"
                            name="noSiriBorang"
                            disabled={loadingPapar}
                            value={noSiriBorang}
                            onChange={this.handleChange}
                            style={{ width: 500 }}
                          />
                        </td>
                      </tr>
                    ) : null}

                    <tr>
                      <td />
                      <td>
                        <Button
                          intent="primary"
                          style={{ marginTop: 13 }}
                          onClick={this.paparSenarai}
                          loading={loadingPapar}
                          disabled={loadingSenaraiMasjidParam}
                        >
                          Papar Senarai
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card>

              {showResult ? (
                <Card>
                  <h4 style={{ margin: "10px 0" }}>{senaraiTitle}</h4>
                  Panduan:
                  <ul>
                    <li>Klik pada mana-mana rekod untuk melihat butiran penuh.</li>
                    <li>Status sumbangan boleh ditukar di ruang status.</li>
                  </ul>
                  <ReactTable
                    className="-highlight -striped"
                    noDataText={
                      <p style={{ margin: 0, textAlign: "center" }}>Tiada data untuk dipaparkan</p>
                    }
                    rowsText="Rekod"
                    pageText="Muka"
                    ofText="/"
                    data={senaraiSumbangan}
                    columns={[
                      {
                        Header: "No",
                        accessor: "no",
                        width: 50,
                        className: "alignCenter",
                        Cell: row => row.index + 1
                      },
                      {
                        Header: "Nama Penjaga",
                        accessor: "namaPenjaga",
                        filterable: true,
                        filterMethod: (state, record) => {
                          const { value } = state;
                          const { namaPenjaga } = record;
                          const regex = new RegExp(value, "gi");
                          return regex.test(namaPenjaga);
                        }
                      },
                      {
                        Header: "NoKP Penjaga",
                        accessor: "noKadPengenalanPenjaga",
                        filterable: true,
                        filterMethod: (state, record) => {
                          const { value } = state;
                          const { noKadPengenalanPenjaga } = record;
                          const regex = new RegExp(value, "gi");
                          return regex.test(noKadPengenalanPenjaga);
                        }
                      },
                      {
                        Header: "Nama Anak Yatim",
                        accessor: "anakYatim",
                        filterable: true,
                        filterMethod: (state, record) => {
                          const { value } = state;
                          const { anakYatim } = record;
                          const regex = new RegExp(value, "gi");
                          return regex.test(anakYatim);
                        }
                      },
                      {
                        Header: "NoKP Anak Yatim",
                        accessor: "anakYatimNoKp",
                        filterable: true,
                        filterMethod: (state, record) => {
                          const { value } = state;
                          const { anakYatimNoKp } = record;
                          const regex = new RegExp(value, "gi");
                          return regex.test(anakYatimNoKp);
                        }
                      },
                      {
                        Header: "Daerah",
                        accessor: "daerahDescription"
                      },
                      {
                        Header: "Status",
                        accessor: "serahSumbangan",
                        Cell: row => {
                          return (
                            <Switch
                              label={row.value ? "Sudah Diserah" : "Belum Diserah"}
                              checked={row.value || false}
                              disabled={loadingChangeStatusId === row.original.anakYatimId}
                              onChange={e => {
                                this.changeStatus({
                                  anakYatimId: row.original.anakYatimId,
                                  value: e.target.checked
                                  // pendaftaranIndex: index,
                                  // anakYatimIndex: indexAnak,
                                  // pendaftaranId: rekod._id,
                                  // anakYatimId: anak._id,
                                  // value: e.target.checked
                                });
                              }}
                            />
                          );
                        }
                      },
                      {
                        Header: "Hapus",
                        accessor: "hapus",
                        className: "alignCenter",
                        width: 100,
                        Cell: row => (
                          <Popover
                            minimal
                            content={
                              <DeletePrompt
                                title="Hapus Rekod"
                                message={
                                  <React.Fragment>
                                    <span>Hapus maklumat anak yatim ini?</span>
                                  </React.Fragment>
                                }
                                proceed={() => this.hapus(row)}
                              />
                            }
                            target={
                              <Button
                                small
                                icon="cross"
                                intent="danger"
                                minimal
                                loading={loadingHapusId === row.original.anakYatimId}
                              >
                                Hapus
                              </Button>
                            }
                          />
                        )
                      }
                    ]}
                  />
                </Card>
              ) : null}
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  };
}

export default StatusSumbangan;
