import React from "react";
import Internal from "../components/Internal";
import InternalPageLoading from "../components/InternalPageLoading";
import OperasiTitle from "../components/OperasiTitle";
import { Card, FormGroup, Switch, Divider, TextArea, Button, Callout } from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import AppToaster from "../components/AppToaster";
import moment from "moment";
import axios from "axios";
import { API_URL } from "../constant";

const MAXYEAR = Number(moment().format("YYYY")) + 5;
const MAXDATE = moment(`3112${MAXYEAR}`, "DDMMYYYY").toDate();

class OperasiTetapan extends React.Component {
  state = {
    loadingPage: true,
    loadingPageError: false,
    kandunganPengumuman: "",
    tarikhPengumuman: null,
    senaraiPengumuman: [],
    tambahPengumumanDisabled: true,
    bukaPendaftaran: false,
    tarikhAkhirPendaftaran: null,
    tarikhAkhirHantarBorang: null
  };

  componentDidMount = () => {
    this.fetch();
  };

  fetch = async () => {
    try {
      this.setState({
        loadingPage: true,
        loadingPageError: false
      });

      const res = await axios.get(`${API_URL}/tetapan`);
      const {
        pengumuman,
        bukaPendaftaran,
        tarikhAkhirPendaftaran,
        tarikhAkhirHantarBorang
      } = res.data.result;

      this.setState({
        loadingPage: false,
        senaraiPengumuman: pengumuman,
        bukaPendaftaran,
        tarikhAkhirPendaftaran: moment(tarikhAkhirPendaftaran).toDate(),
        tarikhAkhirHantarBorang: moment(tarikhAkhirHantarBorang).toDate()
      });
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign"
      });

      this.setState({
        loadingPageError: true
      });
    }
  };

  tambahPengumuman = () => {
    const { kandunganPengumuman, tarikhPengumuman } = this.state;

    if (kandunganPengumuman === "") return;
    if (tarikhPengumuman === "" || tarikhPengumuman === null) return;

    this.setState(prevState => {
      let { senaraiPengumuman } = prevState;

      senaraiPengumuman.push({
        date: tarikhPengumuman,
        content: kandunganPengumuman
      });

      return { senaraiPengumuman, kandunganPengumuman: "", tarikhPengumuman: null };
    });
  };

  hapusPengumuman = index => {
    this.setState(prevState => {
      let { senaraiPengumuman } = prevState;
      senaraiPengumuman.splice(index, 1);
      return { senaraiPengumuman };
    });
  };

  simpan = async () => {
    const {
      bukaPendaftaran,
      tarikhAkhirPendaftaran,
      senaraiPengumuman,
      tarikhAkhirHantarBorang
    } = this.state;

    try {
      if (bukaPendaftaran) {
        if (!tarikhAkhirPendaftaran || tarikhAkhirPendaftaran === "") {
          throw Error("Sila masukkan tarikh tutup pendaftaran.");
        }

        if (!tarikhAkhirHantarBorang || tarikhAkhirHantarBorang === "") {
          throw Error("Sila masukkan tarikh akhir hantar borang.");
        }
      }

      this.setState({
        loadingSimpan: true
      });

      const res = await axios.post(
        `${API_URL}/tetapan`,
        {
          bukaPendaftaran,
          tarikhAkhirPendaftaran: moment(tarikhAkhirPendaftaran).format("DDMMYYYY"),
          tarikhAkhirHantarBorang: moment(tarikhAkhirHantarBorang).format("DDMMYYYY"),
          pengumuman: senaraiPengumuman
        },
        { withCredentials: true }
      );

      if (!res.data.success) {
        throw Error(res.data.message);
      }

      this.setState({
        loadingSimpan: false
      });

      AppToaster.show({
        message: "Tetapan telah berjaya disimpan",
        intent: "success",
        icon: "tick",
        canEscapeKeyClear: false
      });
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign"
      });

      this.setState({
        loadingSimpan: false
      });
    }
  };

  render() {
    const {
      loadingPage,
      loadingPageError,
      kandunganPengumuman,
      tarikhPengumuman,
      senaraiPengumuman,
      tarikhAkhirPendaftaran,
      bukaPendaftaran,
      loadingSimpan,
      tarikhAkhirHantarBorang
    } = this.state;

    return (
      <React.Fragment>
        <Internal />
        <div className="wrapper fixed" style={{ marginBottom: 50 }}>
          <OperasiTitle title="Tetapan Umum" />

          {loadingPage ? (
            <InternalPageLoading error={loadingPageError} retryFn={this.fetch} />
          ) : (
            <Card style={{ marginBottom: 15 }}>
              <form>
                <FormGroup label={<b>Pendaftaran</b>}>
                  <Switch
                    label={`
                    Benarkan pendaftaran untuk umum 
                    (${bukaPendaftaran ? "BUKA" : "TUTUP"})`}
                    checked={bukaPendaftaran}
                    onChange={e =>
                      this.setState({
                        bukaPendaftaran: e.target.checked
                      })
                    }
                  />
                </FormGroup>

                <Divider style={{ marginBottom: 20 }} />

                <FormGroup label={<b>Tarikh Tutup Pendaftaran</b>} helperText="(dd-mm-yyyy)">
                  <p>
                    Masukkan tarikh tutup pendaftaran. Selepas tarikh ini, sistem tidak lagi
                    menerima sebarang pendaftaran.
                  </p>
                  <DateInput
                    locale="ms"
                    formatDate={date => moment(date).format("DD-MM-YYYY")}
                    parseDate={str => moment(str, "DD-MM-YYYY").toDate()}
                    placeholder="Tarikh"
                    disabled={!bukaPendaftaran}
                    minDate={new Date()}
                    maxDate={MAXDATE}
                    value={tarikhAkhirPendaftaran}
                    onChange={date =>
                      this.setState({
                        tarikhAkhirPendaftaran: date
                      })
                    }
                  />
                </FormGroup>

                <Divider style={{ marginBottom: 20 }} />

                <FormGroup
                  label={<b>Tarikh Akhir Hantar Borang Pendaftaran</b>}
                  helperText="(dd-mm-yyyy)"
                >
                  <p>
                    Tarikh ini adalah tarikh akhir borang dihantar. Tarikh akan dipaparkan pada
                    borang pendaftaran pada bahagian "Syarat-Syarat Permohonan".
                  </p>
                  <DateInput
                    locale="ms"
                    formatDate={date => moment(date).format("DD-MM-YYYY")}
                    parseDate={str => moment(str, "DD-MM-YYYY").toDate()}
                    placeholder="Tarikh"
                    disabled={!bukaPendaftaran}
                    minDate={new Date()}
                    maxDate={MAXDATE}
                    value={tarikhAkhirHantarBorang}
                    onChange={date =>
                      this.setState({
                        tarikhAkhirHantarBorang: date
                      })
                    }
                  />
                </FormGroup>

                <Divider style={{ marginBottom: 20 }} />

                <FormGroup label={<b>Pengumuman</b>}>
                  <p>Pengumuman akan dipaparkan di muka depan (laman umum).</p>
                  <div style={{ marginBottom: 10 }}>
                    <TextArea
                      fill
                      placeholder="Masukkan kandungan pengumuman..."
                      style={{ resize: "vertical", marginBottom: 5 }}
                      value={kandunganPengumuman}
                      onChange={e =>
                        this.setState({
                          kandunganPengumuman: e.target.value
                        })
                      }
                    />
                    <br />
                    <DateInput
                      locale="ms"
                      formatDate={date => moment(date).format("DD/MM/YYYY")}
                      parseDate={str => moment(str, "DD/MM/YYYY").toDate()}
                      placeholder="Tarikh"
                      value={tarikhPengumuman}
                      minDate={new Date()}
                      maxDate={MAXDATE}
                      onChange={date => {
                        this.setState({
                          tarikhPengumuman: date
                        });
                      }}
                    />
                    <Button
                      disabled={kandunganPengumuman === "" || !tarikhPengumuman}
                      style={{ marginLeft: 10 }}
                      onClick={this.tambahPengumuman}
                    >
                      Tambah
                    </Button>
                  </div>

                  <div style={{ marginLeft: 50 }}>
                    {senaraiPengumuman.map((s, index) => (
                      <Callout key={index} style={{ marginBottom: 12 }}>
                        <b>{moment(s.date).format("DD-MM-YYYY")} :</b>
                        <br />
                        {s.content}
                        <br />
                        <Button
                          small
                          intent="danger"
                          onClick={() => this.hapusPengumuman(index)}
                          style={{ marginTop: 10 }}
                        >
                          Hapus
                        </Button>
                      </Callout>
                    ))}
                  </div>

                  {/* <ol>
                    {senaraiPengumuman.map((s, index) => {
                      return (
                        <li key={index} style={{ marginBottom: 12 }}>
                          <b>{moment(s.date).format("DD-MM-YYYY")} :</b>
                          <br />
                          {s.content}
                          <br />
                          <Button
                            small
                            minimal
                            intent="danger"
                            onClick={() => this.hapusPengumuman(index)}
                          >
                            Hapus
                          </Button>
                        </li>
                      );
                    })}
                  </ol> */}
                </FormGroup>
                <Button intent="primary" large onClick={this.simpan} loading={loadingSimpan}>
                  Simpan Tetapan
                </Button>
              </form>
            </Card>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default OperasiTetapan;
