import React from "react";
import PublicHeader from "../components/PublicHeader";
import AppToaster from "../components/AppToaster";
import {
  AnchorButton,
  Card,
  Button,
  RadioGroup,
  Radio,
  Spinner,
} from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import { API_URL } from "../constant";
import axios from "axios";
import moment from "moment";
import replaceall from "replaceall";
import isOdd from "is-odd";
import PublicFooter from "../components/PublicFooter";
import capitalize from "../capitalize";
import { SEBAB_KEMATIAN } from "../constant";

const RequiredAsterisk = () => <span style={{ color: "red" }}> *</span>;

const YATIM = "YATIM";
const YATIM_PIATU = "YATIM_PIATU";

const bulan = {
  "01": "Januari",
  "02": "Februari",
  "03": "Mac",
  "04": "April",
  "05": "Mei",
  "06": "Jun",
  "07": "Julai",
  "08": "Ogos",
  "09": "September",
  10: "Oktober",
  11: "November",
  12: "Disember",
};

const sebabKematian = SEBAB_KEMATIAN;

const defaultDaerahCode = "01";

const stukturAnakYatim = {
  nama: "",
  nokp: "",
  tarikhLahir: null,
  jantina: "",
  namaSekolah: "",
  umurLabel: "-",
  jenisPengenalan: "kadPengenalan",
};

const limitAnakYatim = 10;

const minDateUmurAnakYatim = moment().subtract(16, "years").toDate();

class Pendaftaran extends React.Component {
  state = {
    sudahBacaSyarat: false, // temp
    loadingSetujuSyarat: false,
    senaraiBank: [],
    senaraiDaerah: [],
    senaraiMasjid: [],
    senaraiPendapatan: [],
    masjidKariah: "",
    loadingFetchMasjid: false,
    anakYatim: [{ ...stukturAnakYatim }],
    loadingSubmit: false,
    loadingSyarat: true,
    tarikhAkhirHantar: "",
    kategoriYatim: "YATIM",
    kematianTarikhBapa: null,
    kematianTarikhIbu: null,
    sudahDaftarZakatSelangor: "TIDAK",
    sudahSelesaiUrusHartaPusaka: "TIDAK",
  };

  componentDidMount = () => {
    this.fetchTarikhAkhirHantar();
  };

  fetchTarikhAkhirHantar = async () => {
    try {
      const res = await axios.get(`${API_URL}/tarikhhantar`);
      const dm = moment(res.data.result);
      const tarikh = `${dm.format("DD")} ${bulan[dm.format("MM")]} ${dm.format(
        "YYYY"
      )}`;

      this.setState({
        tarikhAkhirHantar: tarikh.toUpperCase(),
        loadingSyarat: false,
      });
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign",
      });
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  setujuSyarat = () => {
    this.setState(
      {
        loadingSetujuSyarat: true,
      },
      () => {
        this.prepareForm();
      }
    );
  };

  prepareForm = async () => {
    try {
      const res = await Promise.all([
        axios.get(`${API_URL}/bank`),
        axios.get(`${API_URL}/daerah`),
        axios.get(`${API_URL}/masjid/daerah/${defaultDaerahCode}`),
        axios.get(`${API_URL}/pendapatan`),
      ]);

      this.setState({
        loadingSetujuSyarat: false,
        sudahBacaSyarat: true,
        senaraiBank: res[0].data.result,
        senaraiDaerah: res[1].data.result,
        senaraiMasjid: res[2].data.result,
        senaraiPendapatan: res[3].data.result,
      });
    } catch (e) {
      this.setState({
        loadingSetujuSyarat: false,
        sudahBacaSyarat: false,
      });

      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign",
      });
    }
  };

  fetchMasjidByDaerah = async (e) => {
    try {
      const { value } = e.target;

      this.setState({
        loadingFetchMasjid: true,
      });

      const res = await axios.get(`${API_URL}/masjid/daerah/${value}`);

      this.setState({
        loadingFetchMasjid: false,
        senaraiMasjid: res.data.result,
      });
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign",
      });

      this.setState({
        loadingFetchMasjid: false,
      });
    }
  };

  handleChangeAnakYatim = (e) => {
    const { name, value } = e.target;
    const index = Number(e.target.getAttribute("data-index"));

    this.setState((prevState) => {
      let { anakYatim } = prevState;
      anakYatim[index][name] = value;
      return { anakYatim };
    });
  };

  handleChangeJantinaAnakYatim = (e) => {
    const { value } = e.target;
    const index = Number(e.target.getAttribute("data-index"));

    this.setState((prevState) => {
      let { anakYatim } = prevState;
      anakYatim[index].jantina = value;
      return { anakYatim };
    });
  };

  handleChangeJenisPengenalan = (e) => {
    const { value } = e.target;
    const index = Number(e.target.getAttribute("data-index"));

    this.setState((prevState) => {
      let { anakYatim } = prevState;
      anakYatim[index].jenisPengenalan = value;
      return { anakYatim };
    });
  };

  hapusRekodAnakYatim = (index) => {
    this.setState((prevState) => {
      let { anakYatim } = prevState;

      anakYatim.splice(index, 1);

      return {
        anakYatim,
      };
    });
  };

  handleChangeTarikhLahirAnakYatim = (index, key, date) => {
    this.setState((prevState) => {
      let { anakYatim } = prevState;

      anakYatim[index][key] = date;

      if (date && date !== "") {
        anakYatim[index].umurLabel = this.getUmurLabel(date);
      } else {
        anakYatim[index].umurLabel = "-";
      }

      return { anakYatim };
    });
  };

  handleBlurNoKP = (index, value) => {
    // remove dash
    value = replaceall("-", "", value);
    value = replaceall(" ", "", value);
    value = replaceall("_", "", value);

    // trim
    value = value.trim();

    // format nokp: 123456121234 (12 karakter)
    if (value.length !== 12) {
      return;
    }

    this.setState((prevState) => {
      let { anakYatim } = prevState;
      anakYatim[index].nokp = value;

      // jantina:
      // dengan syarat no kp mestilah 12 karakter
      if (value.length === 12) {
        const hujung = Number(value.substr(-1));

        if (!isNaN(hujung)) {
          anakYatim[index].jantina = isOdd(hujung) ? "L" : "P";
        }
      }

      // generate tarikh lahir
      let pangkal = value.substr(0, 6);

      if (pangkal.length === 6) {
        let tarikhLahir = moment(pangkal, "YYMMDD");

        if (tarikhLahir.isValid()) {
          if (
            tarikhLahir.endOf("day") >=
            moment(minDateUmurAnakYatim).startOf("day")
          ) {
            if (tarikhLahir.endOf("day") <= moment().endOf("day")) {
              anakYatim[index].tarikhLahir = tarikhLahir.toDate();
              anakYatim[index].umurLabel = this.getUmurLabel(
                tarikhLahir.toDate()
              );
            }
          }
        }
      }

      return { anakYatim };
    });
  };

  getUmurLabel = (tarikhLahirDate) => {
    // generate label umur
    const a = moment();
    const b = moment(tarikhLahirDate);

    const years = a.diff(b, "year");
    b.add(years, "years");

    const months = a.diff(b, "months");
    b.add(months, "months");

    const days = a.diff(b, "days");

    return `${years} Tahun ${months} Bulan ${days} Hari`;
  };

  submit = async (e) => {
    e.preventDefault();

    const {
      namaPenjaga,
      noKadPengenalanPenjaga,
      noTelBimbitPenjaga,
      noTelRumahPenjaga,
      emelPenjaga,
      alamat1Penjaga,
      alamat2Penjaga,
      alamatPoskodPenjaga,
      alamatBandarPenjaga,
      alamatNegeriPenjaga,
      pekerjaanPenjaga,
      noTelPejabatPenjaga,
      alamatTempatKerjaPenjaga,
      bankPenjaga,
      noAkaunBankPenjaga,
      namaPemilikAkaunPenjaga,
      namaWaris,
      noTelBimbitWaris,
      noTelRumahWaris,
      masjidDaerah,
      masjidKariah,
      kategoriPendapatanPenjaga,
      kategoriYatim,
      kematianNamaBapa,
      sebabKematianBapa,
      kematianNamaIbu,
      sebabKematianIbu,
      sudahDaftarZakatSelangor,
      sudahSelesaiUrusHartaPusaka,
    } = e.target;

    const { anakYatim, kematianTarikhBapa, kematianTarikhIbu } = this.state;

    try {
      if (namaPenjaga.value === "") {
        throw Error(1);
      }

      if (noKadPengenalanPenjaga.value === "") {
        throw Error(2);
      }

      if (noTelBimbitPenjaga.value === "") {
        throw Error(3);
      }

      if (alamat1Penjaga.value === "") {
        throw Error(4);
      }

      if (alamatPoskodPenjaga.value === "") {
        throw Error(5);
      }

      if (alamatBandarPenjaga.value === "") {
        throw Error(6);
      }

      if (alamatNegeriPenjaga.value === "") {
        throw Error(7);
      }

      if (pekerjaanPenjaga.value === "") {
        throw Error(8);
      }

      if (bankPenjaga.value === "") {
        throw Error(13);
      }

      if (noAkaunBankPenjaga.value === "") {
        throw Error(14);
      }

      if (namaPemilikAkaunPenjaga.value === "") {
        throw Error(15);
      }

      if (namaWaris.value === "") {
        throw Error(9);
      }

      if (noTelBimbitWaris.value === "") {
        throw Error(10);
      }

      if (masjidDaerah.value === "") {
        throw Error(11);
      }

      if (masjidKariah.value === "") {
        throw Error(12);
      }

      // kematian
      if (kematianNamaBapa.value === "") {
        throw Error(16);
      }

      if (!kematianTarikhBapa || kematianTarikhBapa === "") {
        throw Error(17);
      }

      if (kategoriYatim.value === YATIM_PIATU) {
        if (kematianNamaIbu.value === "") {
          throw Error(18);
        }

        if (!kematianTarikhIbu || kematianTarikhIbu === "") {
          throw Error(19);
        }
      }

      // validate anak2 yatim punya maklumat
      let senaraiAnakYatim = [];

      anakYatim.forEach((anak, index) => {
        if (anak.nama === "") {
          throw Error(`Sila isikan nama penuh anak yatim (No.${index + 1})`);
        }

        if (anak.nokp === "") {
          throw Error(
            `Sila isikan nombor kad pengenalan / nombor sijil kelahiran anak yatim (No.${
              index + 1
            })`
          );
        }

        if (!anak.tarikhLahir || anak.tarikhLahir === "") {
          throw Error(`Sila pilih tarikh lahir anak yatim (No.${index + 1})`);
        }

        if (anak.jantina === "") {
          throw Error(`Sila pilih jantina anak yatim (No.${index + 1})`);
        }

        senaraiAnakYatim.push({
          ...anak,
          tarikhLahir: moment(anak.tarikhLahir).format("DDMMYYYY"),
        });
      });

      // prepare data
      let data = {
        namaPenjaga: namaPenjaga.value,
        noKadPengenalanPenjaga: noKadPengenalanPenjaga.value,
        noTelBimbitPenjaga: noTelBimbitPenjaga.value,
        noTelRumahPenjaga: noTelRumahPenjaga.value,
        emelPenjaga: emelPenjaga.value,
        alamat1Penjaga: alamat1Penjaga.value,
        alamat2Penjaga: alamat2Penjaga.value,
        alamatPoskodPenjaga: alamatPoskodPenjaga.value,
        alamatBandarPenjaga: alamatBandarPenjaga.value,
        alamatNegeriPenjaga: alamatNegeriPenjaga.value,
        pekerjaanPenjaga: pekerjaanPenjaga.value,
        noTelPejabatPenjaga: noTelPejabatPenjaga.value,
        alamatTempatKerjaPenjaga: alamatTempatKerjaPenjaga.value,
        kategoriPendapatanPenjaga: kategoriPendapatanPenjaga.value,
        bankPenjaga: bankPenjaga.value,
        noAkaunBankPenjaga: noAkaunBankPenjaga.value,
        namaPemilikAkaunPenjaga: namaPemilikAkaunPenjaga.value,
        namaWaris: namaWaris.value,
        noTelBimbitWaris: noTelBimbitWaris.value,
        noTelRumahWaris: noTelRumahWaris.value,
        masjidDaerah: masjidDaerah.value,
        masjidKariah: masjidKariah.value,
        anakYatim: senaraiAnakYatim,
        kategoriYatim: kategoriYatim.value,
        kematianNamaBapa: kematianNamaBapa.value,
        kematianTarikhBapa: kematianTarikhBapa,
        sebabKematianBapa: sebabKematianBapa.value,
        sudahDaftarZakatSelangor: sudahDaftarZakatSelangor.value,
        sudahSelesaiUrusHartaPusaka: sudahSelesaiUrusHartaPusaka.value,
      };

      // date moment to string
      if (data.kematianTarikhBapa) {
        data.kematianTarikhBapa = moment(data.kematianTarikhBapa).format(
          "DDMMYYYY"
        );
      }

      if (this.state.kategoriYatim === YATIM_PIATU) {
        data = {
          ...data,
          kematianNamaIbu: kematianNamaIbu.value,
          kematianTarikhIbu: kematianTarikhIbu,
          sebabKematianIbu: sebabKematianIbu.value,
        };

        if (data.kematianTarikhIbu) {
          data.kematianTarikhIbu = moment(data.kematianTarikhIbu).format(
            "DDMMYYYY"
          );
        }
      }

      this.setState({
        loadingSubmit: true,
      });

      const res = await axios.post(`${API_URL}/pendaftaran`, data);

      if (!res.data.success) {
        throw Error(res.data.message);
      }

      this.setState({
        loadingSubmit: false,
      });

      if (res.data && res.data.pendaftaranId) {
        const encodedNoSiri = encodeURIComponent(res.data.noSiriBorang);

        this.props.history.replace(
          `/pendaftaran/berjaya/${res.data.pendaftaranId}/${encodedNoSiri}`
        );
      }
    } catch (e) {
      let message;

      switch (e.message) {
        case "1":
          message = (
            <span>
              Sila masukkan nama penuh penjaga.
              <br />
              (Bahagian 1.1 - Nama Penuh)
            </span>
          );
          break;

        case "2":
          message = (
            <span>
              Sila masukkan nombor kad pengenalan
              <br />
              atau nombor passport penjaga.
              <br />
              (Bahagian 1.1 - No. KP / Passport)
            </span>
          );
          break;

        case "3":
          message = (
            <span>
              Sila masukkan nombor telefon bimbit penjaga.
              <br />
              (Bahagian 1.1 - No. Tel Bimbit)
            </span>
          );
          break;

        case "4":
          message = (
            <span>
              Sila masukkan alamat penjaga.
              <br />
              (Bahagian 1.1 - Alamat)
            </span>
          );
          break;

        case "5":
          message = (
            <span>
              Sila masukkan poskod alamat penjaga.
              <br />
              (Bahagian 1.1 - Poskod)
            </span>
          );
          break;

        case "6":
          message = (
            <span>
              Sila masukkan bandar alamat penjaga.
              <br />
              (Bahagian 1.1 - Bandar)
            </span>
          );
          break;

        case "7":
          message = (
            <span>
              Sila pilih negeri alamat penjaga.
              <br />
              (Bahagian 1.1 - Negeri)
            </span>
          );
          break;

        case "8":
          message = (
            <span>
              Sila masukkan nama pekerjaan penjaga.
              <br />
              (Bahagian 1.2 - Pekerjaan)
            </span>
          );
          break;

        case "9":
          message = (
            <span>
              Sila masukkan nama penuh waris.
              <br />
              (Bahagian 1.4 - Nama Penuh)
            </span>
          );
          break;

        case "10":
          message = (
            <span>
              Sila masukkan nombor telefon bimbit waris.
              <br />
              (Bahagian 1.4 - No Telefon Bimbit)
            </span>
          );
          break;

        case "11":
          message = (
            <span>
              Sila pilih daerah masjid.
              <br />
              (Bahagian 2 - Daearah)
            </span>
          );
          break;

        case "12":
          message = (
            <span>
              Sila pilih nama masjid kariah.
              <br />
              (Bahagian 2 - Masjid)
            </span>
          );
          break;

        case "13":
          message = (
            <span>
              Sila pilih jenis bank.
              <br />
              (Bahagian 1.3 - Maklumat Bank)
            </span>
          );
          break;

        case "14":
          message = (
            <span>
              Sila masukkan nombor akaun bank.
              <br />
              (Bahagian 1.3 - Maklumat Bank)
            </span>
          );
          break;

        case "15":
          message = (
            <span>
              Sila masukkan nama pemilik akaun bank.
              <br />
              (Bahagian 1.3 - Maklumat Bank)
            </span>
          );
          break;

        case "16":
          message = (
            <span>
              Sila masukkan nama penuh bapa.
              <br />
              (Bahagian 3.1 - Maklumat Kematian)
            </span>
          );
          break;

        case "17":
          message = (
            <span>
              Sila masukkan tarikh kematian bapa.
              <br />
              (Bahagian 3.1 - Maklumat Kematian)
            </span>
          );
          break;

        case "18":
          message = (
            <span>
              Sila masukkan nama penuh ibu.
              <br />
              (Bahagian 3.1 - Maklumat Kematian)
            </span>
          );
          break;

        case "19":
          message = (
            <span>
              Sila masukkan tarikh kematian ibu.
              <br />
              (Bahagian 3.1 - Maklumat Kematian)
            </span>
          );
          break;

        default:
          //message = "Sila isi ruang yang bertanda (*)";
          message = e.message;
          break;
      }

      AppToaster.show(
        {
          message,
          intent: "danger",
          icon: "warning-sign",
        },
        "pendaftaran"
      );

      this.setState({
        loadingSubmit: false,
      });
    }
  };

  applyContohData = () => {
    // prettier-ignore
    if (process.env.NODE_ENV === "development") {
      document.querySelector("[name=namaPenjaga]").value = "Ramli Bin Sarip";
      document.querySelector("[name=noKadPengenalanPenjaga]").value = "882233193234";
      document.querySelector("[name=noTelBimbitPenjaga]").value= "0123123123";
      document.querySelector("[name=alamat1Penjaga]").value= "No 9 Jalan Haha 3";
      document.querySelector("[name=alamatPoskodPenjaga]").value= "12344";
      document.querySelector("[name=alamatBandarPenjaga]").value= "Selayang";
      document.querySelector("[name=pekerjaanPenjaga]").value= "Tukang Tonton";
      document.querySelector("[name=noAkaunBankPenjaga]").value= "888888888888";
      document.querySelector("[name=namaPemilikAkaunPenjaga]").value= "Ramli Sarip";
      document.querySelector("[name=namaWaris]").value= "Nama Waris Lagi";
      document.querySelector("[name=noTelBimbitWaris]").value= "0993231233";
      document.querySelector("[name=kematianNamaBapa]").value= "namabapa bin kematian";

      

      this.setState({
        kematianTarikhBapa: moment().subtract(3, 'days').toDate(),
        anakYatim: [{
          nama: "Octane bin Fennec",
          nokp: "123123123",
          tarikhLahir: moment().subtract(3, 'months').toDate(),
          jantina: "L",
          namaSekolah: "SEKOLAH SINI SUDAH",
          umurLabel: "-",
          jenisPengenalan: "kadPengenalan",
          kematianNamaBapa: "namabapa bin kematian",
        }]
      }) 
    }
  };

  render = () => {
    const {
      sudahBacaSyarat,
      loadingSetujuSyarat,
      senaraiBank,
      senaraiDaerah,
      senaraiMasjid,
      masjidKariah,
      loadingFetchMasjid,
      anakYatim,
      loadingSubmit,
      senaraiPendapatan,
      loadingSyarat,
      tarikhAkhirHantar,
    } = this.state;

    return (
      <React.Fragment>
        <PublicHeader />
        <div className="wrapper fixed pendaftaran" style={{ marginBottom: 50 }}>
          <AnchorButton
            minimal
            icon="arrow-left"
            small
            style={{ marginTop: 15 }}
            onClick={() => {
              this.props.history.replace("/");
            }}
          >
            Kembali ke Laman Utama
          </AnchorButton>
          <h2 className="title">Borang Pendaftaran Anak Yatim</h2>

          {sudahBacaSyarat ? (
            <React.Fragment>
              <Card style={{ marginTop: 20 }}>
                <p>
                  <b>Panduan Mengisi Borang Online</b>
                </p>
                <ul>
                  <li>
                    Anda digalakkan mengisi borang menggunakan komputer kerana
                    anda perlu mencetak borang permohonan selepas ini.
                  </li>
                  <li>
                    Semua ruang yang bertanda (<RequiredAsterisk /> ) adalah
                    wajib diisi, manakala ruang yang tidak bertanda boleh
                    dibiarkan kosong. Adalah sangat baik jika anda mengisi semua
                    ruang.
                  </li>
                  <li>
                    Untuk ruang nombor kad pengenalan, anda tidak perlu
                    memasukkan tanda <i>dash (-)</i>, contoh :<br />
                    <div style={{ margin: 7 }}>
                      <code>850302-10-4231</code> (Salah)
                      <br />
                      <code>850302104231</code> (Betul)
                    </div>
                  </li>
                  <li>
                    Pada bahagian butiran anak yatim, sistem akan menetapkan
                    tarikh lahir secara automatik berdasarkan nombor Kad
                    Pengenalan anak yatim tersebut. Walau bagaimanapun, ia hanya
                    berfungsi untuk nombor Kad Pengenalan jenis baru sahaja.
                  </li>
                  <li>
                    Setelah selesai mengisi borang, klik pada butang "Hantar
                    Borang Pendaftaran" di bawah.
                  </li>
                </ul>
              </Card>

              {process.env.NODE_ENV === "development" && (
                <button onClick={this.applyContohData}>
                  Apply contoh data
                </button>
              )}

              <form onSubmit={this.submit}>
                <table className="form">
                  <tbody>
                    <tr>
                      <td colSpan={2}>
                        <h3 className="partition-title">
                          Bahagian 1 : Maklumat Penjaga
                        </h3>
                        <h4 className="partition-subtitle">
                          Bahagian 1.1 : Butiran Penjaga
                        </h4>
                      </td>
                    </tr>
                    <tr>
                      <td className="label" style={{ width: 180 }}>
                        Nama Penuh
                        <RequiredAsterisk />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="namaPenjaga"
                          className="bp3-input force-uppercase"
                          placeholder="Nama Penuh"
                          //required
                          autoComplete="off"
                          disabled={loadingSubmit}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label">
                        No. KP / Passport
                        <RequiredAsterisk />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="noKadPengenalanPenjaga"
                          className="bp3-input force-uppercase"
                          placeholder="Nombor Kad Pengenalan / Passport"
                          //required
                          autoComplete="off"
                          ref="noKadPengenalanPenjaga"
                          disabled={loadingSubmit}
                          onBlur={(e) => {
                            let { value } = e.target;

                            value = replaceall(" ", "", value);
                            value = replaceall("-", "", value);
                            value = replaceall("_", "", value);
                            value = value.trim();

                            this.refs.noKadPengenalanPenjaga.value = value;
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label">
                        No. Telefon Bimbit
                        <RequiredAsterisk />
                      </td>
                      <td>
                        <input
                          type="tel"
                          name="noTelBimbitPenjaga"
                          className="bp3-input"
                          placeholder="Nombor Tel Bimbit"
                          //required
                          autoComplete="off"
                          disabled={loadingSubmit}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label">No. Telefon Rumah</td>
                      <td>
                        <input
                          type="tel"
                          name="noTelRumahPenjaga"
                          className="bp3-input"
                          placeholder="Nombor Tel Rumah"
                          autoComplete="off"
                          disabled={loadingSubmit}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label">Emel</td>
                      <td>
                        <input
                          type="email"
                          name="emelPenjaga"
                          className="bp3-input"
                          placeholder="Emel"
                          autoComplete="off"
                          disabled={loadingSubmit}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label">
                        Alamat
                        <RequiredAsterisk />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="alamat1Penjaga"
                          className="bp3-input force-uppercase"
                          placeholder="Cth: No.5 Jalan Bintang 10"
                          //required
                          autoComplete="off"
                          disabled={loadingSubmit}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label" />
                      <td>
                        <input
                          type="text"
                          name="alamat2Penjaga"
                          className="bp3-input force-uppercase"
                          placeholder="Cth: Taman Seri Indah"
                          autoComplete="off"
                          disabled={loadingSubmit}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label">
                        Poskod
                        <RequiredAsterisk />
                      </td>
                      <td>
                        <input
                          type="number"
                          name="alamatPoskodPenjaga"
                          className="bp3-input nostep"
                          placeholder="Poskod"
                          maxLength={5}
                          //required
                          autoComplete="off"
                          disabled={loadingSubmit}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label">
                        Bandar
                        <RequiredAsterisk />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="alamatBandarPenjaga"
                          className="bp3-input force-uppercase"
                          placeholder="Bandar"
                          //required
                          autoComplete="off"
                          disabled={loadingSubmit}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label">
                        Negeri
                        <RequiredAsterisk />
                      </td>
                      <td>
                        <div className="bp3-select">
                          <select
                            style={{ width: 300 }}
                            name="alamatNegeriPenjaga"
                            disabled={loadingSubmit}
                            defaultValue="Selangor"
                            //required
                          >
                            <option value="Johor">Johor</option>
                            <option value="Kedah">Kedah</option>
                            <option value="Kelantan">Kelantan</option>
                            <option value="Kuala Lumpur">Kuala Lumpur</option>
                            <option value="Labuan">Labuan</option>
                            <option value="Melaka">Melaka</option>
                            <option value="Negeri Sembilan">
                              Negeri Sembilan
                            </option>
                            <option value="Pahang">Pahang</option>
                            <option value="Penang">Penang</option>
                            <option value="Perak">Perak</option>
                            <option value="Perlis">Perlis</option>
                            <option value="Putrajaya">Putrajaya</option>
                            <option value="Sabah">Sabah</option>
                            <option value="Sarawak">Sarawak</option>
                            <option value="Selangor">Selangor</option>
                            <option value="Terengganu">Terengganu</option>
                          </select>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <h4 className="partition-subtitle">
                          Bahagian 1.2 : Pekerjaan Penjaga
                        </h4>
                      </td>
                    </tr>
                    <tr>
                      <td className="label" style={{ width: 180 }}>
                        Pekerjaan
                        <RequiredAsterisk />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="pekerjaanPenjaga"
                          className="bp3-input"
                          placeholder="Nama Pekerjaan"
                          autoComplete="off"
                          //required
                          disabled={loadingSubmit}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label">No. Telefon Pejabat</td>
                      <td>
                        <input
                          type="tel"
                          name="noTelPejabatPenjaga"
                          className="bp3-input"
                          placeholder="Nombor Tel Pejabat"
                          autoComplete="off"
                          disabled={loadingSubmit}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label">Alamat Tempat Kerja</td>
                      <td>
                        <textarea
                          type="text"
                          name="alamatTempatKerjaPenjaga"
                          className="bp3-input force-uppercase"
                          placeholder="Alamat Tempat Kerja"
                          rows={5}
                          autoComplete="off"
                          disabled={loadingSubmit}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label">
                        Pendapatan Bulanan
                        <RequiredAsterisk />
                      </td>
                      <td>
                        <span style={{ marginRight: 10 }}>RM</span>
                        <div className="bp3-select">
                          <select
                            name="kategoriPendapatanPenjaga"
                            defaultValue="1"
                            disabled={loadingSubmit}
                          >
                            {senaraiPendapatan.map((pendapatan) => (
                              <option
                                key={pendapatan.code}
                                value={pendapatan.code}
                              >
                                {pendapatan.description}
                              </option>
                            ))}
                          </select>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <h4 className="partition-subtitle">
                          Bahagian 1.3 : Maklumat Bank
                        </h4>
                        <p>
                          Sila pilih bank penjaga dan masukkan nombor akaun bank
                          tersebut. Pihak Yayasan akan menghantar wang sumbangan
                          melalui bank ini jika sumbangan secara tunai tidak
                          dapat diserahkan kepada penjaga/anak yatim.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="label">
                        Bank
                        <RequiredAsterisk />
                      </td>
                      <td>
                        <div className="bp3-select">
                          <select
                            style={{ minWidth: 300 }}
                            name="bankPenjaga"
                            defaultValue="5b8c7967ab8e096c945510b9"
                            disabled={loadingSubmit}
                          >
                            {senaraiBank.map((bank) =>
                              bank.description !== "ASB" &&
                              bank.description !== "TABUNG HAJI" ? (
                                <option key={bank._id} value={bank._id}>
                                  {bank.description}
                                </option>
                              ) : null
                            )}
                          </select>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="label">
                        No. Akaun
                        <RequiredAsterisk />
                      </td>
                      <td>
                        <input
                          type="number"
                          name="noAkaunBankPenjaga"
                          className="bp3-input nostep"
                          placeholder="Nombor Akaun Bank"
                          autoComplete="off"
                          disabled={loadingSubmit}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label">
                        Nama Pemilik Akaun
                        <RequiredAsterisk />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="namaPemilikAkaunPenjaga"
                          className="bp3-input force-uppercase"
                          placeholder="Nama Pemilik Akaun"
                          autoComplete="off"
                          disabled={loadingSubmit}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <h4 className="partition-subtitle">
                          Bahagian 1.4 : Maklumat Waris
                        </h4>
                      </td>
                    </tr>
                    <tr>
                      <td className="label" style={{ width: 180 }}>
                        Nama Penuh
                        <RequiredAsterisk />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="namaWaris"
                          className="bp3-input force-uppercase"
                          placeholder="Nama Penuh Waris"
                          //required
                          autoComplete="off"
                          disabled={loadingSubmit}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label">
                        No. Telefon Bimbit
                        <RequiredAsterisk />
                      </td>
                      <td>
                        <input
                          type="tel"
                          name="noTelBimbitWaris"
                          className="bp3-input"
                          placeholder="Nombor Tel Bimbit Waris"
                          //required
                          autoComplete="off"
                          disabled={loadingSubmit}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label">No. Telefon Rumah</td>
                      <td>
                        <input
                          type="tel"
                          name="noTelRumahWaris"
                          className="bp3-input"
                          placeholder="Nombor Tel Rumah Waris"
                          autoComplete="off"
                          disabled={loadingSubmit}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <h3 className="partition-title">
                          Bahagian 2 : Maklumat Masjid Kariah
                        </h3>
                        <p>
                          Sila pilih masjid kariah tempat tinggal penjaga.
                          Senarai masjid akan dipapar mengikut pilihan daerah.
                          Sila pastikan anda memilih daerah yang betul.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="label">
                        Daerah
                        <RequiredAsterisk />
                      </td>
                      <td>
                        <div className="bp3-select">
                          <select
                            style={{ minWidth: 300 }}
                            defaultValue={defaultDaerahCode}
                            name="masjidDaerah"
                            onChange={this.fetchMasjidByDaerah}
                            disabled={loadingFetchMasjid || loadingSubmit}
                            //required
                          >
                            {senaraiDaerah.map((daerah) => (
                              <option key={daerah.code} value={daerah.code}>
                                {daerah.description}
                              </option>
                            ))}
                          </select>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="label">
                        Masjid
                        <RequiredAsterisk />
                      </td>
                      <td>
                        {loadingFetchMasjid ? (
                          <Spinner size={18} />
                        ) : (
                          <div className="bp3-select">
                            <select
                              name="masjidKariah"
                              onChange={this.handleChange}
                              value={masjidKariah}
                              disabled={loadingSubmit}
                              //required
                            >
                              {senaraiMasjid.map((masjid) => {
                                let label = masjid.nama;

                                if (label.substr(0, 6) !== "MASJID") {
                                  label = `MASJID ${label}`;
                                }

                                return (
                                  <option value={masjid._id} key={masjid._id}>
                                    {masjid.bandar !== ""
                                      ? `${label}  (${masjid.bandar})`
                                      : label}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <h3 className="partition-title">
                          Bahagian 3 : Maklumat Anak Yatim
                        </h3>
                        <h4 className="partition-subtitle">
                          Bahagian 3.1 : Maklumat Kematian
                        </h4>
                      </td>
                    </tr>

                    <tr>
                      <td className="label">
                        Kategori
                        <RequiredAsterisk />
                      </td>
                      <td>
                        <div className="bp3-select">
                          <select
                            style={{ minWidth: 300 }}
                            name="kategoriYatim"
                            value={this.state.kategoriYatim}
                            onChange={(e) =>
                              this.setState({ kategoriYatim: e.target.value })
                            }
                            disabled={loadingSubmit}
                          >
                            <option value={YATIM}>Yatim</option>
                            <option value={YATIM_PIATU}>Yatim Piatu</option>
                          </select>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td className="label">
                        Nama Bapa
                        <RequiredAsterisk />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="kematianNamaBapa"
                          className="bp3-input force-uppercase"
                          placeholder="Nama Penuh Bapa"
                          //required
                          autoComplete="off"
                          disabled={loadingSubmit}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label">
                        Tarikh Kematian Bapa
                        <RequiredAsterisk />
                      </td>
                      <td className="date-cell">
                        <DateInput
                          name="kematianTarikhBapa"
                          locale="ms"
                          invalidDateMessage="Tarikh Tidak Sah"
                          formatDate={(date) =>
                            moment(date).format("DD/MM/YYYY")
                          }
                          parseDate={(str) =>
                            moment(str, "DD/MM/YYYY").toDate()
                          }
                          placeholder="Tarikh Kematian Bapa"
                          maxDate={moment().endOf("day").toDate()}
                          outOfRangeMessage="Tarikh melebihi had"
                          disabled={loadingSubmit}
                          value={this.state.kematianTarikhBapa}
                          onChange={(value) =>
                            this.setState({ kematianTarikhBapa: value })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label">
                        Sebab Kematian Bapa
                        <RequiredAsterisk />
                      </td>
                      <td>
                        <div className="bp3-select">
                          <select
                            style={{ minWidth: 300 }}
                            name="sebabKematianBapa"
                            disabled={loadingSubmit}
                            defaultValue="LAIN-LAIN"
                          >
                            {sebabKematian.map((sebab) => (
                              <option key={sebab} value={sebab}>
                                {capitalize(sebab)}
                              </option>
                            ))}
                          </select>
                        </div>
                      </td>
                    </tr>

                    {this.state.kategoriYatim === YATIM_PIATU && (
                      <>
                        <tr>
                          <td colSpan={2}></td>
                        </tr>
                        <tr>
                          <td className="label">
                            Nama Ibu
                            <RequiredAsterisk />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="kematianNamaIbu"
                              className="bp3-input"
                              placeholder="Nama Penuh Ibu"
                              //required
                              autoComplete="off"
                              disabled={loadingSubmit}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="label">
                            Tarikh Kematian Ibu
                            <RequiredAsterisk />
                          </td>
                          <td className="date-cell">
                            <DateInput
                              name="kematianTarikhIbu"
                              locale="ms"
                              invalidDateMessage="Tarikh Tidak Sah"
                              formatDate={(date) =>
                                moment(date).format("DD/MM/YYYY")
                              }
                              parseDate={(str) =>
                                moment(str, "DD/MM/YYYY").toDate()
                              }
                              placeholder="Tarikh Kematian Ibu"
                              maxDate={moment().endOf("day").toDate()}
                              outOfRangeMessage="Tarikh melebihi had"
                              disabled={loadingSubmit}
                              value={this.state.kematianTarikhIbu}
                              onChange={(value) =>
                                this.setState({ kematianTarikhIbu: value })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="label">
                            Sebab Kematian Ibu
                            <RequiredAsterisk />
                          </td>
                          <td>
                            <div className="bp3-select">
                              <select
                                style={{ minWidth: 300 }}
                                name="sebabKematianIbu"
                                disabled={loadingSubmit}
                                defaultValue="LAIN-LAIN"
                              >
                                {sebabKematian.map((sebab) => (
                                  <option key={sebab} value={sebab}>
                                    {capitalize(sebab)}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </td>
                        </tr>
                      </>
                    )}

                    <tr>
                      <td colSpan={2}>
                        <h4 className="partition-subtitle">
                          Bahagian 3.2 : Senarai Anak Yatim
                        </h4>
                        <p>
                          Sila isikan maklumat anak-anak yatim dengan betul.
                          Hanya anak-anak yatim yang berumur 16 tahun ke bawah
                          (dan tidak melebihi 16 tahun) sahaja yang layak untuk
                          diletakkan diruangan bawah ini.
                        </p>
                        <p>
                          Jika anda ingin mendaftar lebih dari seorang (1) anak
                          yatim, klik pada butang "Tambah rekod anak yatim"
                          untuk mengisi maklumat anak yatim seterusnya.
                        </p>
                        <p>
                          Anda boleh mengisi sehingga 10 anak yatim dalam satu
                          pendaftaran.
                        </p>
                      </td>
                    </tr>

                    {anakYatim.map((anak, index) => (
                      <React.Fragment key={index}>
                        <tr>
                          <td colSpan={2}>
                            <h4
                              className="partition-subtitle line"
                              style={{ paddingBottom: 6 }}
                            >
                              Butiran Anak Yatim (No.
                              {index + 1})
                              {index > 0 ? (
                                <Button
                                  intent="danger"
                                  small
                                  minimal
                                  rightIcon="cross"
                                  style={{ float: "right" }}
                                  onClick={() =>
                                    this.hapusRekodAnakYatim(index)
                                  }
                                >
                                  Hapus Rekod
                                </Button>
                              ) : null}
                            </h4>
                          </td>
                        </tr>
                        <tr>
                          <td className="label">
                            Nama Anak Yatim
                            <RequiredAsterisk />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="nama"
                              className="bp3-input force-uppercase"
                              placeholder="Nama Penuh Anak Yatim"
                              data-index={index}
                              onChange={this.handleChangeAnakYatim}
                              value={anak.nama}
                              //required
                              autoComplete="off"
                              disabled={loadingSubmit}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="label">
                            Jenis Pengenalan
                            <RequiredAsterisk />
                          </td>
                          <td>
                            <RadioGroup
                              inline
                              selectedValue={anak.jenisPengenalan}
                              onChange={this.handleChangeJenisPengenalan}
                              name={`jenisPengenalan-${index}`}
                              disabled={loadingSubmit}
                              //required
                            >
                              <Radio
                                data-index={index}
                                value="kadPengenalan"
                                checked={
                                  anak.jenisPengenalan === "kadPengenalan"
                                }
                              >
                                Kad Pengenalan Malaysia (MyKad / MyKid)
                              </Radio>
                              <Radio
                                data-index={index}
                                value="passport"
                                checked={anak.jenisPengenalan === "passport"}
                              >
                                Passport
                              </Radio>
                            </RadioGroup>
                          </td>
                        </tr>

                        {anak.jenisPengenalan === "kadPengenalan" ? (
                          <tr>
                            <td className="label">
                              No.KP
                              <RequiredAsterisk />
                            </td>
                            <td>
                              <input
                                type="number"
                                name="nokp"
                                className="bp3-input nostep"
                                placeholder="No. Kad Pengenalan"
                                value={anak.nokp}
                                onChange={this.handleChangeAnakYatim}
                                data-index={index}
                                onBlur={(e) =>
                                  this.handleBlurNoKP(index, e.target.value)
                                }
                                //required
                                autoComplete="off"
                                disabled={loadingSubmit}
                              />
                            </td>
                          </tr>
                        ) : null}

                        {anak.jenisPengenalan === "passport" ? (
                          <tr>
                            <td className="label">
                              No.Passport
                              <RequiredAsterisk />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="nokp" //walaupun dia passport, "key" untuk sistem simpan masih sama iaitu "nokp"
                                className="bp3-input force-uppercase"
                                placeholder="No. Passport"
                                value={anak.nokp}
                                onChange={this.handleChangeAnakYatim}
                                data-index={index}
                                //onBlur={e => this.handleBlurNoKP(index, e.target.value)}
                                //required
                                autoComplete="off"
                                disabled={loadingSubmit}
                              />
                            </td>
                          </tr>
                        ) : null}

                        <tr>
                          <td className="label">
                            Tarikh Lahir
                            <RequiredAsterisk />
                          </td>
                          <td className="date-cell">
                            <DateInput
                              locale="ms"
                              invalidDateMessage="Tarikh Tidak Sah"
                              formatDate={(date) =>
                                moment(date).format("DD/MM/YYYY")
                              }
                              parseDate={(str) =>
                                moment(str, "DD/MM/YYYY").toDate()
                              }
                              placeholder="Tarikh Lahir"
                              value={anak.tarikhLahir}
                              minDate={minDateUmurAnakYatim}
                              maxDate={moment().endOf("day").toDate()}
                              onChange={(date) =>
                                this.handleChangeTarikhLahirAnakYatim(
                                  index,
                                  "tarikhLahir",
                                  date
                                )
                              }
                              outOfRangeMessage="Tarikh melebihi had"
                              disabled={loadingSubmit}
                            />
                            <span style={{ marginLeft: 10 }}>
                              Umur: {anak.umurLabel}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td className="label">
                            Jantina
                            <RequiredAsterisk />
                          </td>
                          <td>
                            <RadioGroup
                              inline
                              selectedValue={anak.jantina}
                              onChange={this.handleChangeJantinaAnakYatim}
                              name={`jantina-${index}`}
                              disabled={loadingSubmit}
                              //required
                            >
                              <Radio
                                data-index={index}
                                value="L"
                                checked={anak.jantina === "L"}
                              >
                                Lelaki
                              </Radio>
                              <Radio
                                data-index={index}
                                value="P"
                                checked={anak.jantina === "P"}
                              >
                                Perempuan
                              </Radio>
                            </RadioGroup>
                          </td>
                        </tr>
                        <tr>
                          <td className="label">Hubungan</td>
                          <td>
                            <input
                              type="text"
                              name="hubungan"
                              className="bp3-input"
                              placeholder="Cth: Bapa Saudara"
                              autoComplete="off"
                              data-index={index}
                              onChange={this.handleChange}
                              disabled={loadingSubmit}
                              value={anak.hubungan}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="label">Nama Sekolah</td>
                          <td>
                            <input
                              type="text"
                              name="namaSekolah"
                              className="bp3-input force-uppercase"
                              placeholder="Nama Tempat Anak Yatim Bersekolah"
                              autoComplete="off"
                              data-index={index}
                              onChange={this.handleChangeAnakYatim}
                              disabled={loadingSubmit}
                              value={anak.namaSekolah}
                            />
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}

                    <tr>
                      <td
                        colSpan={2}
                        style={{ textAlign: "center", padding: "20px 0" }}
                      >
                        <Button
                          disabled={loadingSubmit}
                          icon="plus"
                          style={{ marginBottom: 5 }}
                          onClick={() => {
                            this.setState((prevState) => {
                              try {
                                if (
                                  prevState.anakYatim.length >= limitAnakYatim
                                ) {
                                  throw Error(
                                    "Bilangan anak yatim telah mencapai had yang ditetapkan."
                                  );
                                }

                                let newList = [...prevState.anakYatim];
                                newList.push({ ...stukturAnakYatim });

                                return {
                                  anakYatim: newList,
                                };
                              } catch (e) {
                                AppToaster.show({
                                  message: e.message,
                                  intent: "danger",
                                  icon: "warning-sign",
                                });
                              }
                            });
                          }}
                        >
                          Tambah Rekod Anak Yatim
                        </Button>
                        <br />
                        <i style={{ fontSize: "0.9em", color: "grey" }}>
                          Jika anda mempunyai lebih dari satu (1) orang anak
                          yatim
                        </i>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={2}>
                        <h4 className="partition-subtitle">
                          Bahagian 3.3 : Tambahan
                        </h4>
                        <p>
                          Sila nyatakan YA atau TIDAK pada soalan-soalan di
                          bawah:
                        </p>
                        <ol>
                          <li>
                            <div>
                              <span
                                style={{ marginBottom: 5, display: "block" }}
                              >
                                Adakah penjaga atau anak yatim telah berdaftar
                                dengan Lembaga Zakat Selangor sebagai asnaf?
                              </span>
                              <RadioGroup
                                inline
                                selectedValue={
                                  this.state.sudahDaftarZakatSelangor
                                }
                                onChange={(e) => {
                                  this.setState({
                                    sudahDaftarZakatSelangor: e.target.value,
                                  });
                                }}
                                name="sudahDaftarZakatSelangor"
                                disabled={loadingSubmit}
                              >
                                <Radio value="YA">Ya</Radio>
                                <Radio value="TIDAK">Tidak</Radio>
                                <Radio value="TIDAKPASTI">Tidak Pasti</Radio>
                              </RadioGroup>
                            </div>
                          </li>
                          <li>
                            <div>
                              <span
                                style={{ marginBottom: 5, display: "block" }}
                              >
                                Adakah pihak penjaga telah menyelesaikan
                                pengurusan harta pusaka anak yatim yang
                                ditinggalkan oleh suami/bapa anak yatim?
                              </span>
                              <RadioGroup
                                inline
                                selectedValue={
                                  this.state.sudahSelesaiUrusHartaPusaka
                                }
                                onChange={(e) => {
                                  this.setState({
                                    sudahSelesaiUrusHartaPusaka: e.target.value,
                                  });
                                }}
                                name="sudahSelesaiUrusHartaPusaka"
                                disabled={loadingSubmit}
                              >
                                <Radio value="YA">Ya</Radio>
                                <Radio value="TIDAK">Tidak</Radio>
                                <Radio value="TIDAKPASTI">Tidak Pasti</Radio>
                              </RadioGroup>
                            </div>
                          </li>
                        </ol>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div style={{ textAlign: "center", marginTop: 50 }}>
                  <Button
                    intent="success"
                    rightIcon="tick"
                    large
                    type="submit"
                    loading={loadingSubmit}
                  >
                    Hantar Borang Pendaftaran
                  </Button>
                </div>
              </form>
            </React.Fragment>
          ) : (
            <Card>
              {loadingSyarat ? (
                <Spinner />
              ) : (
                <React.Fragment>
                  <p>
                    <b>Syarat-Syarat Permohonan</b>
                  </p>
                  <p>
                    Berikut adalah syarat-syarat permohonan untuk pendaftaran
                    anak yatim Negeri Selangor:
                  </p>
                  <ol>
                    <li>
                      Warganegara Malaysia yang beragama Islam dan bermastautin
                      di Negeri Selangor sekurang-kurangnya 4 bulan.
                    </li>
                    <li>
                      Anak lelaki dan perempuan yang kematian bapa kandung.
                    </li>
                    <li>
                      Berumur 16 tahun dan ke bawah serta belum mencapai umur 16
                      mengikut tahun kelahirannya.
                    </li>
                    <li>
                      Permohonan hendaklah disertakan dokumen-dokumen berikut:
                      <ol type="i">
                        <li>
                          Satu (1) salinan Kad Pengenalan penjaga yang telah
                          disahkan;
                        </li>
                        <li>
                          Satu (1) salinan Sijil Kelahiran anak-anak yang telah
                          disahkan; dan
                        </li>
                        <li>
                          Satu (1) salinan Sijil Kematian bapa kandung yang
                          telah disahkan.
                        </li>
                        <li>Satu (1) salinan slip Akaun Bank penjaga.</li>
                      </ol>
                    </li>
                    <li>
                      Permohonan hendaklah diserahkan kepada Yayasan Islam Darul
                      Ehsan (YIDE) SEBELUM TANGGAL <b>{tarikhAkhirHantar}</b>.
                    </li>
                  </ol>
                  {!sudahBacaSyarat ? (
                    <div style={{ marginTop: 30, textAlign: "center" }}>
                      <p style={{ color: "grey" }}>
                        <i>Klik butang di bawah untuk teruskan</i>
                      </p>
                      <Button
                        intent="primary"
                        onClick={this.setujuSyarat}
                        loading={loadingSetujuSyarat}
                      >
                        Saya telah membaca dan memahami syarat-syarat di atas
                      </Button>
                    </div>
                  ) : null}
                </React.Fragment>
              )}
            </Card>
          )}
        </div>

        <PublicFooter />
      </React.Fragment>
    );
  };
}

export default Pendaftaran;
