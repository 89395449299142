import React from "react";
import Internal from "../components/Internal";
import OperasiTitle from "../components/OperasiTitle";
import { Card, Button, Tooltip, Spinner, Popover, Tag } from "@blueprintjs/core";
import InternalPageLoading from "../components/InternalPageLoading";
import AppToaster from "../components/AppToaster";
import axios from "axios";
import { API_URL } from "../constant";
import ModalButiranMasjid from "../modals/ButiranMasjid";
import ModalEditMasjid from "../modals/EditMasjid";
import ModalNewMasjid from "../modals/NewMasjid";
import ReactTable, { ReactTableDefaults } from "react-table";
import DeletePrompt from "../components/DeletePrompt";

Object.assign(ReactTableDefaults, {
  NextComponent: state => (
    <Button fill rightIcon="fast-forward" disabled={state.disabled} onClick={state.onClick}>
      Next
    </Button>
  ),
  PreviousComponent: state => (
    <Button fill icon="fast-backward" disabled={state.disabled} onClick={state.onClick}>
      Previous
    </Button>
  )
});

class Operasi extends React.Component {
  state = {
    loadingPage: true,
    loadingPageError: false,
    senaraiDaerah: [],
    senaraiMasjid: [],
    selectedDaerahDescription: "",
    loadingChangeDaerah: false,
    loadingHapusMasjidId: null,
    openButiranMasjid: false,
    butiranMasjidId: null,
    openNewMasjid: false,
    selectedDaerahCode: "01"
  };

  componentDidMount = () => {
    this.fetch();
  };

  fetch = async () => {
    this.setState({
      loadingPage: true,
      loadingPageError: false
    });

    const { selectedDaerahCode } = this.state;

    try {
      const res = await Promise.all([
        axios.get(`${API_URL}/daerah`),
        axios.get(`${API_URL}/masjid/daerah/${selectedDaerahCode}`)
      ]);

      const daerah = res[0].data.result.find(d => d.code === selectedDaerahCode);

      this.setState({
        senaraiDaerah: res[0].data.result,
        senaraiMasjid: res[1].data.result,
        loadingPage: false,
        selectedDaerahDescription: daerah.description
      });
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign"
      });

      this.setState({
        loadingPageError: true
      });
    }
  };

  fetchChangeDaerah = async daerahCode => {
    const { senaraiDaerah } = this.state;

    const daerah = senaraiDaerah.find(d => d.code === daerahCode);

    this.setState({
      selectedDaerahDescription: daerah.description,
      loadingChangeDaerah: true
    });

    try {
      const res = await axios.get(`${API_URL}/masjid/daerah/${daerahCode}`);

      if (!res.data.success) {
        throw Error(res.data.message);
      }

      this.setState({
        loadingChangeDaerah: false,
        senaraiMasjid: res.data.result
      });
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign"
      });

      this.setState({
        loadingChangeDaerah: false
      });
    }
  };

  handleChangeDaerah = e => {
    const daerahCode = e.target.value;

    this.setState({
      selectedDaerahCode: daerahCode
    });

    this.fetchChangeDaerah(daerahCode);
  };

  undoHapus = async masjidId => {
    try {
      const res = await axios.post(
        `${API_URL}/undo/delete/masjid/refetch/daerah`,
        { masjidId },
        { withCredentials: true }
      );

      if (!res.data.success) {
        throw Error(res.data.message);
      }

      this.setState({
        senaraiMasjid: res.data.senaraiMasjid
      });
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign"
      });
    }
  };

  hapus = async masjidId => {
    this.setState({
      loadingHapusMasjidId: masjidId
    });

    try {
      const res = await axios.delete(`${API_URL}/masjid/${masjidId}/refetch/daerah`, {
        withCredentials: true
      });

      if (!res.data.success) {
        throw Error(res.data.message);
      }

      this.setState({
        loadingHapusMasjidId: null,
        senaraiMasjid: res.data.senaraiMasjid
      });

      AppToaster.show({
        message: "Rekod masjid telah dihapus",
        intent: "success",
        icon: "tick",
        action: {
          text: "Undo",
          onClick: () => {
            this.undoHapus(masjidId);
          }
        },
        canEscapeKeyClear: false
      });
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign"
      });

      this.setState({
        loadingHapusMasjidId: null
      });
    }
  };

  showModalEditMasjid = masjidId => {
    this.setState({
      openEditMasjid: true,
      editMasjidId: masjidId
    });
  };

  showModalDetailMasjid = masjidId => {
    this.setState({
      openButiranMasjid: true,
      butiranMasjidId: masjidId
    });
  };

  showModalNewMasjid = () => {
    this.setState({
      openNewMasjid: true
    });
  };

  closeModalDetailMasjid = () => {
    this.setState({
      openButiranMasjid: false,
      butiranMasjidId: null
    });
  };

  closeModalEditMasjid = refetch => {
    this.setState({
      openEditMasjid: false,
      editMasjidId: null
    });

    if (refetch) {
      const daerahCode = this.state.selectedDaerahCode;
      this.fetchChangeDaerah(daerahCode);
    }
  };

  closeModalNewMasjid = refetch => {
    this.setState({
      openNewMasjid: false
    });

    if (refetch) {
      const daerahCode = this.state.selectedDaerahCode;
      this.fetchChangeDaerah(daerahCode);
    }
  };

  render = () => {
    const {
      loadingPage,
      loadingPageError,
      senaraiDaerah,
      selectedDaerahDescription,
      loadingChangeDaerah,
      senaraiMasjid,
      loadingHapusMasjidId,
      openButiranMasjid,
      butiranMasjidId,
      openEditMasjid,
      editMasjidId,
      openNewMasjid,
      selectedDaerahCode
    } = this.state;

    return (
      <React.Fragment>
        <Internal />
        <div className="wrapper" style={{ marginBottom: 50 }}>
          <OperasiTitle title="Pengurusan Masjid" />

          {loadingPage ? (
            <InternalPageLoading error={loadingPageError} retryFn={this.fetch} />
          ) : (
            <React.Fragment>
              <ModalButiranMasjid
                isOpen={openButiranMasjid}
                closeFn={this.closeModalDetailMasjid}
                masjidId={butiranMasjidId}
              />

              <ModalEditMasjid
                isOpen={openEditMasjid}
                closeFn={this.closeModalEditMasjid}
                masjidId={editMasjidId}
              />

              <ModalNewMasjid
                isOpen={openNewMasjid}
                closeFn={this.closeModalNewMasjid}
                daerahCode={selectedDaerahCode}
              />

              <Card style={{ marginBottom: 15 }}>
                <p>Pilih daerah untuk melihat senarai masjid di dalam daerah :</p>

                <div className="bp3-select">
                  <select
                    style={{ minWidth: 300 }}
                    onChange={this.handleChangeDaerah}
                    value={selectedDaerahCode}
                  >
                    {senaraiDaerah.map(daerah => (
                      <option key={daerah.code} value={daerah.code}>
                        {daerah.description}
                      </option>
                    ))}
                  </select>
                </div>
              </Card>

              <Card>
                <div style={{ float: "right" }}>
                  <Tooltip content="Daftar masjid baru di dalam daerah ini">
                    <Button
                      icon="add"
                      disabled={loadingChangeDaerah}
                      onClick={e => {
                        e.stopPropagation();
                        this.showModalNewMasjid();
                      }}
                    >
                      Tambah Masjid Baru
                    </Button>
                  </Tooltip>
                </div>

                <h4 style={{ margin: "10px 0" }}>
                  Senarai Masjid Daerah - {selectedDaerahDescription}
                </h4>

                {loadingChangeDaerah ? (
                  <Spinner size={20} />
                ) : (
                  <React.Fragment>
                    <p>Klik pada nama masjid untuk melihat butiran penuh.</p>

                    <ReactTable
                      className="-highlight -striped"
                      noDataText={
                        <p style={{ margin: 0, textAlign: "center" }}>
                          Tiada data untuk dipaparkan
                        </p>
                      }
                      rowsText="Rekod"
                      pageText="Muka"
                      ofText="/"
                      data={senaraiMasjid}
                      columns={[
                        {
                          Header: "No",
                          accessor: "no",
                          width: 50,
                          className: "alignCenter pointer",
                          Cell: row => row.index + 1
                        },
                        {
                          Header: "Nama Masjid",
                          accessor: "nama",
                          className: "pointer",
                          filterable: true,
                          filterMethod: (state, record) => {
                            const { value } = state;
                            const { nama } = record;
                            const regex = new RegExp(value, "gi");
                            return regex.test(nama);
                          }
                        },
                        {
                          Header: "Daerah",
                          accessor: "daerah",
                          className: "alignCenter pointer",
                          Cell: () => selectedDaerahDescription.toUpperCase()
                        },
                        {
                          Header: "Bandar",
                          accessor: "bandar",
                          className: "alignCenter pointer"
                        },
                        {
                          Header: "Edit",
                          accessor: "edit",
                          className: "alignCenter",
                          width: 100,
                          Cell: row => (
                            <Button
                              small
                              icon="edit"
                              onClick={e => {
                                e.stopPropagation();
                                this.showModalEditMasjid(row.original._id);
                              }}
                              intent="primary"
                              minimal
                            >
                              Edit
                            </Button>
                          )
                        },
                        {
                          Header: "Hapus",
                          accessor: "hapus",
                          className: "alignCenter",
                          width: 100,
                          Cell: row => (
                            <Popover
                              minimal
                              content={
                                <DeletePrompt
                                  title="Hapus Rekod"
                                  message={
                                    <React.Fragment>
                                      <span style={{ marginBottom: 10, display: "block" }}>
                                        Masjid: <Tag>{row.original.nama}</Tag>
                                      </span>
                                    </React.Fragment>
                                  }
                                  proceed={() => this.hapus(row.original._id)}
                                />
                              }
                              target={
                                <Button
                                  small
                                  icon="cross"
                                  intent="danger"
                                  minimal
                                  loading={loadingHapusMasjidId === row.original._id}
                                >
                                  Hapus
                                </Button>
                              }
                            />
                          )
                        }
                      ]}
                      getTdProps={(state, rowInfo, column, instance) => {
                        return {
                          onClick: () => {
                            const { id } = column;
                            if (id !== "edit" && id !== "hapus" && rowInfo) {
                              this.showModalDetailMasjid(rowInfo.original._id);
                            }
                          }
                        };
                      }}
                    />
                  </React.Fragment>
                )}
              </Card>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  };
}

export default Operasi;
