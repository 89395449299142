import React from "react";
import { Navbar, Alignment, Button } from "@blueprintjs/core";
import axios from "axios";
import { API_URL } from "../constant";
import AppToaster from "./AppToaster";
import { withRouter } from "react-router-dom";

class Internal extends React.Component {
  state = {
    loadingLogout: false
  };

  logout = async () => {
    try {
      this.setState({
        loadingLogout: true
      });

      const res = await axios.post(
        `${API_URL}/logout`,
        {},
        { withCredentials: true }
      );

      if (!res.data.success) {
        throw Error(res.data.message);
      }

      localStorage.clear();

      this.props.history.replace("/");
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign"
      });

      this.setState({
        loadingLogout: false
      });
    }
  };

  render = () => {
    const { loadingLogout } = this.state;

    return (
      <React.Fragment>
        <div className="public topbar">
          <div className="wrapper">
            <p className="left">ePAYNS</p>
            <p className="right">Yayasan Islam Darul Ehsan</p>
          </div>
        </div>
        <Navbar className="bp3-dark">
          <div className="wrapper">
            <Navbar.Group align={Alignment.LEFT}>
              <Button
                small
                className="bp3-minimal"
                icon="horizontal-bar-chart"
                text="Ringkasan"
                onClick={() => this.props.history.push("/ringkasan")}
              />
              <Navbar.Divider />
              <Button
                small
                className="bp3-minimal"
                icon="edit"
                text="Operasi"
                onClick={() => this.props.history.push("/operasi")}
              />
              <Navbar.Divider />
              <Button
                small
                className="bp3-minimal"
                icon="git-repo"
                text="Laporan"
                onClick={() => this.props.history.push("/laporan")}
              />
            </Navbar.Group>
            <Navbar.Group align={Alignment.RIGHT}>
              <Button
                small
                className="bp3-minimal"
                icon="person"
                text="Profil"
                onClick={() => this.props.history.push("/profil")}
              />
              <Navbar.Divider />
              <Button
                small
                className="bp3-minimal"
                icon="log-out"
                text="Logout"
                onClick={this.logout}
                loading={loadingLogout}
              />
            </Navbar.Group>
          </div>
        </Navbar>
      </React.Fragment>
    );
  };
}

export default withRouter(Internal);
