import React, { useState, useEffect } from "react";
import PublicHeader from "../components/PublicHeader";
import moment from "moment";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../constant";
import AppToaster from "../components/AppToaster";
import {
  Callout,
  TextArea,
  Button,
  Icon,
  AnchorButton,
} from "@blueprintjs/core";
import PublicFooter from "../components/PublicFooter";

function JantinaLabel(value) {
  if (value === "L") {
    return "LELAKI";
  } else if (value === "P") {
    return "PEREMPUAN";
  }

  return "Tidak Diketahui";
}

function convertToUpperCase(value) {
  if (!value || value === "") {
    return "Tiada maklumat";
  }

  return value.toUpperCase();
}

function properDate(date) {
  if (!date) {
    return "Tiada maklumat";
  }

  return moment(date).format("DD-MM-YYYY");
}

const mapKategoriYatim = {
  YATIM: "YATIM",
  YATIM_PIATU: "YATIM PIATU",
};

function Semakan(props) {
  const [loadingData, setLoadingData] = useState(true);
  const [pendaftaran, setPendaftaran] = useState({});
  const [perubahan, setPerubahan] = useState("");
  const [loadingHantar, setLoadingHantar] = useState(false);

  async function fetch(pendaftaranId) {
    try {
      setLoadingData(true);

      const res = await axios.get(`${API_URL}/pendaftaran/${pendaftaranId}`, {
        withCredentials: true,
      });

      if (!res.data.success) {
        throw Error(res.data.message);
      }

      setPendaftaran(res.data.result);

      // hack (callback)
      setTimeout(() => {
        setLoadingData(false);
      }, 100);
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign",
      });
    }
  }

  async function hantar() {
    try {
      setLoadingHantar(true);

      const res = await axios.post(
        `${API_URL}/semakan`,
        {
          pendaftaranId: props.match.params.id,
          perubahan,
        },
        {
          withCredentials: true,
        }
      );

      if (!res.data.success) {
        throw Error(res.data.message);
      }

      setLoadingHantar(false);

      AppToaster.show({
        message: "Permohonan perubahan maklumat telah dihantar",
        intent: "success",
        icon: "warning-sign",
      });

      props.history.replace("/");
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign",
      });

      setLoadingHantar(false);
    }
  }

  useEffect(() => {
    fetch(props.match.params.id);
  }, [props.match.params.id]);

  const StatusPendaftaran = ({ status }) => {
    if (status) {
      return (
        <span style={{ color: "green" }}>
          PENDAFTARAN LENGKAP <Icon icon="tick-circle" iconSize={12} />
        </span>
      );
    } else {
      return (
        <span style={{ color: "red" }}>BELUM DIHANTAR / SEDANG DIPROSES</span>
      );
    }
  };

  return (
    <React.Fragment>
      <PublicHeader />
      <div className="wrapper fixed" style={{ marginBottom: 50 }}>
        <h2 className="title">Semakan Pendaftaran</h2>

        {loadingData ? (
          "Loading..."
        ) : (
          <React.Fragment>
            <Callout intent="primary" style={{ marginBottom: 15 }}>
              <p>Di bawah adalah hasil semakan yang ditemui dalam sistem.</p>
              <p>
                Jika anda (penjaga/penama) yang tertera di bawah dan ingin
                lakukan perubahan maklumat seperti perubahan no akaun bank, nama
                anak yatim, no kp anak yatim dan sebagainya - sila isi ruang di
                paling bawah dan tekan butang Hantar.
              </p>
            </Callout>

            <table className="aligntop" style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td>
                    <b>No Siri</b>
                  </td>
                  <td>:</td>
                  <td>
                    <code>{pendaftaran.noSiriBorang}</code>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Tarikh Daftar</b>
                  </td>
                  <td>:</td>
                  <td>
                    {moment(pendaftaran.dateCreated).format(
                      "DD-MM-YYYY,  hh:mm A"
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Status Pendaftaran</b>
                  </td>
                  <td>:</td>
                  <td>
                    <StatusPendaftaran status={pendaftaran.sudahDihantar} />
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <p className="cellTitle">Butiran Penjaga</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: 150 }}>
                    <b>Nama</b>
                  </td>
                  <td>:</td>
                  <td>{pendaftaran.namaPenjaga}</td>
                </tr>
                <tr>
                  <td>
                    <b>No.KP/Passport</b>
                  </td>
                  <td>:</td>
                  <td>{pendaftaran.noKadPengenalanPenjaga}</td>
                </tr>
                <tr>
                  <td>
                    <b>No Tel Bimbit</b>
                  </td>
                  <td>:</td>
                  <td>{pendaftaran.noTelBimbitPenjaga}</td>
                </tr>
                <tr>
                  <td>
                    <b>No Tel Rumah</b>
                  </td>
                  <td>:</td>
                  <td>{pendaftaran.noTelRumahPenjaga}</td>
                </tr>
                <tr>
                  <td>
                    <b>Emel</b>
                  </td>
                  <td>:</td>
                  <td>{pendaftaran.emelPenjaga}</td>
                </tr>
                <tr>
                  <td>
                    <b>Alamat Rumah</b>
                  </td>
                  <td>:</td>
                  <td>{pendaftaran.alamat1Penjaga}</td>
                </tr>
                <tr>
                  <td />
                  <td />
                  <td>{pendaftaran.alamat2Penjaga}</td>
                </tr>
                <tr>
                  <td />
                  <td />
                  <td>
                    {pendaftaran.alamatPoskodPenjaga}{" "}
                    {pendaftaran.alamatBandarPenjaga}
                  </td>
                </tr>
                <tr>
                  <td />
                  <td />
                  <td>{pendaftaran.alamatNegeriPenjaga}</td>
                </tr>

                <tr>
                  <td colSpan={3}>
                    <p className="cellTitle">Pekerjaan Penjaga</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Pekerjaan</b>
                  </td>
                  <td>:</td>
                  <td>{pendaftaran.pekerjaanPenjaga}</td>
                </tr>
                <tr>
                  <td>
                    <b>No Tel Pejabat</b>
                  </td>
                  <td>:</td>
                  <td>{pendaftaran.noTelPejabatPenjaga}</td>
                </tr>
                <tr>
                  <td>
                    <b>Alamat</b>
                  </td>
                  <td>:</td>
                  <td>{pendaftaran.alamatTempatKerjaPenjaga}</td>
                </tr>
                <tr>
                  <td>
                    <b>Pendapatan</b>
                  </td>
                  <td>:</td>
                  <td>{pendaftaran.pendapatan}</td>
                </tr>

                <tr>
                  <td colSpan={3}>
                    <p className="cellTitle">Maklumat Bank Penjaga</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Bank</b>
                  </td>
                  <td>:</td>
                  <td>{pendaftaran.namaBank}</td>
                </tr>
                <tr>
                  <td>
                    <b>No Akaun Bank</b>
                  </td>
                  <td>:</td>
                  <td>{pendaftaran.noAkaunBankPenjaga}</td>
                </tr>
                <tr>
                  <td>
                    <b>Nama Pemilik</b>
                  </td>
                  <td>:</td>
                  <td>{pendaftaran.namaPemilikAkaunPenjaga}</td>
                </tr>

                <tr>
                  <td colSpan={3}>
                    <p className="cellTitle">Maklumat Waris</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Nama Waris</b>
                  </td>
                  <td>:</td>
                  <td>{pendaftaran.namaWaris}</td>
                </tr>
                <tr>
                  <td>
                    <b>No Tel Bimbit</b>
                  </td>
                  <td>:</td>
                  <td>{pendaftaran.noTelBimbitWaris}</td>
                </tr>
                <tr>
                  <td>
                    <b>No Tel Rumah</b>
                  </td>
                  <td>:</td>
                  <td>{pendaftaran.noTelRumahWaris}</td>
                </tr>

                <tr>
                  <td colSpan={3}>
                    <p className="cellTitle">Maklumat Masjid Kariah</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Daerah</b>
                  </td>
                  <td>:</td>
                  <td>{pendaftaran.namaDaerah}</td>
                </tr>
                <tr>
                  <td>
                    <b>Masjid</b>
                  </td>
                  <td>:</td>
                  <td>{pendaftaran.namaMasjid}</td>
                </tr>

                <tr>
                  <td colSpan={3}>
                    <p className="cellTitle">Maklumat Kematian</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Kategori</b>
                  </td>
                  <td>:</td>
                  <td>
                    {mapKategoriYatim[pendaftaran.kategoriYatim] || "YATIM"}
                  </td>
                </tr>
                <tr>
                  <td colSpan={3} style={{ padding: "7px 0" }}></td>
                </tr>
                <tr>
                  <td>
                    <b>Nama Bapa</b>
                  </td>
                  <td>:</td>
                  <td>{convertToUpperCase(pendaftaran.kematianNamaBapa)}</td>
                </tr>
                <tr>
                  <td>
                    <b>Sebab Kematian</b>
                  </td>
                  <td>:</td>
                  <td>{convertToUpperCase(pendaftaran.sebabKematianBapa)}</td>
                </tr>
                <tr>
                  <td>
                    <b>Tarikh Kematian</b>
                  </td>
                  <td>:</td>
                  <td>{properDate(pendaftaran.kematianTarikhBapa)}</td>
                </tr>

                {pendaftaran.kategoriYatim === "YATIM_PIATU" && (
                  <>
                    <tr>
                      <td colSpan={3} style={{ padding: "7px 0" }}></td>
                    </tr>
                    <tr>
                      <td>
                        <b>Nama Ibu</b>
                      </td>
                      <td>:</td>
                      <td>{convertToUpperCase(pendaftaran.kematianNamaIbu)}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Sebab Kematian</b>
                      </td>
                      <td>:</td>
                      <td>
                        {convertToUpperCase(pendaftaran.sebabKematianIbu)}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Tarikh Kematian</b>
                      </td>
                      <td>:</td>
                      <td>{properDate(pendaftaran.kematianTarikhIbu)}</td>
                    </tr>
                  </>
                )}

                <tr>
                  <td colSpan={3}>
                    <p className="cellTitle">Maklumat Anak Yatim</p>
                  </td>
                </tr>
                {pendaftaran.anakYatim.map((anak, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td colSpan={3} style={{ paddingTop: 20 }}>
                        <i>No. {index + 1}</i>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Nama</b>
                      </td>
                      <td>:</td>
                      <td>{anak.nama}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>No KP/Passport</b>
                      </td>
                      <td>:</td>
                      <td>{anak.nokp}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Tarikh Lahir</b>
                      </td>
                      <td>:</td>
                      <td>{moment(anak.tarikhLahir).format("DD-MM-YYYY")}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Jantina</b>
                      </td>
                      <td>:</td>
                      <td>{JantinaLabel(anak.jantina)}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Hubungan</b>
                      </td>
                      <td>:</td>
                      <td>{anak.hubungan}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Nama Sekolah</b>
                      </td>
                      <td>:</td>
                      <td>{anak.namaSekolah}</td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>

            <div style={{ marginTop: 40 }}>
              <h3>Perubahan Maklumat</h3>
              <TextArea
                value={perubahan}
                fill
                style={{ height: 150 }}
                placeholder="Sila tuliskan apa-apa perubahan yang perlu dilakukan pada maklumat di atas."
                onChange={(e) => setPerubahan(e.target.value)}
              />

              <p style={{ marginTop: 5 }}>
                Contoh:{" "}
                <i>
                  Nama anak yatim sepatutnya Muhammad Aisy Bin Ali. No telefon
                  penjaga terbaru ialah 01311132321.
                </i>
              </p>

              <p>
                *Pihak Yayasan Islam Darul Ehsan mungkin akan menghubungi anda
                untuk sebarang pengesahan.
              </p>

              <Button intent="primary" onClick={hantar} loading={loadingHantar}>
                Hantar
              </Button>
              <Button
                onClick={() => props.history.replace("/")}
                style={{ marginLeft: 10 }}
              >
                Kembali
              </Button>

              <AnchorButton
                style={{ float: "right" }}
                intent="success"
                href={`${API_URL}/pendaftaran/borang/${pendaftaran._id}`}
                target="_blank"
              >
                Cetak Borang Permohonan
              </AnchorButton>
            </div>
          </React.Fragment>
        )}
      </div>

      <PublicFooter />
    </React.Fragment>
  );
}

export default withRouter(Semakan);
