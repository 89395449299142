import React from "react";
import { Dialog } from "@blueprintjs/core";
import AppToaster from "../components/AppToaster";
import axios from "axios";
import { API_URL } from "../constant";

class Modal extends React.Component {
  state = {
    loadingData: true,
    masjid: {}
  };

  fetch = async () => {
    const { masjidId } = this.props;

    try {
      const res = await axios.get(`${API_URL}/masjid/${masjidId}`, { withCredentials: true });

      if (!res.data.success) {
        throw Error(res.data.message);
      }

      this.setState({
        loadingData: false,
        masjid: res.data.result
      });
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign"
      });
    }
  };

  render = () => {
    const { isOpen, closeFn } = this.props;
    const { loadingData, masjid } = this.state;

    return (
      <Dialog
        transitionDuration={0}
        isOpen={isOpen}
        title="Butiran Masjid"
        style={{ width: 800 }}
        onOpening={this.fetch}
        onClose={() => {
          this.setState({
            loadingData: true
          });

          closeFn();
        }}
      >
        <div style={{ padding: 20 }}>
          {loadingData ? (
            "Loading..."
          ) : (
            <table className="aligntop">
              <tbody>
                <tr>
                  <td colSpan={2}>Butiran Umum</td>
                </tr>
                <tr>
                  <td>
                    <b>Nama Masjid</b>
                  </td>
                  <td>:</td>
                  <td>{masjid.nama}</td>
                </tr>
                <tr>
                  <td>
                    <b>Alamat</b>
                  </td>
                  <td>:</td>
                  <td>{masjid.alamat}</td>
                </tr>
                <tr>
                  <td>
                    <b>Daerah</b>
                  </td>
                  <td>:</td>
                  <td>
                    {masjid.daerah} ({masjid.daerahCode})
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Bandar</b>
                  </td>
                  <td>:</td>
                  <td>{masjid.bandar}</td>
                </tr>
                <tr>
                  <td>
                    <b>No.Telefon</b>
                  </td>
                  <td>:</td>
                  <td>{masjid.noTelefon}</td>
                </tr>
                <tr>
                  <td>
                    <b>No.Faks</b>
                  </td>
                  <td>:</td>
                  <td>{masjid.noFaks}</td>
                </tr>

                <tr>
                  <td colSpan={2} style={{ paddingTop: 15 }}>
                    Nazir
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Nama Nazir</b>
                  </td>
                  <td>:</td>
                  <td>{masjid.namaNazir}</td>
                </tr>
                <tr>
                  <td>
                    <b>No Telefon Nazir</b>
                  </td>
                  <td>:</td>
                  <td>{masjid.noTelefonNazir}</td>
                </tr>

                <tr>
                  <td colSpan={2} style={{ paddingTop: 15 }}>
                    Imam
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Nama Imam</b>
                  </td>
                  <td>:</td>
                  <td>{masjid.namaImam}</td>
                </tr>
                <tr>
                  <td>
                    <b>No Telefon Imam</b>
                  </td>
                  <td>:</td>
                  <td>{masjid.noTelefonImam}</td>
                </tr>

                <tr>
                  <td colSpan={2} style={{ paddingTop: 15 }}>
                    Setiausaha
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Nama Setiausaha</b>
                  </td>
                  <td>:</td>
                  <td>{masjid.namaSetiausaha}</td>
                </tr>
                <tr>
                  <td>
                    <b>No Telefon Setiausaha</b>
                  </td>
                  <td>:</td>
                  <td>{masjid.noTelefonSetiausaha}</td>
                </tr>

                <tr>
                  <td colSpan={2} style={{ paddingTop: 15 }}>
                    Bendahari
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Nama Bendahari</b>
                  </td>
                  <td>:</td>
                  <td>{masjid.namaBendahari}</td>
                </tr>
                <tr>
                  <td>
                    <b>No Telefon Bendahari</b>
                  </td>
                  <td>:</td>
                  <td>{masjid.noTelefonBendahari}</td>
                </tr>

                <tr>
                  <td colSpan={2} style={{ paddingTop: 15 }}>
                    Maklumat Bank
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Nama Bank</b>
                  </td>
                  <td>:</td>
                  <td>{masjid.namaBank}</td>
                </tr>
                <tr>
                  <td>
                    <b>No Akaun</b>
                  </td>
                  <td>:</td>
                  <td>{masjid.noAkaunBank}</td>
                </tr>
                <tr>
                  <td>
                    <b>Pemilik Akaun</b>
                  </td>
                  <td>:</td>
                  <td>{masjid.namaPemilikAkaunBank}</td>
                </tr>

                <tr>
                  <td colSpan={2} style={{ paddingTop: 15 }}>
                    Lain-Lain
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Emel</b>
                  </td>
                  <td>:</td>
                  <td>{masjid.emel}</td>
                </tr>
                <tr>
                  <td>
                    <b>Website</b>
                  </td>
                  <td>:</td>
                  <td>{masjid.website}</td>
                </tr>
                <tr>
                  <td>
                    <b>Facebook</b>
                  </td>
                  <td>:</td>
                  <td>{masjid.facebook}</td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </Dialog>
    );
  };
}

export default Modal;
