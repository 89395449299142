import React, { useState } from "react";
import Internal from "../components/Internal";
import OperasiTitle from "../components/OperasiTitle";
import AppToaster from "../components/AppToaster";
import axios from "axios";
import { API_URL } from "../constant";
import ReactTable, { ReactTableDefaults } from "react-table";
import { Button, InputGroup, Dialog, Alert } from "@blueprintjs/core";

const PWD = "123-admin";

Object.assign(ReactTableDefaults, {
  NextComponent: state => (
    <Button fill rightIcon="fast-forward" disabled={state.disabled} onClick={state.onClick}>
      Next
    </Button>
  ),
  PreviousComponent: state => (
    <Button fill icon="fast-backward" disabled={state.disabled} onClick={state.onClick}>
      Previous
    </Button>
  )
});

export default function OperasiPengguna() {
  const [isPassed, setIsPassed] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [result, setResult] = useState([]);
  const [savingUserBaru, setSavingUserBaru] = useState(false);
  const [isOpenModalBaru, setIsOpenModalBaru] = useState(false);
  const [isOpenConfirmHapus, setIsOpenConfirmHapus] = useState(false);
  const [hapusUser, setHapusUser] = useState({});
  const [isOpenConfirmReset, setIsOpenConfirmReset] = useState(false);
  const [resetUser, setResetUser] = useState({});

  async function fetch() {
    try {
      setLoadingPage(true);

      const res = await axios.get(`${API_URL}/pengguna`, { withCredentials: true });

      if (!res.data.success) {
        throw Error(res.data.message);
      }

      setLoadingPage(false);
      setResult(res.data.result);
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign"
      });
    }
  }

  function submitPassword(e) {
    e.preventDefault();

    if (e.target.pwd.value === PWD) {
      setIsPassed(true);
      fetch();
    } else {
      AppToaster.show({
        message: "Password tidak sah!",
        intent: "danger",
        icon: "warning-sign"
      });
    }
  }

  async function simpanPenggunaBaru(e) {
    e.preventDefault();
    const { value: username } = e.target.username;
    const { value: password } = e.target.password;

    try {
      setSavingUserBaru(true);

      const res = await axios.post(
        `${API_URL}/pengguna/baru`,
        { username, password },
        { withCredentials: true }
      );

      if (!res.data.success) {
        throw Error(res.data.message);
      }

      setSavingUserBaru(false);

      AppToaster.show({
        message: "User telah berjaya didaftar",
        intent: "success",
        icon: "tick"
      });

      //tutup modal
      setIsOpenModalBaru(false);

      //re-fetch
      fetch();
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign"
      });

      setSavingUserBaru(false);
    }
  }

  async function proccedHapusUser(userId) {
    try {
      const res = await axios.post(
        `${API_URL}/pengguna/hapus`,
        { userId },
        { withCredentials: true }
      );

      if (!res.data.success) {
        throw Error(res.data.message);
      }

      //tutup modal
      setIsOpenConfirmHapus(false);

      //re-fetch
      fetch();
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign"
      });
    }
  }

  async function proccedResetUser(userId) {
    try {
      const res = await axios.post(
        `${API_URL}/pengguna/reset`,
        { userId },
        { withCredentials: true }
      );

      if (!res.data.success) {
        throw Error(res.data.message);
      }

      //tutup modal
      setIsOpenConfirmReset(false);

      AppToaster.show({
        message: "Password telah berjaya di-reset",
        intent: "success",
        icon: "warning-sign"
      });
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign"
      });
    }
  }

  return (
    <React.Fragment>
      <Internal />
      <div className="wrapper fixed" style={{ marginBottom: 50 }}>
        <OperasiTitle title="Pengguna Sistem" />

        {!isPassed ? (
          // <InternalPageLoading error={loadingPageError} retryFn={fetch} />
          <React.Fragment>
            <p>Sila masukkan password admin untuk melihat butiran page ini:</p>
            <form onSubmit={submitPassword}>
              <InputGroup
                style={{ width: 250 }}
                placeholder="Admin Password"
                name="pwd"
                type="password"
              />
              <br />
              <Button type="submit" intent="primary">
                Submit
              </Button>
            </form>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <p>
              Senarai pengguna sistem :
              <Button
                style={{ float: "right" }}
                intent="success"
                icon="plus"
                onClick={() => setIsOpenModalBaru(true)}
              >
                Tambah Pengguna Baru
              </Button>
            </p>
            <div style={{ clear: "both" }} />
            <ReactTable
              style={{ marginTop: 10 }}
              pageSize={10}
              loading={loadingPage}
              className="-highlight -striped"
              noDataText={
                <p style={{ margin: 0, textAlign: "center" }}>Tiada data untuk dipaparkan</p>
              }
              rowsText="Rekod"
              pageText="Muka"
              ofText="/"
              data={result}
              columns={[
                {
                  Header: "No",
                  accessor: "no",
                  Cell: row => row.index + 1,
                  width: 50,
                  className: "alignCenter"
                },
                {
                  Header: "Username",
                  accessor: "username"
                },
                {
                  Header: "Options",
                  accessor: "options",
                  className: "alignRight",
                  Cell: row => (
                    <React.Fragment>
                      <Button
                        small
                        style={{ marginRight: 5 }}
                        onClick={() => {
                          setIsOpenConfirmReset(true);
                          setResetUser(row.original);
                        }}
                      >
                        Reset Password
                      </Button>
                      <Button
                        intent="danger"
                        small
                        onClick={() => {
                          setIsOpenConfirmHapus(true);
                          setHapusUser(row.original);
                        }}
                      >
                        Hapus
                      </Button>
                    </React.Fragment>
                  )
                }
              ]}
            />

            <Dialog
              isOpen={isOpenModalBaru}
              onClose={() => {
                setIsOpenModalBaru(false);
              }}
              title="Tambah Pengguna Baru"
              canEscapeKeyClose={false}
              canOutsideClickClose={false}
            >
              <form style={{ padding: "10px 20px" }} onSubmit={simpanPenggunaBaru}>
                <div style={{ marginBottom: 10 }}>
                  Username:
                  <InputGroup
                    placeholder="Username"
                    name="username"
                    autoComplete="off"
                    disabled={savingUserBaru}
                  />
                </div>
                <div>
                  Password*:
                  <InputGroup value="123" readOnly name="password" disabled={savingUserBaru} />
                </div>
                <p style={{ marginTop: 20 }}>
                  *Password asal ialah "123" dan boleh ditukar kemudian.
                  <br />
                  <i>
                    (Log masuk sebagai pengguna baru tersebut dan klik pada Profil untuk ubah
                    password)
                  </i>
                </p>
                <Button intent="primary" type="submit" loading={savingUserBaru}>
                  Simpan
                </Button>
              </form>
            </Dialog>

            <Alert
              isOpen={isOpenConfirmHapus}
              confirmButtonText="Hapus"
              intent="danger"
              cancelButtonText="Batal"
              onCancel={() => setIsOpenConfirmHapus(false)}
              onConfirm={() => proccedHapusUser(hapusUser._id)}
            >
              <p>
                Hapus user <b>"{hapusUser.username}"</b> ?
              </p>
            </Alert>

            <Alert
              isOpen={isOpenConfirmReset}
              confirmButtonText="Reset Password"
              intent="primary"
              cancelButtonText="Batal"
              onCancel={() => setIsOpenConfirmReset(false)}
              onConfirm={() => proccedResetUser(resetUser._id)}
            >
              <p>
                Reset password untuk user <b>"{resetUser.username}"</b> ?
                <br />
                Password akan ditukar menjadi password asal iaitu : "123".
              </p>
            </Alert>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
}
