import React from "react";
import { Dialog, Button } from "@blueprintjs/core";
import AppToaster from "../components/AppToaster";
import axios from "axios";
import { API_URL } from "../constant";
import serialize from "form-serialize";

class Modal extends React.Component {
  state = {
    loadingData: true,
    masjid: {},
    senaraiDaerah: [],
    senaraiBank: [],
    loadingSubmit: false
  };

  submit = async e => {
    e.preventDefault();

    try {
      this.setState({
        loadingSubmit: true
      });

      const { masjid } = this.state;
      const data = serialize(e.target, { hash: true, empty: true });

      if (data.nama === "") {
        throw Error("Nama masjid tidak boleh dibiarkan kosong");
      }

      const res = await axios.post(
        `${API_URL}/masjid/edit`,
        {
          masjidId: masjid._id,
          data
        },
        {
          withCredentials: true
        }
      );

      if (!res.data.success) {
        throw Error(res.data.message);
      }

      this.setState({
        loadingSubmit: false,
        masjid: {} // bugfix : kena letak ni, kalau tak dialog display state yang lama
      });

      AppToaster.show({
        message: "Rekod telah dikemaskini",
        intent: "success",
        icon: "tick"
      });

      this.props.closeFn(true);
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign"
      });

      this.setState({
        loadingSubmit: false
      });
    }
  };

  fetch = async () => {
    const { masjidId } = this.props;

    try {
      //const res = await axios.get(`${API_URL}/masjid/${masjidId}`, { withCredentials: true });

      const res = await Promise.all([
        axios.get(`${API_URL}/masjid/${masjidId}`, { withCredentials: true }),
        axios.get(`${API_URL}/bank`),
        axios.get(`${API_URL}/daerah`)
      ]);

      if (!res[0].data.success) {
        throw Error(res[0].data.message);
      }

      this.setState({
        loadingData: false,
        masjid: res[0].data.result,
        senaraiBank: res[1].data.result,
        senaraiDaerah: res[2].data.result
      });
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign"
      });
    }
  };

  render = () => {
    const { isOpen, closeFn } = this.props;
    const { loadingData, masjid, senaraiBank, senaraiDaerah } = this.state;

    return (
      <Dialog
        transitionDuration={0}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        isOpen={isOpen}
        title="Edit Butiran Masjid"
        style={{ width: 800 }}
        onOpening={this.fetch}
        onClose={() => {
          this.setState({
            masjid: {},
            senaraiBank: [],
            senaraiDaerah: [],
            loadingData: true
          });

          closeFn();
        }}
      >
        <div style={{ padding: 20 }}>
          {loadingData ? (
            "Loading..."
          ) : (
            <form onSubmit={this.submit}>
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td colSpan={2}>Butiran Umum</td>
                  </tr>
                  <tr>
                    <td style={{ width: 180 }}>
                      <b>Nama Masjid</b>
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        type="text"
                        defaultValue={masjid.nama}
                        name="nama"
                        className="bp3-input bp3-fill"
                        autoComplete="off"
                        required
                        placeholder="Nama Masjid"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Alamat</b>
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        type="text"
                        defaultValue={masjid.alamat}
                        name="alamat"
                        className="bp3-input bp3-fill"
                        autoComplete="off"
                        placeholder="Alamat"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Daerah</b>
                    </td>
                    <td>:</td>
                    <td>
                      <div className="bp3-select">
                        <select
                          style={{ minWidth: 300 }}
                          value={masjid.daerahCode}
                          name="daerahCode"
                          onChange={e => {
                            let value = e.target.value;

                            this.setState(prevState => {
                              let { masjid } = prevState;
                              masjid.daerahCode = value;
                              return { masjid };
                            });
                          }}
                        >
                          {senaraiDaerah.map(daerah => (
                            <option key={daerah.code} value={daerah.code}>
                              {daerah.description}
                            </option>
                          ))}
                        </select>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Bandar</b>
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        type="text"
                        defaultValue={masjid.bandar}
                        name="bandar"
                        className="bp3-input bp3-fill"
                        autoComplete="off"
                        placeholder="Bandar"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>No.Telefon</b>
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        type="text"
                        defaultValue={masjid.noTelefon}
                        name="noTelefon"
                        className="bp3-input bp3-fill"
                        autoComplete="off"
                        placeholder="No Telefon"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>No.Faks</b>
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        type="text"
                        defaultValue={masjid.noFaks}
                        name="noFaks"
                        className="bp3-input bp3-fill"
                        autoComplete="off"
                        placeholder="No Faks"
                      />
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={2} style={{ paddingTop: 15 }}>
                      Nazir
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Nama Nazir</b>
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        type="text"
                        defaultValue={masjid.namaNazir}
                        name="namaNazir"
                        className="bp3-input bp3-fill"
                        autoComplete="off"
                        placeholder="Nama Nazir"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>No Telefon Nazir</b>
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        type="text"
                        defaultValue={masjid.noTelefonNazir}
                        name="noTelefonNazir"
                        className="bp3-input bp3-fill"
                        autoComplete="off"
                        placeholder="No Telefon Nazir"
                      />
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={2} style={{ paddingTop: 15 }}>
                      Imam
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Nama Imam</b>
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        type="text"
                        defaultValue={masjid.namaImam}
                        name="namaImam"
                        className="bp3-input bp3-fill"
                        autoComplete="off"
                        placeholder="Nama Imam"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>No Telefon Imam</b>
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        type="text"
                        defaultValue={masjid.noTelefonImam}
                        name="noTelefonImam"
                        className="bp3-input bp3-fill"
                        autoComplete="off"
                        placeholder="No Telefon Imam"
                      />
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={2} style={{ paddingTop: 15 }}>
                      Setiausaha
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Nama Setiausaha</b>
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        type="text"
                        defaultValue={masjid.namaSetiausaha}
                        name="namaSetiausaha"
                        className="bp3-input bp3-fill"
                        autoComplete="off"
                        placeholder="Nama Setiausaha"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>No Telefon Setiausaha</b>
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        type="text"
                        defaultValue={masjid.noTelefonSetiausaha}
                        name="noTelefonSetiausaha"
                        className="bp3-input bp3-fill"
                        autoComplete="off"
                        placeholder="No Telefon Setiausaha"
                      />
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={2} style={{ paddingTop: 15 }}>
                      Bendahari
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Nama Bendahari</b>
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        type="text"
                        defaultValue={masjid.bendahari}
                        name="bendahari"
                        className="bp3-input bp3-fill"
                        autoComplete="off"
                        placeholder="Nama Bendahari"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>No Telefon Bendahari</b>
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        type="text"
                        defaultValue={masjid.noTelefonBendahari}
                        name="noTelefonBendahari"
                        className="bp3-input bp3-fill"
                        autoComplete="off"
                        placeholder="No Telefon Bendahari"
                      />
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={2} style={{ paddingTop: 15 }}>
                      Maklumat Bank
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Nama Bank</b>
                    </td>
                    <td>:</td>
                    <td>
                      <div className="bp3-select">
                        <select
                          style={{ minWidth: 300 }}
                          name="bankId"
                          defaultValue={masjid.bankId || ""}
                        >
                          <option value="">--Tiada--</option>
                          {senaraiBank.map(bank => (
                            <option key={bank._id} value={bank._id}>
                              {bank.description}
                            </option>
                          ))}
                        </select>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>No Akaun</b>
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        type="text"
                        defaultValue={masjid.noAkaunBank}
                        name="noAkaunBank"
                        className="bp3-input bp3-fill"
                        autoComplete="off"
                        placeholder="No Akaun Bank"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Pemilik Akaun</b>
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        type="text"
                        defaultValue={masjid.namaPemilikAkaunBank}
                        name="namaPemilikAkaunBank"
                        className="bp3-input bp3-fill"
                        autoComplete="off"
                        placeholder="Nama Pemilik Akaun Bank"
                      />
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={2} style={{ paddingTop: 15 }}>
                      Lain-Lain
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Emel</b>
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        type="text"
                        defaultValue={masjid.emel}
                        name="emel"
                        className="bp3-input bp3-fill"
                        autoComplete="off"
                        placeholder="Emel"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Website</b>
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        type="text"
                        defaultValue={masjid.website}
                        name="website"
                        className="bp3-input bp3-fill"
                        autoComplete="off"
                        placeholder="Website"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Facebook</b>
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        type="text"
                        defaultValue={masjid.facebook}
                        name="facebook"
                        className="bp3-input bp3-fill"
                        autoComplete="off"
                        placeholder="Facebook"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <Button
                intent="primary"
                style={{ float: "right", marginTop: 20 }}
                icon="tick"
                type="submit"
              >
                Simpan
              </Button>
            </form>
          )}
        </div>
      </Dialog>
    );
  };
}

export default Modal;
