import React from "react";
import { Button, Dialog } from "@blueprintjs/core";
import AppToaster from "../components/AppToaster";
import axios from "axios";
import { API_URL } from "../constant";
import moment from "moment";

const mapKategoriYatim = {
  YATIM: "YATIM",
  YATIM_PIATU: "YATIM PIATU",
};

class Modal extends React.Component {
  state = {
    loadingData: true,
    pendaftaran: {},
  };

  fetch = async () => {
    const { pendaftaranId } = this.props;

    try {
      const res = await axios.get(`${API_URL}/pendaftaran/${pendaftaranId}`, {
        withCredentials: true,
      });

      if (!res.data.success) {
        throw Error(res.data.message);
      }

      this.setState({
        loadingData: false,
        pendaftaran: res.data.result,
      });
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign",
      });
    }
  };

  dateKematian(value) {
    if (value) {
      return moment(value).format("DD-MM-YYYY");
    }
    return "-";
  }

  render = () => {
    const { isOpen, closeFn } = this.props;
    const { loadingData, pendaftaran } = this.state;

    return (
      <Dialog
        transitionDuration={0}
        isOpen={isOpen}
        title={
          <div style={{ display: "flex" }}>
            <span>Butiran Pendaftaran</span>
            <Button
              small
              intent="primary"
              style={{ marginLeft: 10 }}
              icon="edit"
              onClick={() => this.props.edit(this.props.pendaftaranId)}
            >
              Edit
            </Button>
          </div>
        }
        style={{ width: 800 }}
        onOpening={this.fetch}
        onClose={() => {
          this.setState({
            loadingData: true,
          });

          closeFn();
        }}
      >
        <div style={{ padding: 20 }}>
          {loadingData ? (
            "Loading..."
          ) : (
            <table className="aligntop" style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td>
                    <b>No Siri</b>
                  </td>
                  <td>:</td>
                  <td>
                    <code>{pendaftaran.noSiriBorang}</code>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Tarikh Daftar</b>
                  </td>
                  <td>:</td>
                  <td>
                    {moment(pendaftaran.dateCreated).format(
                      "DD-MM-YYYY,  hh:mm A"
                    )}
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <p className="cellTitle">Butiran Penjaga</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: 150 }}>
                    <b>Nama</b>
                  </td>
                  <td>:</td>
                  <td>{pendaftaran.namaPenjaga}</td>
                </tr>
                <tr>
                  <td>
                    <b>No.KP/Passport</b>
                  </td>
                  <td>:</td>
                  <td>{pendaftaran.noKadPengenalanPenjaga}</td>
                </tr>
                <tr>
                  <td>
                    <b>No Tel Bimbit</b>
                  </td>
                  <td>:</td>
                  <td>{pendaftaran.noTelBimbitPenjaga}</td>
                </tr>
                <tr>
                  <td>
                    <b>No Tel Rumah</b>
                  </td>
                  <td>:</td>
                  <td>{pendaftaran.noTelRumahPenjaga}</td>
                </tr>
                <tr>
                  <td>
                    <b>Emel</b>
                  </td>
                  <td>:</td>
                  <td>{pendaftaran.emelPenjaga}</td>
                </tr>
                <tr>
                  <td>
                    <b>Alamat Rumah</b>
                  </td>
                  <td>:</td>
                  <td>{pendaftaran.alamat1Penjaga}</td>
                </tr>
                <tr>
                  <td />
                  <td />
                  <td>{pendaftaran.alamat2Penjaga}</td>
                </tr>
                <tr>
                  <td />
                  <td />
                  <td>
                    {pendaftaran.alamatPoskodPenjaga}{" "}
                    {pendaftaran.alamatBandarPenjaga}
                  </td>
                </tr>
                <tr>
                  <td />
                  <td />
                  <td>{pendaftaran.alamatNegeriPenjaga}</td>
                </tr>

                <tr>
                  <td colSpan={3}>
                    <p className="cellTitle">Pekerjaan Penjaga</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Pekerjaan</b>
                  </td>
                  <td>:</td>
                  <td>{pendaftaran.pekerjaanPenjaga}</td>
                </tr>
                <tr>
                  <td>
                    <b>No Tel Pejabat</b>
                  </td>
                  <td>:</td>
                  <td>{pendaftaran.noTelPejabatPenjaga}</td>
                </tr>
                <tr>
                  <td>
                    <b>Alamat</b>
                  </td>
                  <td>:</td>
                  <td>{pendaftaran.alamatTempatKerjaPenjaga}</td>
                </tr>
                <tr>
                  <td>
                    <b>Pendapatan</b>
                  </td>
                  <td>:</td>
                  <td>{pendaftaran.pendapatan}</td>
                </tr>

                <tr>
                  <td colSpan={3}>
                    <p className="cellTitle">Maklumat Bank Penjaga</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Bank</b>
                  </td>
                  <td>:</td>
                  <td>{pendaftaran.namaBank}</td>
                </tr>
                <tr>
                  <td>
                    <b>No Akaun Bank</b>
                  </td>
                  <td>:</td>
                  <td>{pendaftaran.noAkaunBankPenjaga}</td>
                </tr>
                <tr>
                  <td>
                    <b>Nama Pemilik</b>
                  </td>
                  <td>:</td>
                  <td>{pendaftaran.namaPemilikAkaunPenjaga}</td>
                </tr>

                <tr>
                  <td colSpan={3}>
                    <p className="cellTitle">Maklumat Waris</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Nama Waris</b>
                  </td>
                  <td>:</td>
                  <td>{pendaftaran.namaWaris}</td>
                </tr>
                <tr>
                  <td>
                    <b>No Tel Bimbit</b>
                  </td>
                  <td>:</td>
                  <td>{pendaftaran.noTelBimbitWaris}</td>
                </tr>
                <tr>
                  <td>
                    <b>No Tel Rumah</b>
                  </td>
                  <td>:</td>
                  <td>{pendaftaran.noTelRumahWaris}</td>
                </tr>

                <tr>
                  <td colSpan={3}>
                    <p className="cellTitle">Maklumat Masjid Kariah</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Daerah</b>
                  </td>
                  <td>:</td>
                  <td>{pendaftaran.namaDaerah}</td>
                </tr>
                <tr>
                  <td>
                    <b>Masjid</b>
                  </td>
                  <td>:</td>
                  <td>{pendaftaran.namaMasjid}</td>
                </tr>

                <tr>
                  <td colSpan={3}>
                    <p className="cellTitle">Maklumat Kematian</p>
                  </td>
                </tr>
                {mapKategoriYatim[pendaftaran.kategoriYatim] ? (
                  <>
                    <tr>
                      <td>
                        <b>Kategori</b>
                      </td>
                      <td>:</td>
                      <td>
                        {mapKategoriYatim[pendaftaran.kategoriYatim] || "-"}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3} style={{ padding: "5px 0" }}></td>
                    </tr>
                    <tr>
                      <td>
                        <b>Nama Bapa</b>
                      </td>
                      <td>:</td>
                      <td>{pendaftaran.kematianNamaBapa || "-"}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Tarikh Kematian</b>
                      </td>
                      <td>:</td>
                      <td>
                        {this.dateKematian(pendaftaran.kematianTarikhBapa)}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Sebab Kematian</b>
                      </td>
                      <td>:</td>
                      <td>{pendaftaran.sebabKematianBapa || "-"}</td>
                    </tr>

                    {pendaftaran.kategoriYatim === "YATIM_PIATU" && (
                      <>
                        <tr>
                          <td colSpan={3} style={{ padding: "5px 0" }}></td>
                        </tr>
                        <tr>
                          <td>
                            <b>Nama Ibu</b>
                          </td>
                          <td>:</td>
                          <td>{pendaftaran.kematianNamaIbu || "-"}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Tarikh Kematian</b>
                          </td>
                          <td>:</td>
                          <td>
                            {this.dateKematian(pendaftaran.kematianTarikhIbu)}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Sebab Kematian</b>
                          </td>
                          <td>:</td>
                          <td>{pendaftaran.sebabKematianIbu || "-"}</td>
                        </tr>
                      </>
                    )}
                  </>
                ) : (
                  <tr>
                    <td>
                      <b>Nota</b>
                    </td>
                    <td>:</td>
                    <td>Tiada maklumat kematian</td>
                  </tr>
                )}

                <tr>
                  <td colSpan={3}>
                    <p className="cellTitle">Maklumat Anak Yatim</p>
                  </td>
                </tr>

                {pendaftaran.anakYatim.map((anak, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td colSpan={3} style={{ paddingTop: 20 }}>
                        <i>No. {index + 1}</i>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Nama</b>
                      </td>
                      <td>:</td>
                      <td>{anak.nama}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>No KP/Passport</b>
                      </td>
                      <td>:</td>
                      <td>{anak.nokp}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Tarikh Lahir</b>
                      </td>
                      <td>:</td>
                      <td>{moment(anak.tarikhLahir).format("DD-MM-YYYY")}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Jantina</b>
                      </td>
                      <td>:</td>
                      <td>{JantinaLabel(anak.jantina)}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Hubungan</b>
                      </td>
                      <td>:</td>
                      <td>{anak.hubungan}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Nama Sekolah</b>
                      </td>
                      <td>:</td>
                      <td>{anak.namaSekolah}</td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </Dialog>
    );
  };
}

function JantinaLabel(value) {
  if (value === "L") {
    return "LELAKI";
  } else if (value === "P") {
    return "PEREMPUAN";
  }

  return "Tidak Diketahui";
}

export default Modal;
