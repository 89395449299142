import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
// import Router from "react-router-dom/BrowserRouter";
// import Switch from "react-router-dom/Switch";
// import Route from "react-router-dom/Route";
import PrivateRoute from "./components/PrivateRoute";
import Public from "./pages/Public";
import Pendaftaran from "./pages/Pendaftaran";
import PendaftaranBerjaya from "./pages/PendaftaranBerjaya";
import Ringkasan from "./pages/Ringkasan";
import Operasi from "./pages/Operasi";
import OperasiMasjid from "./pages/OperasiMasjid";
import OperasiPendaftaran from "./pages/OperasiPendaftaran";
import OperasiSumbangan from "./pages/OperasiSumbangan";
import OperasiTetapan from "./pages/OperasiTetapan";
import Laporan from "./pages/Laporan";
import Profil from "./pages/Profil";
import OperasiPengguna from "./pages/OperasiPengguna";
import Semakan from "./pages/Semakan";
import OperasiPengubahan from "./pages/OperasiPengubahan";
class App extends Component {
  render() {
    return (
        <Router basename="/">
          <Switch>
            <PrivateRoute exact path="/" component={Ringkasan} />
            <PrivateRoute exact path="/ringkasan" component={Ringkasan} />
            <PrivateRoute exact path="/operasi" component={Operasi} />
            <PrivateRoute exact path="/operasi/masjid" component={OperasiMasjid} />
            <PrivateRoute exact path="/operasi/pendaftaran" component={OperasiPendaftaran} />
            <PrivateRoute exact path="/operasi/sumbangan" component={OperasiSumbangan} />
            <PrivateRoute exact path="/operasi/tetapan" component={OperasiTetapan} />
            <PrivateRoute exact path="/operasi/pengguna" component={OperasiPengguna} />
            <PrivateRoute exact path="/operasi/pengubahan" component={OperasiPengubahan} />
            <PrivateRoute exact path="/laporan" component={Laporan} />
            <PrivateRoute exact path="/profil" component={Profil} />
            <Route exact path="/" component={Public} />
            <Route exact path="/pendaftaran" component={Pendaftaran} />
            <Route exact path="/pendaftaran/berjaya/:id/:nosiri" component={PendaftaranBerjaya} />
            <Route exact path="/semakan/:id" component={Semakan} />
          </Switch>
        </Router>
    );
  }
}

export default App;
