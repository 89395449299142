// ini adalah public page (landing)
// yang dilihat oleh public atau sesiapa yang belum login

import React from "react";
import {
  Button,
  FormGroup,
  InputGroup,
  Card,
  Spinner,
  Callout
} from "@blueprintjs/core";
import AppToaster from "../components/AppToaster";
import PublicHeader from "../components/PublicHeader";
import { withRouter } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { API_URL } from "../constant";
import PublicFooter from "../components/PublicFooter";

class Public extends React.Component {
  state = {
    //loadingPengumuman: true,
    pengumumanList: [],
    loadingTetapan: true,
    bukaPendaftaran: false,
    tarikhAkhirPendaftaran: null,
    loadingSemak: false
  };

  componentDidMount = async () => {
    try {
      const res = await axios.get(`${API_URL}/tetapan`);
      const {
        pengumuman,
        bukaPendaftaran,
        tarikhAkhirPendaftaran
      } = res.data.result;

      this.setState({
        loadingTetapan: false,
        pengumumanList: pengumuman || [],
        bukaPendaftaran,
        tarikhAkhirPendaftaran: moment(tarikhAkhirPendaftaran).format(
          "DDMMYYYY"
        )
      });
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign"
      });
    }
  };

  semakButiran = async e => {
    e.preventDefault();

    const nokp = e.target.nokp.value;

    try {
      if (!nokp || nokp === "") {
        throw Error("Sila masukkan No.KP / Passport penjaga atau anak yatim.");
      }

      // 630926045410

      const res = await Promise.all([
        axios.get(`${API_URL}/pendaftaran/nokp/penjaga/${nokp}`, {
          withCredentials: true
        }),
        axios.get(`${API_URL}/pendaftaran/nokp/anak/${nokp}`, {
          withCredentials: true
        })
      ]);

      const penjaga = res[0].data.result[0];
      const anak = res[1].data.result[0];

      let pendaftaranId;

      if (penjaga && penjaga._id) {
        pendaftaranId = penjaga._id;
      } else if (anak && anak._id) {
        pendaftaranId = anak._id;
      }

      if (!pendaftaranId) {
        throw Error(`Tiada maklumat.`);
      }

      this.setState({
        loadingSemak: false
      });

      this.props.history.push(`/semakan/${pendaftaranId}`);
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign"
      });

      this.setState({
        loadingSemak: false
      });
    }
  };

  render = () => {
    const {
      loadingTetapan,
      pengumumanList,
      bukaPendaftaran,
      tarikhAkhirPendaftaran,
      loadingSemak
    } = this.state;

    return (
      <React.Fragment>
        <PublicHeader />

        <div className="wrapper fixed">
          <div style={{ float: "right", width: 320 }}>
            <Card style={{ padding: 0 }}>
              <h4
                style={{
                  padding: "10px 20px",
                  margin: 0,
                  borderBottom: "1px solid lightgrey"
                }}
              >
                PENGUMUMAN
              </h4>
              <div>
                {loadingTetapan ? (
                  <div style={{ padding: 20 }}>
                    <Spinner size={15} />
                  </div>
                ) : (
                  <Pengumuman list={pengumumanList} />
                )}
              </div>
            </Card>
          </div>

          <div style={{ width: 600 }}>
            <div className="public panel">
              <div className="icon">
                <img src="/clip_register.svg" alt="" />
              </div>
              <div className="content">
                <h4>PENDAFTARAN ANAK YATIM</h4>
                <p>
                  Permohonan baru untuk mendaftar anak-anak yatim Negeri
                  Selangor. Klik butang di bawah untuk mengisi borang
                  pendaftaran. Setelah mengisi, anda akan diminta untuk memuat
                  turun borang yang telah selesai dan mencetak borang tersebut
                  untuk proses pengesahan dan penyerahan.
                </p>
                {loadingTetapan ? (
                  <p>
                    <code>Loading...</code>
                  </p>
                ) : (
                  <ButtonPendaftaran
                    buka={bukaPendaftaran}
                    tarikhTutup={tarikhAkhirPendaftaran}
                    history={this.props.history}
                  />
                )}
              </div>
            </div>

            <div className="public panel">
              <div className="icon">
                <img src="/clip_search.svg" alt="" />
              </div>
              <div className="content">
                <h4>SEMAK BUTIRAN ANAK YATIM</h4>
                <p>
                  Anda boleh menyemak butiran dan maklumat anak yatim dengan
                  memasukkan No.KP (atau Passport) penjaga atau No KP / Passport
                  anak yatim itu sendiri.
                </p>
                <form onSubmit={this.semakButiran}>
                  <FormGroup
                    label="No Kad Pengenalan / Passport:"
                    labelFor="text-input"
                  >
                    <InputGroup
                      id="text-input"
                      placeholder="Contoh: 690101105894"
                      style={{ width: 400 }}
                      name="nokp"
                      disabled={loadingSemak}
                    />
                  </FormGroup>
                  <Button
                    icon="edit"
                    intent="primary"
                    type="submit"
                    loading={loadingSemak}
                  >
                    Semak Butiran
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>

        <PublicFooter />
      </React.Fragment>
    );
  };
}

function ButtonPendaftaran({ buka, tarikhTutup, history }) {
  const bulan = {
    "01": "Januari",
    "02": "Februari",
    "03": "Mac",
    "04": "April",
    "05": "Mei",
    "06": "Jun",
    "07": "Julai",
    "08": "Ogos",
    "09": "September",
    "10": "Oktober",
    "11": "November",
    "12": "Disember"
  };

  const dm = moment(tarikhTutup, "DDMMYYYY");
  const tarikh = `${dm.format("DD")} ${bulan[dm.format("MM")]} ${dm.format(
    "YYYY"
  )}`;

  if (!buka) {
    return <Callout intent="danger">Sesi pendaftaran telah ditutup.</Callout>;
  }

  return (
    <React.Fragment>
      <Button
        icon="edit"
        intent="primary"
        style={{ marginTop: 20, marginBottom: 10 }}
        onClick={() => {
          history.push("/pendaftaran");
        }}
      >
        Isi Borang Pendaftaran
      </Button>
      <p>Tarikh tutup pendaftaran: {tarikh}</p>
    </React.Fragment>
  );
}

function Pengumuman({ list }) {
  if (list.length <= 0) {
    return (
      <p style={{ textAlign: "center", padding: 20 }}>
        Tiada pengumuman untuk dipaparkan setakat ini.
      </p>
    );
  } else {
    return (
      <ul
        style={{
          listStyle: "none",
          padding: 0,
          maxHeight: 400,
          overflowY: "auto",
          margin: 0
        }}
      >
        {list.map((p, index) => {
          let paddingTop = 20;
          let paddingBottom = 20;
          let borderBottom =
            index === list.length - 1 ? "" : "1px solid lightgrey";

          return (
            <li
              key={index}
              style={{
                paddingTop,
                paddingBottom,
                borderBottom,
                paddingLeft: 15,
                paddingRight: 15
              }}
            >
              <p>
                <b>{moment(p.date).format("DD/MM/YYYY")}</b>
              </p>
              {p.content}
            </li>
          );
        })}
      </ul>
    );
  }
}

export default withRouter(Public);
