import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { FocusStyleManager } from "@blueprintjs/core";
import findIndex from "array.prototype.findindex";
import reportWebVitals from "./reportWebVitals";

import "ie-array-find-polyfill";

import "./index.css";
import "react-table/react-table.css";
import "normalize.css/normalize.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";

FocusStyleManager.onlyShowFocusOnTabs();
findIndex.shim();

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
