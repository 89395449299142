import React, { useState, useEffect } from "react";
import Internal from "../components/Internal";
import { Card, InputGroup, Button } from "@blueprintjs/core";
import InternalPageLoading from "../components/InternalPageLoading";
import axios from "axios";
import { API_URL } from "../constant";
import AppToaster from "../components/AppToaster";

function Profil() {
  const [loadingPage, setLoadingPage] = useState(true);
  const [loadingPageError, setLoadingPageError] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [saving, setSaving] = useState(false);

  async function fetch() {
    try {
      setLoadingPage(true);
      setLoadingPageError(false);

      const res = await axios.get(`${API_URL}/profil`, { withCredentials: true });

      if (!res.data.success) {
        throw Error(res.data.message);
      }

      setLoadingPage(false);
      setUsername(res.data.result.username);
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign"
      });

      setLoadingPageError(true);
    }
  }

  async function simpan() {
    try {
      setSaving(true);

      const res = await axios.post(
        `${API_URL}/profil`,
        {
          password,
          confirmPassword
        },
        { withCredentials: true }
      );

      if (!res.data.success) {
        throw Error(res.data.message);
      }

      setSaving(false);

      AppToaster.show({
        message: "Password berjaya dikemaskini",
        intent: "success",
        icon: "warning-sign"
      });
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign"
      });

      setSaving(false);
    }
  }

  useEffect(() => {
    fetch();
  }, []);

  return (
    <React.Fragment>
      <Internal />
      <div className="wrapper fixed" style={{ marginBottom: 50 }}>
        <h2>Profil</h2>

        {loadingPage ? (
          <InternalPageLoading error={loadingPageError} retryFn={fetch} />
        ) : (
          <Card>
            <table style={{ width: "100%" }} className="form">
              <tbody>
                <tr>
                  <td style={{ width: 180 }}>Username</td>
                  <td>
                    <InputGroup
                      style={{ width: 300 }}
                      readOnly
                      value={username}
                      disabled={saving}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Password</td>
                  <td>
                    <InputGroup
                      style={{ width: 300 }}
                      placeholder="Password"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      type="password"
                      disabled={saving}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Confirm Password</td>
                  <td>
                    <InputGroup
                      style={{ width: 300 }}
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      onChange={e => setConfirmPassword(e.target.value)}
                      type="password"
                      disabled={saving}
                    />
                  </td>
                </tr>
                <tr>
                  <td />
                  <td>
                    <Button
                      intent="primary"
                      style={{ marginTop: 20 }}
                      onClick={simpan}
                      loading={saving}
                    >
                      Simpan
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
          </Card>
        )}
      </div>
    </React.Fragment>
  );
}

export default Profil;
