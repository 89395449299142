import React from "react";
import Internal from "../components/Internal";
import { Card, Icon } from "@blueprintjs/core";

function Panel({ path, title, description, history }) {
  return (
    <Card interactive className="internal-panel" onClick={() => history.push(path)}>
      <div className="internal-panel-arrow">
        <Icon icon="arrow-right" />
      </div>
      <p>
        <b>{title}</b>
      </p>
      {description}
    </Card>
  );
}

const items = [
  {
    path: "/operasi/pendaftaran",
    title: "Pengurusan Pendaftaran dan Permohonan",
    description:
      "Urus dan kemaskini maklumat pendaftaran anak yatim dan segala maklumat yang berkaitan semasa pendaftaran."
  },
  {
    path: "/operasi/masjid",
    title: "Pengurusan Masjid",
    description:
      "Urus maklumat masjid seperti tambah data masjid baru, ubah data sedia ada dan hapus rekod masjid dari database."
  },
  {
    path: "/operasi/sumbangan",
    title: "Senarai Anak Yatim & Status Sumbangan",
    description: "Papar senarai anak yatim dan kemaskini status penyerahan sumbangan anak yatim."
  },
  {
    path: "/operasi/tetapan",
    title: "Tetapan Umum",
    description:
      "Konfigurasi tetapan umum, contoh: tarikh tutup pendaftaran dan pengumuman di muka hadapan."
  },
  {
    path: "/operasi/pengubahan",
    title: "Pengubahan Rekod Pendaftaran",
    description:
      "Segala permohonan untuk mengubah data pendaftaran sebelum ini oleh penjaga atau orang awam."
  },
  {
    path: "/operasi/pengguna",
    title: "Pengguna Sistem",
    description: "Pengurusan username dan segala berkaitan dengan pengguna sistem."
  }
];

class Operasi extends React.Component {
  render = () => {
    return (
      <React.Fragment>
        <Internal />
        <div className="wrapper fixed">
          <h2>Operasi</h2>

          {items.map((item, index) => (
            <Panel
              key={index}
              path={item.path}
              title={`${index + 1}. ${item.title.toUpperCase()}`}
              description={item.description}
              history={this.props.history}
            />
          ))}
        </div>
      </React.Fragment>
    );
  };
}

export default Operasi;
