import React from "react";
import PublicHeader from "../components/PublicHeader";
import { Callout, Button } from "@blueprintjs/core";
import { withRouter } from "react-router-dom";
import { API_URL } from "../constant";

class PendaftaranBerjaya extends React.Component {
  state = {
    reportAction: "view"
  };

  cetakBorang = type => {
    // untuk security
    // kita akan gunakan post, instead of get
    // jadi kita kena simulate satu form, letakkan pada body
    // dan submit
    this.setState(
      {
        reportAction: type
      },
      () => {
        this.refs.hiddenForm.submit();
      }
    );
  };

  render = () => {
    const { reportAction } = this.state;

    return (
      <React.Fragment>
        <PublicHeader />
        <div className="wrapper fixed" style={{ padding: "20px 0" }}>
          <Callout title="Berjaya" intent="success" icon="endorsed">
            <p>Pendaftaran telah berjaya disimpan di dalam sistem.</p>
            <p>
              No. Rujukan :
              <br />
              <b>
                <code style={{ fontSize: 18 }}>
                  {decodeURIComponent(this.props.match.params.nosiri)}
                </code>
              </b>
            </p>
          </Callout>

          <div style={{ padding: "40px 0" }}>
            <p>
              Anda masih perlu mencetak borang pendaftaran ini untuk proses
              pengesahan.
            </p>
            <p>
              Setelah disahkan, kepilkan bersama salinan-salinan yang diperlukan
              untuk dihantar ke Pejabat Yayasan Islam Darul Ehsan. Rujuk arahan
              pada borang untuk maklumat lanjut.
            </p>

            <Button
              intent="primary"
              icon="print"
              style={{ marginTop: 20, marginBottom: 50 }}
              large
              onClick={() => this.cetakBorang("view")}
            >
              Cetak Borang Pendaftaran
            </Button>

            <Button
              intent="primary"
              icon="download"
              style={{ marginTop: 20, marginBottom: 50, marginLeft: 10 }}
              large
              minimal
              onClick={() => this.cetakBorang("download")}
            >
              Muat Turun Borang (Untuk Simpanan)
            </Button>

            <br />
            <Button
              minimal
              small
              icon="arrow-left"
              onClick={() => {
                this.props.history.replace("/");
              }}
            >
              Kembali ke Laman Utama
            </Button>
          </div>

          <form
            style={{ display: "none" }}
            method="POST"
            target="_blank"
            ref="hiddenForm"
            action={`${API_URL}/pendaftaran/borang/${this.props.match.params.id}`}
          >
            <input type="hidden" value={reportAction} name="type" />
          </form>
        </div>
      </React.Fragment>
    );
  };
}

export default withRouter(PendaftaranBerjaya);
