import React, { useState, useEffect } from "react";
import Internal from "../components/Internal";
import OperasiTitle from "../components/OperasiTitle";
import { Card, NumericInput, Button, Tag } from "@blueprintjs/core";
import moment from "moment";
import ReactTable, { ReactTableDefaults } from "react-table";
import axios from "axios";
import { API_URL } from "../constant";
import AppToaster from "../components/AppToaster";
import ModalEditPendaftaran from "../modals/EditPendaftaran";

Object.assign(ReactTableDefaults, {
  NextComponent: (state) => (
    <Button
      fill
      rightIcon="fast-forward"
      disabled={state.disabled}
      onClick={state.onClick}
    >
      Next
    </Button>
  ),
  PreviousComponent: (state) => (
    <Button
      fill
      icon="fast-backward"
      disabled={state.disabled}
      onClick={state.onClick}
    >
      Previous
    </Button>
  ),
});

function OperasiPengubahan() {
  const [tahun, setTahun] = useState(moment().format("YYYY"));
  const [senarai, setSenarai] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingTukarId, setLoadingTukarId] = useState(null);
  const [butiran, setButiran] = useState("");
  const [isOpenModalEdit, setIsOpenModalEdit] = useState(false);
  const [modalEditPendaftaranId, setModalEditPendaftaranId] = useState(null);
  const [currentRowId, setCurrentRowId] = useState(null);

  async function tukarStatus(id, status) {
    try {
      setLoadingTukarId(id);

      const res = await axios.post(
        `${API_URL}/perubahan/status`,
        { status, id },
        { withCredentials: true }
      );

      if (!res.data.success) {
        throw Error(res.data.message);
      }

      setLoadingTukarId(null);

      // update list
      let list = [...senarai];
      let record = list.find((r) => r._id === id);

      if (record) {
        record.status = status;
        setSenarai(list);
      }
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign",
      });

      setLoadingTukarId(null);
    }
  }

  async function fetch(tahun) {
    try {
      setLoading(true);

      const res = await axios.get(`${API_URL}/perubahan/${tahun}`, {
        withCredentials: true,
      });

      if (!res.data.success) {
        throw Error(res.data.message);
      }

      setLoading(false);
      setSenarai(res.data.result);
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign",
      });

      setLoading(false);
    }
  }

  function edit({ butiran, pendaftaranId, rowId }) {
    setModalEditPendaftaranId(pendaftaranId);
    setButiran(butiran);
    setCurrentRowId(rowId);
    setIsOpenModalEdit(true);
  }

  useEffect(() => {
    fetch(tahun);
  }, [tahun]);

  return (
    <React.Fragment>
      <Internal />
      <div className="wrapper" style={{ marginBottom: 50 }}>
        <OperasiTitle title="Pengubahan Rekod Pendaftaran" />

        <Card>
          Tahun :
          <NumericInput
            value={tahun}
            disabled={loading}
            placeholder="Tahun"
            onValueChange={(val) => {
              setTahun(val);
              fetch(val);
            }}
            maxLength={4}
            minLength={4}
            min={2018}
          />
        </Card>

        <Card>
          <ReactTable
            loading={loading}
            className="-highlight -striped"
            noDataText={
              <p style={{ margin: 0, textAlign: "center" }}>
                Tiada data untuk dipaparkan
              </p>
            }
            rowsText="Rekod"
            pageText="Muka"
            ofText="/"
            data={senarai}
            columns={[
              {
                Header: "No",
                accessor: "no",
                width: 50,
                className: "alignCenter pointer",
                Cell: (row) => row.index + 1,
              },
              {
                Header: "Tarikh",
                accessor: "dateCreated",
                width: 100,
                className: "alignCenter pointer",
                Cell: (row) => moment(row.value).format("DD-MM-YYYY"),
              },
              {
                Header: "Nama Penjaga",
                accessor: "namaPenjaga",
                maxWidth: 200,
                className: "pointer",
                Cell: (row) => row.original.pendaftaran[0].namaPenjaga,
              },
              {
                Header: "No KP Penjaga",
                accessor: "nokp",
                className: "alignCenter pointer",
                width: 150,
                Cell: (row) =>
                  row.original.pendaftaran[0].noKadPengenalanPenjaga,
              },
              {
                Header: "Butiran",
                accessor: "butiran",
                className: "pointer",
              },
              {
                Header: "Status",
                accessor: "status",
                className: "alignCenter",
                width: 100,
                Cell: (row) => {
                  if (row.value === "buka") {
                    return <Tag intent="danger">BUKA</Tag>;
                  } else if (row.value === "selesai") {
                    return <Tag intent="success">SELESAI</Tag>;
                  }

                  return "-";
                },
              },
              {
                Header: "Tanda Status",
                accessor: "tanda",
                className: "alignCenter",
                width: 100,
                Cell: (row) => {
                  if (row.original.status === "buka") {
                    return (
                      <Button
                        loading={row.original._id === loadingTukarId}
                        small
                        onClick={(e) => {
                          e.stopPropagation();
                          tukarStatus(row.original._id, "selesai");
                        }}
                      >
                        SELESAI
                      </Button>
                    );
                  }
                  if (row.original.status === "selesai") {
                    return (
                      <Button
                        loading={row.original._id === loadingTukarId}
                        small
                        onClick={(e) => {
                          e.stopPropagation();
                          tukarStatus(row.original._id, "buka");
                        }}
                      >
                        BUKA
                      </Button>
                    );
                  }
                  return "-";
                },
              },
            ]}
            getTdProps={(state, row, column, instance) => {
              return {
                onClick: () => {
                  if (["status", "tanda"].includes(column.id)) {
                    return;
                  }

                  edit({
                    butiran: row.original.butiran,
                    pendaftaranId: row.original.pendaftaran[0]._id,
                    rowId: row.original._id,
                  });
                },
              };
            }}
          />
        </Card>
      </div>

      <ModalEditPendaftaran
        isOpen={isOpenModalEdit}
        rowId={currentRowId} // ini bukan id pendaftaran, tapi id row pengubahan
        closeFn={() => setIsOpenModalEdit(false)}
        pendaftaranId={modalEditPendaftaranId}
        butiran={butiran}
        refresh={(id) => tukarStatus(id, "selesai")}
      />
    </React.Fragment>
  );
}

export default OperasiPengubahan;
