import React, { useEffect, useState } from "react";
import Internal from "../components/Internal";
import { Card, Radio, RadioGroup, Button, Checkbox } from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import moment from "moment";
import axios from "axios";
import { API_URL } from "../constant";
import AppToaster from "../components/AppToaster";
import InternalPageLoading from "../components/InternalPageLoading";

const LABEL_WIDTH = 120;

const content = [
  {
    id: 1,
    label: "Senarai Anak Yatim Seluruh Negeri",
  },
  {
    id: 2,
    label: "Senarai Anak Yatim Mengikut Daerah",
  },
  {
    id: 3,
    label: "Senarai Anak Yatim Mengikut Masjid Kariah",
  },
  {
    id: 4,
    label: "Statistik Bilangan Serahan Kepada Anak Yatim Seluruh Negeri",
  },
  {
    id: 5,
    label: "Statistik Bilangan Serahan Kepada Anak Yatim Mengikut Daerah",
  },
  {
    id: 6,
    label: "Senarai Data Bermasalah",
  },
  /* {
    id: 5,
    label: "Senarai Status Pendaftaran"
  },
  {
    id: 6,
    label: "Senarai Status Pendaftaran Mengikut Daerah"
  } */
];

/**
 * kena declare list of columns ni ikut susunan yang asal.
 * supaya bila user toggle macam mana pun, susunan dah ikut yang ditetapkan.
 */
const listColumns = [
  {
    label: "Daerah",
    key: "masjidDaerah",
    defaultChecked: true,
  },
  {
    label: "Masjid",
    key: "masjidKariah",
    defaultChecked: true,
  },
  {
    label: "Nama Penjaga",
    key: "namaPenjaga",
    defaultChecked: true,
  },
  {
    label: "No KP Penjaga",
    key: "noKadPengenalanPenjaga",
    defaultChecked: true,
  },
  {
    label: "Alamat Rumah",
    key: "alamat",
    defaultChecked: true,
  },
  {
    label: "Telefon",
    key: "noTelBimbitPenjaga",
    defaultChecked: true,
  },
  {
    label: "Nama Anak Yatim",
    key: "anakYatim.nama",
    defaultChecked: true,
  },
  {
    label: "No KP Anak Yatim",
    key: "anakYatim.nokp",
    defaultChecked: true,
  },
  {
    label: "Umur Anak Yatim",
    key: "anakYatim.umur",
    defaultChecked: true,
  },
  {
    label: "Jantina Anak Yatim",
    key: "anakYatim.jantina",
    defaultChecked: true,
  },
  {
    label: "Jenis Bank",
    key: "bankPenjaga",
    defaultChecked: true,
  },
  {
    label: "Nama Akaun Bank",
    key: "namaPemilikAkaunPenjaga",
    defaultChecked: true,
  },
  {
    label: "No Akaun Bank",
    key: "noAkaunBankPenjaga",
    defaultChecked: true,
  },
  {
    label: "Kategori Yatim",
    key: "kategoriYatim",
    defaultChecked: true,
  },
  {
    label: "Nama Bapa",
    key: "kematianNamaBapa",
    defaultChecked: true,
  },
  {
    label: "Tarikh Kematian Bapa",
    key: "kematianTarikhBapa",
    defaultChecked: true,
  },
  {
    label: "Sebab Kematian Bapa",
    key: "sebabKematianBapa",
    defaultChecked: true,
  },
  {
    label: "Nama Ibu",
    key: "kematianNamaIbu",
    defaultChecked: true,
  },
  {
    label: "Tarikh Kematian Ibu",
    key: "kematianTarikhIbu",
    defaultChecked: true,
  },
  {
    label: "Sebab Kematian Ibu",
    key: "sebabKematianIbu",
    defaultChecked: true,
  },
  {
    label: "Sudah Daftar LZS",
    key: "sudahDaftarZakatSelangor",
    defaultChecked: true,
  },
  {
    label: "Selesai Urus Harta",
    key: "sudahSelesaiUrusHartaPusaka",
    defaultChecked: true,
  },
];

const listColumnsAdditional = [
  {
    label: "Emel Penjaga",
    key: "emelPenjaga",
  },
  {
    label: "Pekerjaan Penjaga",
    key: "pekerjaanPenjaga",
  },
  {
    label: "No Tel Pejabat",
    key: "noTelPejabatPenjaga",
  },
  {
    label: "Alamat Tempat Kerja",
    key: "alamatTempatKerjaPenjaga",
  },
  {
    label: "Pendapatan Penjaga",
    key: "kategoriPendapatanPenjaga",
  },
  {
    label: "Nama Waris",
    key: "namaWaris",
  },
  {
    label: "No Tel Bimbit Waris",
    key: "noTelBimbitWaris",
  },
  {
    label: "No Tel Rumah Waris",
    key: "noTelRumahWaris",
  },
  {
    label: "Tarikh Lahir Anak Yatim",
    key: "anakYatim.tarikhLahir",
  },
  {
    label: "Nama Sekolah Anak Yatim",
    key: "anakYatim.namaSekolah",
  },
  {
    label: "Hubungan Dengan Penjaga",
    key: "anakYatim.hubungan",
  },
];

class Laporan extends React.Component {
  state = {
    reportId: 1,
    tahun: moment().format("YYYY"),
    selectedDaerahCode: "01",
    senaraiDaerah: [],
    selectedMasjidId: null,
    senaraiMasjid: [],
    loadingPage: true,
    bawah16: true,
    tarikhPerbandingan: new Date(),
    masalahCode: null,
    csvAlamatType: "",
    includeKematian: false,
    selectedColumns: [],
    statusBorangHantar: "Y",
  };

  paparLaporan = () => {
    try {
      const {
        reportId,
        tahun,
        selectedDaerahCode,
        selectedMasjidId,
        bawah16,
        tarikhPerbandingan,
        masalahCode,
        includeKematian,
        statusBorangHantar,
      } = this.state;

      let url;
      let b16 = bawah16 ? 1 : 0;
      let kematian = includeKematian ? 1 : 0;
      let tarikhBanding = moment(tarikhPerbandingan).format("DDMMYYYY");

      if (reportId === 1 || reportId === 4) {
        url = `${API_URL}/laporan?id=${reportId}&tahun=${tahun}&b16=${b16}&tarikhBanding=${tarikhBanding}&kematian=${kematian}&statushantar=${statusBorangHantar}`;
      } else if (reportId === 2 || reportId === 5) {
        url = `${API_URL}/laporan?id=${reportId}&tahun=${tahun}&daerah=${selectedDaerahCode}&b16=${b16}&tarikhBanding=${tarikhBanding}&kematian=${kematian}&statushantar=${statusBorangHantar}`;
      } else if (reportId === 3) {
        url = `${API_URL}/laporan?id=${reportId}&tahun=${tahun}&daerah=${selectedDaerahCode}&masjid=${selectedMasjidId}&b16=${b16}&tarikhBanding=${tarikhBanding}&kematian=${kematian}&statushantar=${statusBorangHantar}`;
      } else if (reportId === 6) {
        if (!masalahCode) {
          throw Error("Sila pilih jenis masalah.");
        } else {
          url = `${API_URL}/laporan?id=${reportId}&tahun=${tahun}&code=${masalahCode}`;
        }
      }

      window.open(url, "_blank");
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign",
      });
    }
  };

  componentDidMount = () => {
    this.initParam();
  };

  changeReportType = (e) => {
    const { value } = e.target;

    this.setState({
      reportId: Number(value),
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState(
      {
        [name]: value,
      },
      () => {
        if (name === "selectedDaerahCode") {
          this.fetchSenaraiMasjidParam(value);
        }
      }
    );
  };

  fetchSenaraiMasjidParam = async (daerahCode) => {
    try {
      this.setState({
        loadingSenaraiMasjidParam: true,
      });

      const res = await axios.get(`${API_URL}/masjid/daerah/${daerahCode}`);

      if (!res.data.success) {
        throw Error(res.data.message);
      }

      this.setState({
        loadingSenaraiMasjidParam: false,
        senaraiMasjid: res.data.result,
        selectedMasjidId:
          res.data.result.length > 0 ? res.data.result[0]._id : null,
      });
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign",
      });

      this.setState({
        loadingSenaraiMasjidParam: false,
      });
    }
  };

  initParam = async () => {
    this.setState({
      loadingPage: true,
    });

    try {
      const { selectedDaerahCode } = this.state;

      const res = await Promise.all([
        axios.get(`${API_URL}/daerah`),
        axios.get(`${API_URL}/masjid/daerah/${selectedDaerahCode}`),
      ]);

      for (let n = 0; n < res.length; n++) {
        if (!res[n].data.success) {
          throw Error(res[n].data.message);
        }
      }

      this.setState({
        loadingPage: false,
        senaraiDaerah: res[0].data.result,
        senaraiMasjid: res[1].data.result,
        selectedMasjidId: res[1].data.result[0]._id,
      });
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign",
      });
    }
  };

  orderColumnsThenSubmit(jenisAlamat) {
    let columns = [...listColumns, ...listColumnsAdditional];
    let res = [];

    for (let c of columns) {
      if (this.state.selectedColumns.includes(c.key)) {
        res.push(c.key);
      }
    }

    this.setState(
      {
        selectedColumns: res,
        csvAlamatType: jenisAlamat,
      },
      () => {
        this.refs.csvForm.submit();
      }
    );
  }

  render = () => {
    const {
      reportId,
      tahun,
      loadingPapar,
      loadingSenaraiMasjidParam,
      selectedDaerahCode,
      senaraiDaerah,
      senaraiMasjid,
      selectedMasjidId,
      loadingPage,
      bawah16,
      tarikhPerbandingan,
      masalahCode,
      csvAlamatType,
      selectedColumns,
    } = this.state;

    return (
      <React.Fragment>
        <Internal />
        <div className="wrapper fixed">
          <h2>Laporan</h2>

          {loadingPage ? (
            <InternalPageLoading />
          ) : (
            <React.Fragment>
              <Card className="internal-panel">
                <p>
                  <b>Pilih Jenis Laporan :</b>
                </p>

                <RadioGroup
                  selectedValue={reportId}
                  onChange={this.changeReportType}
                >
                  {content.map((c, index) => (
                    <Radio key={c.id} value={c.id}>
                      {c.label}
                    </Radio>
                  ))}
                </RadioGroup>
              </Card>

              <Card className="internal-panel">
                <p>
                  <b>Parameter :</b>
                </p>

                <table>
                  <tbody>
                    {[1, 2, 3].includes(reportId) && (
                      <tr>
                        <td style={{ width: LABEL_WIDTH }}>Status Borang</td>
                        <td>
                          <RadioGroup
                            inline
                            selectedValue={this.state.statusBorangHantar}
                            onChange={(e) =>
                              this.setState({
                                statusBorangHantar: e.target.value,
                              })
                            }
                          >
                            <Radio
                              value="Y"
                              checked={this.state.statusBorangHantar === "Y"}
                            >
                              Telah Dihantar
                            </Radio>
                            <Radio
                              value="N"
                              checked={this.state.statusBorangHantar === "N"}
                            >
                              Belum Dihantar
                            </Radio>
                          </RadioGroup>
                        </td>
                      </tr>
                    )}

                    {reportId !== 6 ? (
                      <tr>
                        <td style={{ width: LABEL_WIDTH }}>Tahun</td>
                        <td>
                          <input
                            type="text"
                            className="bp3-input"
                            placeholder="Tahun"
                            name="tahun"
                            value={tahun}
                            onChange={this.handleChange}
                            maxLength={4}
                            disabled={loadingPapar || loadingSenaraiMasjidParam}
                            style={{ width: 500 }}
                          />
                        </td>
                      </tr>
                    ) : null}

                    {reportId === 2 || reportId === 3 || reportId === 5 ? (
                      <tr>
                        <td style={{ width: LABEL_WIDTH }}>Daerah</td>
                        <td>
                          <div className="bp3-select">
                            <select
                              style={{ width: 500 }}
                              onChange={this.handleChange}
                              value={selectedDaerahCode}
                              name="selectedDaerahCode"
                              disabled={
                                loadingPapar || loadingSenaraiMasjidParam
                              }
                            >
                              {senaraiDaerah.map((daerah) => (
                                <option key={daerah.code} value={daerah.code}>
                                  {daerah.description}
                                </option>
                              ))}
                            </select>
                          </div>
                        </td>
                      </tr>
                    ) : null}

                    {reportId === 3 ? (
                      <tr>
                        <td style={{ width: LABEL_WIDTH }}>Masjid</td>
                        <td>
                          <div className="bp3-select">
                            <select
                              style={{ minWidth: 500 }}
                              onChange={this.handleChange}
                              value={selectedMasjidId}
                              name="selectedMasjidId"
                              disabled={
                                loadingPapar || loadingSenaraiMasjidParam
                              }
                            >
                              {senaraiMasjid.map((masjid) => (
                                <option key={masjid._id} value={masjid._id}>
                                  {masjid.bandar && masjid.bandar !== ""
                                    ? `${masjid.nama} - (${masjid.bandar})`
                                    : masjid.nama}
                                </option>
                              ))}
                            </select>
                          </div>
                        </td>
                      </tr>
                    ) : null}

                    {reportId === 1 || reportId === 2 || reportId === 3 ? (
                      <>
                        <tr>
                          <td
                            style={{
                              width: LABEL_WIDTH,
                              verticalAlign: "top",
                            }}
                          >
                            Had Umur
                          </td>
                          <td style={{ paddingTop: 5 }}>
                            <Checkbox
                              checked={bawah16}
                              onChange={(e) =>
                                this.setState({
                                  bawah16: e.target.checked,
                                })
                              }
                              label="16 Tahun Dan Ke Bawah, berdasarkan tarikh :"
                            />
                            <DateInput
                              inputProps={{
                                style: {
                                  marginBottom: 10,
                                },
                              }}
                              locale="ms"
                              formatDate={(date) =>
                                moment(date).format("DD-MM-YYYY")
                              }
                              parseDate={(str) =>
                                moment(str, "DD-MM-YYYY").toDate()
                              }
                              placeholder="Tarikh"
                              //disabled={!bukaPendaftaran}
                              //minDate={new Date()}
                              //maxDate={MAXDATE}
                              value={tarikhPerbandingan}
                              onChange={(date) =>
                                this.setState({
                                  tarikhPerbandingan: date,
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              width: LABEL_WIDTH,
                              verticalAlign: "top",
                            }}
                          >
                            Kematian
                          </td>
                          <td>
                            <Checkbox
                              checked={this.state.includeKematian}
                              onChange={(e) =>
                                this.setState({
                                  includeKematian: e.target.checked,
                                })
                              }
                            >
                              Papar butiran kematian pada laporan
                            </Checkbox>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              width: LABEL_WIDTH,
                              verticalAlign: "top",
                            }}
                          >
                            Butiran
                          </td>
                          <td>
                            <GridPilihanColumn
                              onChange={(keys) =>
                                this.setState({
                                  selectedColumns: keys,
                                })
                              }
                            />
                          </td>
                        </tr>
                      </>
                    ) : null}

                    {reportId === 6 ? (
                      <tr>
                        <td
                          style={{
                            width: LABEL_WIDTH,
                            verticalAlign: "top",
                          }}
                        >
                          Jenis Masalah
                        </td>
                        <td style={{ paddingTop: 5 }}>
                          <RadioGroup
                            selectedValue={masalahCode}
                            onChange={(e) => {
                              this.setState({
                                masalahCode: e.target.value,
                              });
                            }}
                          >
                            <Radio value="ic_penjaga_berulang">
                              IC Penjaga Berulang
                            </Radio>
                            <Radio value="ic_anak_yatim_berulang">
                              IC Anak Yatim Berulang
                            </Radio>
                            {/* <Radio value="penjaga_tiada_ic">
                                                            Penjaga tiada IC
                                                        </Radio>
                                                        <Radio value="anak_yatim_tiada_ic">
                                                            Anak Yatim tiada IC
                                                        </Radio> */}
                          </RadioGroup>
                        </td>
                      </tr>
                    ) : null}

                    <tr>
                      <td />
                      <td>
                        <div style={{ marginTop: 15 }}>
                          <Button intent="primary" onClick={this.paparLaporan}>
                            Papar Laporan
                          </Button>

                          {[1, 2, 3].includes(reportId) && (
                            <>
                              <Button
                                style={{ marginLeft: 10 }}
                                icon="download"
                                onClick={() =>
                                  this.orderColumnsThenSubmit("gabung")
                                }
                              >
                                Download CSV{" "}
                                <span style={{ fontSize: "0.8em" }}>
                                  (Alamat Gabung)
                                </span>
                              </Button>

                              <Button
                                style={{ marginLeft: 10 }}
                                icon="download"
                                onClick={() =>
                                  this.orderColumnsThenSubmit("asing")
                                }
                              >
                                Download CSV{" "}
                                <span style={{ fontSize: "0.8em" }}>
                                  (Alamat Asing)
                                </span>
                              </Button>
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card>
            </React.Fragment>
          )}
        </div>

        <form
          style={{ display: "none" }}
          method="post"
          target="_blank"
          action={`${API_URL}/csv`}
          ref="csvForm"
        >
          <input readOnly name="id" value={reportId} />
          <input readOnly name="tahun" value={tahun} />
          <input readOnly name="daerah" value={selectedDaerahCode} />
          <input readOnly name="masjid" value={selectedMasjidId || ""} />
          <input readOnly name="b16" value={bawah16 ? 1 : 0} />
          <input readOnly name="alamatType" value={csvAlamatType} />
          <input
            type="hidden"
            name="columns"
            value={selectedColumns.join(",")}
          />
          <input
            type="hidden"
            name="statushantar"
            value={this.state.statusBorangHantar}
          />
          <input
            readOnly
            name="tarikhBanding"
            value={moment(tarikhPerbandingan).format("DDMMYYYY")}
          />
        </form>
      </React.Fragment>
    );
  };
}

export default Laporan;

function resetOptions() {
  return listColumns.map((c) => c.key);
}

function GridPilihanColumn({ onChange }) {
  const [selectedKeys, setSelectedKeys] = useState(resetOptions());

  function toggleSelect(isChecked, key) {
    if (isChecked) {
      setSelectedKeys([...selectedKeys, key]);
    } else {
      setSelectedKeys(selectedKeys.filter((k) => k !== key));
    }
  }

  function selectAll() {
    let a = listColumns.map((c) => c.key);
    let b = listColumnsAdditional.map((c) => c.key);
    setSelectedKeys([...a, ...b]);
  }

  function deselectAll() {
    setSelectedKeys([]);
  }

  function reset() {
    return setSelectedKeys(resetOptions());
  }

  useEffect(() => {
    onChange(selectedKeys);
  }, [selectedKeys]);

  return (
    <>
      <fieldset className="options">
        <legend>
          Sila pilih butiran data yang diperlukan (terpakai untuk CSV sahaja) :
        </legend>
        <div className="column-options">
          <div>
            {listColumns.map((field) => (
              <Checkbox
                className="option"
                key={field.key}
                checked={selectedKeys.includes(field.key)}
                onChange={(e) => toggleSelect(e.target.checked, field.key)}
                label={field.label}
              />
            ))}
          </div>
          <div>
            {listColumnsAdditional.map((field) => (
              <Checkbox
                className="option"
                key={field.key}
                checked={selectedKeys.includes(field.key)}
                onChange={(e) => toggleSelect(e.target.checked, field.key)}
                label={field.label}
              />
            ))}
          </div>
        </div>
        <Button small onClick={selectAll}>
          Pilih Semua
        </Button>
        <Button small onClick={deselectAll}>
          Padam Semua
        </Button>
        <Button small onClick={reset}>
          Reset
        </Button>
      </fieldset>
    </>
  );
}
