import React from "react";
import Internal from "../components/Internal";
import InternalPageLoading from "../components/InternalPageLoading";
import OperasiTitle from "../components/OperasiTitle";
import {
  Card,
  Button,
  Switch,
  HTMLSelect,
  Popover,
  Tag,
  NumericInput,
} from "@blueprintjs/core";
import axios from "axios";
import { API_URL } from "../constant";
import AppToaster from "../components/AppToaster";
import moment from "moment";
import replaceall from "replaceall";
import ModalButiranPendaftaran from "../modals/ButiranPendaftaran";
import ModalEditPendaftaran from "../modals/EditPendaftaran";
import ReactTable, { ReactTableDefaults } from "react-table";
import DeletePrompt from "../components/DeletePrompt";

Object.assign(ReactTableDefaults, {
  NextComponent: (state) => (
    <Button
      fill
      rightIcon="fast-forward"
      disabled={state.disabled}
      onClick={state.onClick}
    >
      Next
    </Button>
  ),
  PreviousComponent: (state) => (
    <Button
      fill
      icon="fast-backward"
      disabled={state.disabled}
      onClick={state.onClick}
    >
      Previous
    </Button>
  ),
});

class OperasiPendaftaran extends React.Component {
  state = {
    loadingPage: true,
    senaraiPendaftaran: [],
    jenis: "0",
    tahun: moment().format("YYYY"),
    senaraiDaerah: [],
    selectedDaerahCode: "01",
    loadingPapar: false,
    selectedMasjidId: "",
    senaraiMasjid: [],
    senaraiTitle: "",
    noKpPenjaga: "",
    noKpAnakYatim: "",
    noSiriBorang: "",
    openButiranPendaftaran: false,
    butiranPendaftaranId: null,
    openEditPendaftaran: false,
    editPendaftaranId: null,
    showResult: false,
    loadingChangeStatusId: null,
    loadingSenaraiMasjidParam: false,
    loadingHapusId: null,
    //tableCurrentPage: 0 // controlled react-table page (untuk kegunaan reset bila data bertukar)
  };

  componentDidMount = () => {
    this.initParam();
    //this.paparSenarai();
  };

  initParam = async () => {
    this.setState({
      loadingPage: true,
    });

    try {
      const { selectedDaerahCode } = this.state;

      const res = await Promise.all([
        axios.get(`${API_URL}/daerah`),
        axios.get(`${API_URL}/masjid/daerah/${selectedDaerahCode}`),
      ]);

      for (let n = 0; n < res.length; n++) {
        if (!res[n].data.success) {
          throw Error(res[n].data.message);
        }
      }

      this.setState({
        loadingPage: false,
        senaraiDaerah: res[0].data.result,
        senaraiMasjid: res[1].data.result,
        selectedMasjidId: res[1].data.result[0]._id,
      });
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign",
      });
    }
  };

  fetch = async () => {
    this.setState({
      loadingPage: true,
    });

    try {
      const res = await axios.get(`${API_URL}/pendaftaran/status/belum`);

      if (!res.data.success) {
        throw Error(res.data.message);
      }

      this.setState({
        loadingPage: false,
        senaraiPendaftaran: res.data.result,
      });
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign",
      });
    }
  };

  changeStatus = async (id, value) => {
    let { senaraiPendaftaran } = this.state;
    const index = senaraiPendaftaran.findIndex((r) => r._id === id);

    this.setState({
      loadingChangeStatusId: id,
    });

    try {
      const res = await axios.post(
        //`${API_URL}/pendaftaran/status/ubah/${senaraiPendaftaran[index]._id}`,
        `${API_URL}/pendaftaran/status/ubah/${id}`,
        { sudahDihantar: value },
        { withCredentials: true }
      );

      if (!res.data.success) {
        throw Error(res.data.message);
      }

      senaraiPendaftaran[index].sudahDihantar = value;

      this.setState({
        loadingChangeStatusId: null,
        senaraiPendaftaran,
      });
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign",
      });

      this.setState({
        loadingChangeStatusId: null,
      });
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState(
      {
        [name]: value,
      },
      () => {
        if (name === "jenis") {
          this.setState({
            showResult: false,
          });
        } else if (name === "selectedDaerahCode") {
          this.fetchSenaraiMasjidParam(value);
        }
      }
    );
  };

  fetchSenaraiMasjidParam = async (daerahCode) => {
    try {
      this.setState({
        loadingSenaraiMasjidParam: true,
      });

      const res = await axios.get(`${API_URL}/masjid/daerah/${daerahCode}`);

      if (!res.data.success) {
        throw Error(res.data.message);
      }

      this.setState({
        loadingSenaraiMasjidParam: false,
        senaraiMasjid: res.data.result,
        selectedMasjidId:
          res.data.result.length > 0 ? res.data.result[0]._id : null,
      });
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign",
      });

      this.setState({
        loadingSenaraiMasjidParam: false,
      });
    }
  };

  paparSenarai = async () => {
    const {
      jenis,
      tahun,
      selectedDaerahCode,
      senaraiDaerah,
      selectedMasjidId,
      noKpPenjaga,
      noKpAnakYatim,
      noSiriBorang,
    } = this.state;

    try {
      this.setState({
        loadingPapar: true,
      });

      let res;
      let senaraiTitle = "";
      let daerah;
      let modNoSiriBorang;

      switch (jenis) {
        case "0":
          res = await axios.get(
            `${API_URL}/pendaftaran/${tahun}/status/semua`,
            {
              withCredentials: true,
            }
          );
          senaraiTitle = `Senarai Pendaftaran - Keseluruhan  Tahun ${tahun}`;
          break;

        case "1":
          res = await axios.get(
            `${API_URL}/pendaftaran/${tahun}/status/belum`,
            {
              withCredentials: true,
            }
          );
          senaraiTitle = `Senarai Pendaftaran - Borang Belum Dihantar Tahun ${tahun}`;
          break;

        case "2":
          res = await axios.get(
            `${API_URL}/pendaftaran/${tahun}/status/sudah`,
            {
              withCredentials: true,
            }
          );
          senaraiTitle = `Senarai Pendaftaran - Borang Sudah Dihantar Tahun ${tahun}`;
          break;

        case "3":
          res = await axios.get(
            `${API_URL}/pendaftaran/${tahun}/daerah/${selectedDaerahCode}`,
            {
              withCredentials: true,
            }
          );
          daerah = senaraiDaerah.find((d) => d.code === selectedDaerahCode);
          senaraiTitle = `Senarai Pendaftaran - Daerah ${daerah.description} Tahun ${tahun}`;
          break;

        case "4":
          res = await axios.get(
            `${API_URL}/pendaftaran/${tahun}/masjid/${selectedMasjidId}`,
            {
              withCredentials: true,
            }
          );
          daerah = senaraiDaerah.find((d) => d.code === selectedDaerahCode);
          senaraiTitle = `Senarai Pendaftaran - Daerah ${daerah.description} Tahun ${tahun}`;
          break;

        case "5":
          if (!noKpPenjaga || noKpPenjaga === "") {
            throw Error("Sila masukkan No. Kad Pengenalan/Passport Penjaga");
          }

          res = await axios.get(
            `${API_URL}/pendaftaran/nokp/penjaga/${noKpPenjaga}`,
            {
              withCredentials: true,
            }
          );
          senaraiTitle = `Senarai Pendaftaran - No.KP Penjaga: "${noKpPenjaga}"`;
          break;

        case "6":
          if (!noKpAnakYatim || noKpAnakYatim === "") {
            throw Error("Sila masukkan No. Kad Pengenalan/Passport Anak Yatim");
          }
          res = await axios.get(
            `${API_URL}/pendaftaran/nokp/anak/${noKpAnakYatim}`,
            {
              withCredentials: true,
            }
          );
          senaraiTitle = `Senarai Pendaftaran - No.KP Anak Yatim: "${noKpAnakYatim}"`;
          break;

        case "7":
          if (!noSiriBorang || noSiriBorang === "") {
            throw Error("Sila masukkan No. Siri Borang Pendaftaran");
          }

          modNoSiriBorang = replaceall("/", "-", noSiriBorang).trim();

          res = await axios.get(
            `${API_URL}/pendaftaran/nosiri/${modNoSiriBorang}`,
            {
              withCredentials: true,
            }
          );
          senaraiTitle = `Senarai Pendaftaran - No.Siri: "${noSiriBorang}"`;
          break;

        default:
          break;
      }

      if (!res.data.success) {
        throw Error(res.data.message);
      }

      this.setState({
        loadingPapar: false,
        senaraiPendaftaran: res.data.result,
        senaraiTitle,
        showResult: true,
      });
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign",
      });

      this.setState({
        loadingPapar: false,
      });
    }
  };

  showModalDetailPendaftaran = (pendaftaranId) => {
    this.setState({
      openButiranPendaftaran: true,
      butiranPendaftaranId: pendaftaranId,
    });
  };

  closeModalDetailPendaftaran = () => {
    this.setState({
      openButiranPendaftaran: false,
      butiranPendaftaranId: null,
    });
  };

  showModalEditPendaftaran = (pendaftaranId) => {
    this.setState({
      openEditPendaftaran: true,
      editPendaftaranId: pendaftaranId,
    });
  };

  closeModalEditPendaftaran = (refetch = false) => {
    this.setState({
      openEditPendaftaran: false,
      editPendaftaranId: null,
    });

    if (refetch) {
      this.paparSenarai();
    }
  };

  stopPropagation = (e) => {
    e.stopPropagation();
  };

  hapus = async (row) => {
    const { index, original } = row;

    this.setState({ loadingHapusId: original._id });

    try {
      const res = await axios.post(
        `${API_URL}/pendaftaran/hapus`,
        {
          pendaftaranId: original._id,
        },
        {
          withCredentials: true,
        }
      );

      if (!res.data.success) {
        throw Error(res.data.message);
      }

      this.setState((prevState) => {
        let { senaraiPendaftaran } = prevState;
        senaraiPendaftaran.splice(index, 1);

        return {
          senaraiPendaftaran,
          loadingHapusId: null,
        };
      });
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign",
      });

      this.setState({ loadingHapusId: null });
    }
  };

  inlineEdit = (id) => {
    this.showModalEditPendaftaran(id);
    this.closeModalDetailPendaftaran();
  };

  render = () => {
    const {
      loadingHapusId,
      loadingPage,
      senaraiPendaftaran,
      jenis,
      tahun,
      selectedDaerahCode,
      senaraiDaerah,
      loadingPapar,
      senaraiMasjid,
      selectedMasjidId,
      senaraiTitle,
      noKpPenjaga,
      noKpAnakYatim,
      noSiriBorang,
      openButiranPendaftaran,
      butiranPendaftaranId,
      editPendaftaranId,
      openEditPendaftaran,
      showResult,
      loadingChangeStatusId,
      loadingSenaraiMasjidParam,
      //tableCurrentPage
    } = this.state;

    return (
      <React.Fragment>
        <Internal />

        <ModalButiranPendaftaran
          isOpen={openButiranPendaftaran}
          closeFn={this.closeModalDetailPendaftaran}
          pendaftaranId={butiranPendaftaranId}
          edit={this.inlineEdit}
        />

        <ModalEditPendaftaran
          isOpen={openEditPendaftaran}
          closeFn={this.closeModalEditPendaftaran}
          pendaftaranId={editPendaftaranId}
        />

        <div className="wrapper" style={{ marginBottom: 50 }}>
          <OperasiTitle title="Pengurusan Pendaftaran dan Permohonan" />
          {loadingPage ? (
            <InternalPageLoading />
          ) : (
            <React.Fragment>
              <Card style={{ marginBottom: 15 }}>
                <p>
                  <b>Papar senarai pendaftaran :</b>
                </p>

                <table>
                  <tbody>
                    <tr>
                      <td style={{ width: 130 }}>Berdasarkan </td>
                      <td>
                        <HTMLSelect
                          disabled={loadingPapar || loadingSenaraiMasjidParam}
                          value={jenis}
                          options={[
                            { value: "0", label: "Semua" },
                            { value: "1", label: "Borang Belum Dihantar" },
                            { value: "2", label: "Borang Sudah Dihantar" },
                            { value: "3", label: "Daerah" },
                            { value: "4", label: "Daerah dan Masjid" },
                            {
                              value: "5",
                              label: "Nombor Kad Pengenalan/Passport Penjaga",
                            },
                            {
                              value: "6",
                              label:
                                "Nombor Kad Pengenalan/Passport Anak Yatim",
                            },
                            { value: "7", label: "Nombor Siri Borang" },
                          ]}
                          style={{ width: 500 }}
                          name="jenis"
                          onChange={this.handleChange}
                        />
                      </td>
                    </tr>

                    {jenis === "3" || jenis === "4" ? (
                      <tr>
                        <td>Daerah</td>
                        <td>
                          <div className="bp3-select">
                            <select
                              style={{ width: 500 }}
                              onChange={this.handleChange}
                              value={selectedDaerahCode}
                              name="selectedDaerahCode"
                              disabled={
                                loadingPapar || loadingSenaraiMasjidParam
                              }
                            >
                              {senaraiDaerah.map((daerah) => (
                                <option key={daerah.code} value={daerah.code}>
                                  {daerah.description}
                                </option>
                              ))}
                            </select>
                          </div>
                        </td>
                      </tr>
                    ) : null}

                    {jenis === "4" ? (
                      <tr>
                        <td>Masjid</td>
                        <td>
                          <div className="bp3-select">
                            <select
                              style={{ minWidth: 500 }}
                              onChange={this.handleChange}
                              value={selectedMasjidId}
                              name="selectedMasjidId"
                              disabled={
                                loadingPapar || loadingSenaraiMasjidParam
                              }
                            >
                              {senaraiMasjid.map((masjid) => (
                                <option key={masjid._id} value={masjid._id}>
                                  {masjid.bandar && masjid.bandar !== ""
                                    ? `${masjid.nama} - (${masjid.bandar})`
                                    : masjid.nama}
                                </option>
                              ))}
                            </select>
                          </div>
                        </td>
                      </tr>
                    ) : null}

                    {jenis === "0" ||
                    jenis === "1" ||
                    jenis === "2" ||
                    jenis === "3" ||
                    jenis === "4" ? (
                      <tr>
                        <td>Tahun </td>
                        <td>
                          <NumericInput
                            value={tahun}
                            disabled={loadingPapar || loadingSenaraiMasjidParam}
                            placeholder="Tahun"
                            onValueChange={(val) =>
                              this.setState({ tahun: val })
                            }
                            maxLength={4}
                            minLength={4}
                            min={2018}
                          />
                        </td>
                      </tr>
                    ) : null}

                    {jenis === "5" ? (
                      <tr>
                        <td>No.KP Penjaga</td>
                        <td>
                          <input
                            type="text"
                            className="bp3-input"
                            placeholder="No Kad Pengenalan/Passport Penjaga"
                            name="noKpPenjaga"
                            disabled={loadingPapar}
                            value={noKpPenjaga}
                            onChange={this.handleChange}
                            style={{ width: 500 }}
                          />
                        </td>
                      </tr>
                    ) : null}

                    {jenis === "6" ? (
                      <tr>
                        <td>No.KP Anak Yatim</td>
                        <td>
                          <input
                            type="text"
                            className="bp3-input"
                            placeholder="No Kad Pengenalan/Passport Anak Yatim"
                            name="noKpAnakYatim"
                            disabled={loadingPapar}
                            value={noKpAnakYatim}
                            onChange={this.handleChange}
                            style={{ width: 500 }}
                          />
                        </td>
                      </tr>
                    ) : null}

                    {jenis === "7" ? (
                      <tr>
                        <td>No. Siri Borang</td>
                        <td>
                          <input
                            type="text"
                            className="bp3-input"
                            placeholder="No Siri Borang"
                            name="noSiriBorang"
                            disabled={loadingPapar}
                            value={noSiriBorang}
                            onChange={this.handleChange}
                            style={{ width: 500 }}
                          />
                        </td>
                      </tr>
                    ) : null}

                    <tr>
                      <td />
                      <td>
                        <Button
                          intent="primary"
                          style={{ marginTop: 13 }}
                          onClick={this.paparSenarai}
                          loading={loadingPapar}
                          disabled={loadingSenaraiMasjidParam}
                        >
                          Papar Senarai
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card>

              {showResult ? (
                <Card>
                  <h4 style={{ margin: "10px 0" }}>{senaraiTitle}</h4>
                  Panduan:
                  <ul>
                    <li>
                      Klik pada mana-mana rekod untuk melihat butiran penuh.
                    </li>
                    <li>
                      Untuk mengubah mana-mana butiran berkenaan pendaftaran,
                      klik butang "Edit".
                    </li>
                    <li>
                      Status borang belum atau sudah dihantar boleh ditukar di
                      ruang status.
                    </li>
                  </ul>
                  <ReactTable
                    className="-highlight -striped"
                    noDataText={
                      <p style={{ margin: 0, textAlign: "center" }}>
                        Tiada data untuk dipaparkan
                      </p>
                    }
                    rowsText="Rekod"
                    pageText="Muka"
                    ofText="/"
                    data={senaraiPendaftaran}
                    columns={[
                      {
                        Header: "No",
                        accessor: "no",
                        Cell: (row) => row.index + 1,
                        width: 50,
                        className: "alignCenter",
                      },
                      {
                        Header: "Tarikh",
                        accessor: "dateCreated",
                        Cell: (row) => {
                          return moment(row.value).format("DD-MM-YYYY");
                        },
                        width: 120,
                        className: "alignCenter pointer",
                      },
                      {
                        Header: "Nama Penjaga",
                        accessor: "namaPenjaga",
                        className: "pointer",
                        filterable: true,
                        filterMethod: (state, record) => {
                          const { value } = state;
                          const { namaPenjaga } = record;
                          const regex = new RegExp(value, "gi");
                          return regex.test(namaPenjaga);
                        },
                      },
                      {
                        Header: "NoKP Penjaga",
                        accessor: "noKadPengenalanPenjaga",
                        className: "pointer",
                        filterable: true,
                        filterMethod: (state, record) => {
                          const { value } = state;
                          const { noKadPengenalanPenjaga } = record;
                          const regex = new RegExp(value, "gi");
                          return regex.test(noKadPengenalanPenjaga);
                        },
                      },
                      {
                        Header: "Anak Yatim",
                        accessor: "bilAnakYatim",
                        className: "alignCenter pointer",
                        width: 100,
                      },
                      {
                        Header: "Daerah",
                        accessor: "daerah",
                        className: "pointer",
                        Cell: (row) => row.original.daerah[0].description,
                      },
                      {
                        Header: "Status",
                        accessor: "sudahDihantar",
                        className: "alignCenter",
                        Cell: (row) => (
                          <Switch
                            label={
                              row.value ? "Sudah Dihantar" : "Belum Dihantar"
                            }
                            //onChange={e => this.changeStatus(index, e.target.checked)}
                            onChange={(e) =>
                              this.changeStatus(
                                row.original._id,
                                e.target.checked
                              )
                            }
                            name="status"
                            checked={row.value || false}
                            disabled={
                              loadingChangeStatusId === row.original._id
                            }
                          />
                        ),
                      },
                      {
                        Header: "Edit",
                        accessor: "edit",
                        className: "alignCenter",
                        width: 100,
                        Cell: (row) => (
                          <Button
                            small
                            icon="edit"
                            onClick={(e) => {
                              e.stopPropagation();
                              this.showModalEditPendaftaran(row.original._id);
                            }}
                            intent="primary"
                            minimal
                          >
                            Edit
                          </Button>
                        ),
                      },
                      {
                        Header: "Hapus",
                        accessor: "hapus",
                        className: "alignCenter",
                        width: 100,
                        Cell: (row) => (
                          <Popover
                            minimal
                            content={
                              <DeletePrompt
                                title="Hapus Rekod"
                                message={
                                  <React.Fragment>
                                    <span
                                      style={{
                                        marginBottom: 10,
                                        display: "block",
                                      }}
                                    >
                                      Penjaga:{" "}
                                      <Tag>{row.original.namaPenjaga}</Tag>
                                    </span>
                                    <span>
                                      Semua maklumat berkenaan (termasuk
                                      anak-anak yatim) <br />
                                      di dalam pendaftaran ini akan turut
                                      dihapus. Teruskan?
                                    </span>
                                  </React.Fragment>
                                }
                                proceed={() => this.hapus(row)}
                              />
                            }
                            target={
                              <Button
                                small
                                icon="cross"
                                intent="danger"
                                minimal
                                loading={loadingHapusId === row.original._id}
                              >
                                Hapus
                              </Button>
                            }
                          />
                        ),
                      },
                    ]}
                    getTdProps={(state, rowInfo, column, instance) => {
                      return {
                        onClick: () => {
                          const { id } = column;
                          if (
                            id !== "edit" &&
                            id !== "sudahDihantar" &&
                            id !== "hapus" &&
                            rowInfo
                          ) {
                            this.showModalDetailPendaftaran(
                              rowInfo.original._id
                            );
                          }
                        },
                      };
                    }}
                  />
                  <p style={{ marginTop: 15, textAlign: "center" }}>
                    Jumlah Paparan Rekod :{" "}
                    <code>{senaraiPendaftaran.length}</code>
                  </p>
                </Card>
              ) : null}
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  };
}

export default OperasiPendaftaran;
