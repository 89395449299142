import React from "react";
import { Button, Dialog, Radio, RadioGroup, TextArea } from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import AppToaster from "../components/AppToaster";
import axios from "axios";
import { API_URL } from "../constant";
import moment from "moment";
import replaceall from "replaceall";

const minDateUmurAnakYatim = moment().subtract(16, "years").toDate();
const YATIM = "YATIM";
const YATIM_PIATU = "YATIM_PIATU";

// nota: jangan ubah label ini.
// sebab dekat backend kita takde unique id atau code untuk mewakili values.
// jadi kita guna exact word untuk.
const sebabKematian = [
  "COVID-19",
  "KEMALANGAN",
  "PENYAKIT",
  "SAKIT TUA",
  "LAIN-LAIN",
];

class Modal extends React.Component {
  state = {
    loadingData: true,
    pendaftaran: {},
    senaraiPendapatan: [],
    senaraiBank: [],
    senaraiDaerah: [],
    senaraiMasjid: [],
    loadingSenaraiMasjid: false,
    loadingSubmit: false,
    //collectionAnakYatimIdToBeRemoved: []
  };

  tambahAnakYatim = () => {
    const clonePendaftaran = { ...this.state.pendaftaran };
    const { anakYatim } = clonePendaftaran;

    anakYatim.push({
      nama: "",
      nokp: "",
      tarikhLahir: "",
      jantina: "",
      hubungan: null,
      namaSekolah: null,
    });

    this.setState({ pendaftaran: clonePendaftaran });
  };

  removeAnakYatim = (anakYatimId) => {
    const { anakYatim } = this.state.pendaftaran;
    const index = anakYatim.findIndex((anak) => anak._id === anakYatimId);

    let newPendaftaranObject = { ...this.state.pendaftaran };
    let newListAnakYatim = [...newPendaftaranObject.anakYatim];
    newListAnakYatim.splice(index, 1);

    newPendaftaranObject.anakYatim = newListAnakYatim;

    this.setState({
      pendaftaran: newPendaftaranObject,
    });
  };

  submit = async (e, selesai = false) => {
    e.preventDefault();

    const { pendaftaran } = this.state;

    this.setState({
      loadingSubmit: true,
    });

    try {
      const res = await axios.post(
        `${API_URL}/pendaftaran/edit/${pendaftaran._id}`,
        { ...pendaftaran },
        { withCredentials: true }
      );

      if (!res.data.success) {
        throw Error(res.data.message);
      }

      AppToaster.show({
        message: "Data telah dikemaskini",
        intent: "success",
        icon: "tick",
      });

      this.setState({
        loadingSubmit: false,
      });

      if (selesai && this.props.refresh) {
        this.props.refresh(this.props.rowId);
      }

      this.props.closeFn(true);
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign",
      });

      this.setState({
        loadingSubmit: false,
      });
    }
  };

  handleChangeJantinaAnakYatim = (e) => {
    const { value } = e.target;
    const index = Number(e.target.getAttribute("data-index"));

    this.setState((prevState) => {
      let { pendaftaran } = prevState;
      let { anakYatim } = pendaftaran;

      anakYatim[index].jantina = value;

      return { pendaftaran };
    });
  };

  handleChangeTarikhLahirAnakYatim = (index, key, date) => {
    this.setState((prevState) => {
      let { pendaftaran } = prevState;
      let { anakYatim } = pendaftaran;

      anakYatim[index][key] = date;

      return { pendaftaran };
    });
  };

  fetch = async () => {
    const { pendaftaranId } = this.props;

    try {
      const res = await Promise.all([
        axios.get(`${API_URL}/pendaftaran/${pendaftaranId}`, {
          withCredentials: true,
        }),
        axios.get(`${API_URL}/bank`),
        axios.get(`${API_URL}/daerah`),
        axios.get(`${API_URL}/pendapatan`),
      ]);

      for (let n = 0; n < res.length; n++) {
        if (!res[n].data.success) {
          throw Error(res.data.message);
        }
      }

      const resMasjid = await axios.get(
        `${API_URL}/masjid/daerah/${res[0].data.result.masjidDaerah}`
      );

      if (!resMasjid.data.success) {
        throw Error(resMasjid.data.message);
      }

      // handle default kategori yatim
      let p = res[0].data.result;
      p.kategoriYatim = p.kategoriYatim || YATIM;
      p.sebabKematianBapa = p.sebabKematianBapa || "LAIN-LAIN";
      p.sebabKematianIbu = p.sebabKematianIbu || "LAIN-LAIN";

      this.setState({
        loadingData: false,
        pendaftaran: p,
        senaraiBank: res[1].data.result,
        senaraiDaerah: res[2].data.result,
        senaraiPendapatan: res[3].data.result,
        senaraiMasjid: resMasjid.data.result,
      });
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign",
      });
    }
  };

  handleChangeForm = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => {
      let { pendaftaran } = prevState;
      pendaftaran[name] = value;
      return { pendaftaran };
    });
  };

  handleChangeDaerah = async (e) => {
    const { value } = e.target;

    try {
      this.setState({
        loadingSenaraiMasjid: true,
      });

      const res = await axios.get(`${API_URL}/masjid/daerah/${value}`);

      if (!res.data.success) {
        throw Error(res.data.message);
      }

      this.setState((prevState) => {
        let { pendaftaran } = prevState;

        pendaftaran.masjidDaerah = value;
        pendaftaran.masjidKariah = res.data.result[0]._id;

        return {
          loadingSenaraiMasjid: false,
          pendaftaran,
          senaraiMasjid: res.data.result,
        };
      });
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning-sign",
      });

      this.setState({
        loadingSenaraiMasjid: false,
      });
    }
  };

  handleChangeAnakYatim = (e) => {
    const { name, value } = e.target;
    const index = Number(e.target.getAttribute("data-index"));

    this.setState((prevState) => {
      let { pendaftaran } = prevState;
      pendaftaran.anakYatim[index][name] = value;
      return { pendaftaran };
    });
  };

  handleBlurNoKP = (index, value) => {
    // remove dash
    value = replaceall("-", "", value);
    value = replaceall(" ", "", value);
    value = replaceall("_", "", value);

    // trim
    value = value.trim();

    this.setState((prevState) => {
      let { pendaftaran } = prevState;
      let { anakYatim } = pendaftaran;
      anakYatim[index].nokp = value;

      // generate tarikh lahir
      let pangkal = value.substr(0, 6);

      if (pangkal.length === 6) {
        let tarikhLahir = moment(pangkal, "YYMMDD");

        if (tarikhLahir.isValid()) {
          if (
            tarikhLahir.endOf("day") >=
            moment(minDateUmurAnakYatim).startOf("day")
          ) {
            if (tarikhLahir.endOf("day") <= moment().endOf("day")) {
              anakYatim[index].tarikhLahir = tarikhLahir.toDate();
            }
          }
        }
      }

      return { pendaftaran };
    });
  };

  render = () => {
    const { isOpen, closeFn, butiran } = this.props;
    const {
      loadingData,
      pendaftaran,
      senaraiPendapatan,
      senaraiBank,
      senaraiDaerah,
      senaraiMasjid,
      loadingSenaraiMasjid,
      loadingSubmit,
    } = this.state;

    const gridColumns = butiran ? "250px 1fr" : "1fr";
    const columnGap = butiran ? "15px" : 0;

    return (
      <Dialog
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        transitionDuration={0}
        isOpen={isOpen}
        title="Edit Butiran Pendaftaran"
        style={{ width: butiran ? 1000 : 800 }}
        onOpening={this.fetch}
        onClose={closeFn}
      >
        <div style={{ padding: 20 }}>
          {loadingData && <span>Loading...</span>}

          {!loadingData && (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: gridColumns,
                columnGap: columnGap,
              }}
            >
              {butiran && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p className="cellTitle">Butiran Yang Ingin Diubah</p>
                  <TextArea value={butiran} readOnly style={{ flexGrow: 1 }} />
                </div>
              )}

              <form onSubmit={this.submit}>
                <table className="aligntop" style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td colSpan={3}>
                        <p className="cellTitle">Butiran Penjaga</p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: 150 }}>
                        <b>Nama</b>
                      </td>
                      <td>:</td>
                      <td>
                        <input
                          type="text"
                          name="namaPenjaga"
                          value={pendaftaran.namaPenjaga || ""}
                          placeholder="Nama Penjaga"
                          className="bp3-input bp3-fill"
                          onChange={this.handleChangeForm}
                          disabled={loadingSubmit}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>No.KP/Passport</b>
                      </td>
                      <td>:</td>
                      <td>
                        <input
                          type="text"
                          name="noKadPengenalanPenjaga"
                          value={pendaftaran.noKadPengenalanPenjaga || ""}
                          placeholder="No Kad Pengenalan/Passport Penjaga"
                          className="bp3-input bp3-fill"
                          onChange={this.handleChangeForm}
                          disabled={loadingSubmit}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>No Tel Bimbit</b>
                      </td>
                      <td>:</td>
                      <td>
                        <input
                          type="text"
                          name="noTelBimbitPenjaga"
                          value={pendaftaran.noTelBimbitPenjaga || ""}
                          placeholder="No Tel Bimbit"
                          className="bp3-input bp3-fill"
                          onChange={this.handleChangeForm}
                          disabled={loadingSubmit}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>No Tel Rumah</b>
                      </td>
                      <td>:</td>
                      <td>
                        <input
                          type="text"
                          name="noTelRumahPenjaga"
                          value={pendaftaran.noTelRumahPenjaga || ""}
                          placeholder="No Tel Rumah"
                          className="bp3-input bp3-fill"
                          onChange={this.handleChangeForm}
                          disabled={loadingSubmit}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Emel</b>
                      </td>
                      <td>:</td>
                      <td>
                        <input
                          type="text"
                          name="emelPenjaga"
                          value={pendaftaran.emelPenjaga || ""}
                          placeholder="Emel"
                          className="bp3-input bp3-fill"
                          onChange={this.handleChangeForm}
                          disabled={loadingSubmit}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Alamat Rumah</b>
                      </td>
                      <td>:</td>
                      <td>
                        <input
                          type="text"
                          name="alamat1Penjaga"
                          value={pendaftaran.alamat1Penjaga || ""}
                          placeholder="Alamat Penjaga"
                          className="bp3-input bp3-fill"
                          onChange={this.handleChangeForm}
                          disabled={loadingSubmit}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td />
                      <td />
                      <td>
                        <input
                          type="text"
                          name="alamat2Penjaga"
                          value={pendaftaran.alamat2Penjaga || ""}
                          placeholder="Alamat Penjaga"
                          className="bp3-input bp3-fill"
                          onChange={this.handleChangeForm}
                          disabled={loadingSubmit}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Poskod</b>
                      </td>
                      <td>:</td>
                      <td>
                        <input
                          type="text"
                          name="alamatPoskodPenjaga"
                          value={pendaftaran.alamatPoskodPenjaga || ""}
                          placeholder="Poskod"
                          className="bp3-input"
                          onChange={this.handleChangeForm}
                          maxLength={5}
                          disabled={loadingSubmit}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Bandar</b>
                      </td>
                      <td>:</td>
                      <td>
                        <input
                          type="text"
                          name="alamatBandarPenjaga"
                          value={pendaftaran.alamatBandarPenjaga || ""}
                          placeholder="Bandar"
                          className="bp3-input bp3-fill"
                          onChange={this.handleChangeForm}
                          disabled={loadingSubmit}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Negeri</b>
                      </td>
                      <td>:</td>
                      <td>
                        <div className="bp3-select">
                          <select
                            style={{ width: 300 }}
                            name="alamatNegeriPenjaga"
                            value={pendaftaran.alamatNegeriPenjaga || ""}
                            onChange={this.handleChangeForm}
                            disabled={loadingSubmit}
                          >
                            <option value="Johor">Johor</option>
                            <option value="Kedah">Kedah</option>
                            <option value="Kelantan">Kelantan</option>
                            <option value="Kuala Lumpur">Kuala Lumpur</option>
                            <option value="Labuan">Labuan</option>
                            <option value="Melaka">Melaka</option>
                            <option value="Negeri Sembilan">
                              Negeri Sembilan
                            </option>
                            <option value="Pahang">Pahang</option>
                            <option value="Penang">Penang</option>
                            <option value="Perak">Perak</option>
                            <option value="Perlis">Perlis</option>
                            <option value="Putrajaya">Putrajaya</option>
                            <option value="Sabah">Sabah</option>
                            <option value="Sarawak">Sarawak</option>
                            <option value="Selangor">Selangor</option>
                            <option value="Terengganu">Terengganu</option>
                          </select>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <p className="cellTitle">Pekerjaan Penjaga</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Pekerjaan</b>
                      </td>
                      <td>:</td>
                      <td>
                        <input
                          type="text"
                          name="pekerjaanPenjaga"
                          value={pendaftaran.pekerjaanPenjaga || ""}
                          placeholder=""
                          className="bp3-input bp3-fill"
                          onChange={this.handleChangeForm}
                          disabled={loadingSubmit}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>No Tel Pejabat</b>
                      </td>
                      <td>:</td>
                      <td>
                        <input
                          type="text"
                          name="noTelPejabatPenjaga"
                          value={pendaftaran.noTelPejabatPenjaga || ""}
                          placeholder=""
                          className="bp3-input bp3-fill"
                          onChange={this.handleChangeForm}
                          disabled={loadingSubmit}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Alamat</b>
                      </td>
                      <td>:</td>
                      <td>
                        <input
                          type="text"
                          name="alamatTempatKerjaPenjaga"
                          value={pendaftaran.alamatTempatKerjaPenjaga || ""}
                          placeholder=""
                          className="bp3-input bp3-fill"
                          onChange={this.handleChangeForm}
                          disabled={loadingSubmit}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Pendapatan</b>
                      </td>
                      <td>:</td>
                      <td>
                        <span style={{ marginRight: 10 }}>RM</span>
                        <div className="bp3-select">
                          <select
                            name="kategoriPendapatanPenjaga"
                            value={pendaftaran.kategoriPendapatanPenjaga || ""}
                            onChange={this.handleChangeForm}
                            disabled={loadingSubmit}
                          >
                            {senaraiPendapatan.map((pendapatan) => (
                              <option
                                key={pendapatan.code}
                                value={pendapatan.code}
                              >
                                {pendapatan.description}
                              </option>
                            ))}
                          </select>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <p className="cellTitle">Maklumat Bank Penjaga</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Bank</b>
                      </td>
                      <td>:</td>
                      <td>
                        <div className="bp3-select">
                          <select
                            name="bankPenjaga"
                            value={pendaftaran.bankPenjaga || ""}
                            onChange={this.handleChangeForm}
                            disabled={loadingSubmit}
                          >
                            <option value="">--Tiada--</option>
                            {senaraiBank.map((bank) => (
                              <option key={bank._id} value={bank._id}>
                                {bank.description}
                              </option>
                            ))}
                          </select>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>No Akaun Bank</b>
                      </td>
                      <td>:</td>
                      <td>
                        <input
                          type="text"
                          name="noAkaunBankPenjaga"
                          value={pendaftaran.noAkaunBankPenjaga || ""}
                          placeholder=""
                          className="bp3-input bp3-fill"
                          onChange={this.handleChangeForm}
                          disabled={loadingSubmit}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Nama Pemilik</b>
                      </td>
                      <td>:</td>
                      <td>
                        <input
                          type="text"
                          name="namaPemilikAkaunPenjaga"
                          value={pendaftaran.namaPemilikAkaunPenjaga || ""}
                          placeholder=""
                          className="bp3-input bp3-fill"
                          onChange={this.handleChangeForm}
                          disabled={loadingSubmit}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <p className="cellTitle">Maklumat Waris</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Nama Waris</b>
                      </td>
                      <td>:</td>
                      <td>
                        <input
                          type="text"
                          name="namaWaris"
                          value={pendaftaran.namaWaris || ""}
                          placeholder=""
                          className="bp3-input bp3-fill"
                          onChange={this.handleChangeForm}
                          disabled={loadingSubmit}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>No Tel Bimbit</b>
                      </td>
                      <td>:</td>
                      <td>
                        <input
                          type="text"
                          name="noTelBimbitWaris"
                          value={pendaftaran.noTelBimbitWaris || ""}
                          placeholder=""
                          className="bp3-input bp3-fill"
                          onChange={this.handleChangeForm}
                          disabled={loadingSubmit}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>No Tel Rumah</b>
                      </td>
                      <td>:</td>
                      <td>
                        <input
                          type="text"
                          name="noTelRumahWaris"
                          value={pendaftaran.noTelRumahWaris || ""}
                          placeholder=""
                          className="bp3-input bp3-fill"
                          onChange={this.handleChangeForm}
                          disabled={loadingSubmit}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <p className="cellTitle">Maklumat Masjid Kariah</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Daerah</b>
                      </td>
                      <td>:</td>
                      <td>
                        <div className="bp3-select">
                          <select
                            style={{ minWidth: 300 }}
                            value={pendaftaran.masjidDaerah || ""}
                            name="masjidDaerah"
                            onChange={this.handleChangeDaerah}
                            disabled={loadingSenaraiMasjid || loadingSubmit}
                          >
                            {senaraiDaerah.map((daerah) => (
                              <option key={daerah.code} value={daerah.code}>
                                {daerah.description}
                              </option>
                            ))}
                          </select>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Masjid</b>
                      </td>
                      <td>:</td>
                      <td>
                        <div className="bp3-select">
                          <select
                            style={{ minWidth: 300 }}
                            value={pendaftaran.masjidKariah || ""}
                            name="masjidKariah"
                            onChange={this.handleChangeForm}
                            disabled={loadingSenaraiMasjid || loadingSubmit}
                          >
                            {senaraiMasjid.map((masjid) => (
                              <option key={masjid._id} value={masjid._id}>
                                {masjid.nama}
                                {Boolean(masjid.bandar)
                                  ? ` (${masjid.bandar})`
                                  : ""}
                              </option>
                            ))}
                          </select>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <p className="cellTitle">Maklumat Kematian</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Kategori</b>
                      </td>
                      <td>:</td>
                      <td>
                        <div className="bp3-select">
                          <select
                            style={{ minWidth: 300 }}
                            name="kategoriYatim"
                            value={pendaftaran.kategoriYatim || YATIM}
                            onChange={this.handleChangeForm}
                            disabled={loadingSubmit}
                          >
                            <option value={YATIM}>Yatim</option>
                            <option value={YATIM_PIATU}>Yatim Piatu</option>
                          </select>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3} style={{ padding: "5px 0" }}></td>
                    </tr>
                    <tr>
                      <td>
                        <b>Nama Bapa</b>
                      </td>
                      <td>:</td>
                      <td>
                        <input
                          type="text"
                          name="kematianNamaBapa"
                          className="bp3-input bp3-fill"
                          placeholder="Nama Penuh Bapa"
                          autoComplete="off"
                          disabled={loadingSubmit}
                          value={pendaftaran.kematianNamaBapa || ""}
                          onChange={this.handleChangeForm}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Tarikh Kematian</b>
                      </td>
                      <td>:</td>
                      <td>
                        <DateInput
                          locale="ms"
                          invalidDateMessage="Tarikh Tidak Sah"
                          formatDate={(date) =>
                            moment(date).format("DD/MM/YYYY")
                          }
                          parseDate={(str) =>
                            moment(str, "DD/MM/YYYY").toDate()
                          }
                          placeholder="Tarikh Kematian Bapa"
                          value={
                            pendaftaran.kematianTarikhBapa
                              ? moment(pendaftaran.kematianTarikhBapa).toDate()
                              : null
                          }
                          onChange={(date) =>
                            this.setState({
                              pendaftaran: {
                                ...this.state.pendaftaran,
                                kematianTarikhBapa: date,
                              },
                            })
                          }
                          disabled={loadingSubmit}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Sebab Kematian</b>
                      </td>
                      <td>:</td>
                      <td>
                        <div className="bp3-select">
                          <select
                            style={{ minWidth: 300 }}
                            name="sebabKematianBapa"
                            disabled={loadingSubmit}
                            value={pendaftaran.sebabKematianBapa}
                            onChange={this.handleChangeForm}
                          >
                            {sebabKematian.map((sebab) => (
                              <option key={sebab} value={sebab}>
                                {sebab}
                              </option>
                            ))}
                          </select>
                        </div>
                      </td>
                    </tr>

                    {pendaftaran.kategoriYatim === YATIM_PIATU && (
                      <>
                        <tr>
                          <td colSpan={3} style={{ padding: "5px 0" }}></td>
                        </tr>
                        <tr>
                          <td>
                            <b>Nama Ibu</b>
                          </td>
                          <td>:</td>
                          <td>
                            <input
                              type="text"
                              name="kematianNamaIbu"
                              className="bp3-input bp3-fill"
                              placeholder="Nama Penuh Ibu"
                              autoComplete="off"
                              disabled={loadingSubmit}
                              value={pendaftaran.kematianNamaIbu || ""}
                              onChange={this.handleChangeForm}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Tarikh Kematian</b>
                          </td>
                          <td>:</td>
                          <td>
                            <DateInput
                              locale="ms"
                              invalidDateMessage="Tarikh Tidak Sah"
                              formatDate={(date) =>
                                moment(date).format("DD/MM/YYYY")
                              }
                              parseDate={(str) =>
                                moment(str, "DD/MM/YYYY").toDate()
                              }
                              placeholder="Tarikh Kematian Ibu"
                              value={
                                pendaftaran.kematianTarikhIbu
                                  ? moment(
                                      pendaftaran.kematianTarikhIbu
                                    ).toDate()
                                  : null
                              }
                              onChange={(date) =>
                                this.setState({
                                  pendaftaran: {
                                    ...this.state.pendaftaran,
                                    kematianTarikhIbu: date,
                                  },
                                })
                              }
                              disabled={loadingSubmit}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Sebab Kematian</b>
                          </td>
                          <td>:</td>
                          <td>
                            <div className="bp3-select">
                              <select
                                style={{ minWidth: 300 }}
                                name="sebabKematianIbu"
                                disabled={loadingSubmit}
                                defaultValue="LAIN-LAIN"
                                value={pendaftaran.sebabKematianIbu}
                                onChange={this.handleChangeForm}
                              >
                                {sebabKematian.map((sebab) => (
                                  <option key={sebab} value={sebab}>
                                    {sebab}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </td>
                        </tr>
                      </>
                    )}

                    <tr>
                      <td colSpan={3}>
                        <p className="cellTitle">
                          Maklumat Anak Yatim{" "}
                          <Button
                            style={{ marginLeft: 15 }}
                            small
                            intent="success"
                            minimal
                            onClick={() => this.tambahAnakYatim()}
                            icon="add"
                          >
                            Tambah Anak Yatim
                          </Button>
                        </p>
                      </td>
                    </tr>
                    {pendaftaran.anakYatim.map((anak, index) => (
                      <React.Fragment key={index}>
                        <tr>
                          <td colSpan={3} style={{ paddingTop: 20 }}>
                            <i>No. {index + 1}</i>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Nama</b>
                          </td>
                          <td>:</td>
                          <td>
                            <input
                              type="text"
                              name="nama"
                              className="bp3-input bp3-fill"
                              placeholder="Nama Penuh Anak Yatim"
                              data-index={index}
                              onChange={this.handleChangeAnakYatim}
                              value={anak.nama || ""}
                              //required
                              autoComplete="off"
                              disabled={loadingSubmit}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>No KP/Passport</b>
                          </td>
                          <td>:</td>
                          <td>
                            <input
                              type="text"
                              name="nokp"
                              className="bp3-input bp3-fill"
                              placeholder="No. Kad Pengenalan / Sijil Kelahiran"
                              value={anak.nokp || ""}
                              onChange={this.handleChangeAnakYatim}
                              data-index={index}
                              onBlur={(e) =>
                                this.handleBlurNoKP(index, e.target.value)
                              }
                              //required
                              autoComplete="off"
                              disabled={loadingSubmit}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Tarikh Lahir</b>
                          </td>
                          <td>:</td>
                          {/* <td>{moment(anak.tarikhLahir, "DDMMYYYY").format("DD-MM-YYYY")}</td> */}
                          <td>
                            <DateInput
                              locale="ms"
                              invalidDateMessage="Tarikh Tidak Sah"
                              formatDate={(date) =>
                                moment(date).format("DD/MM/YYYY")
                              }
                              parseDate={(str) =>
                                moment(str, "DD/MM/YYYY").toDate()
                              }
                              placeholder="Tarikh Lahir"
                              value={moment(anak.tarikhLahir).toDate()}
                              onChange={(date) =>
                                this.handleChangeTarikhLahirAnakYatim(
                                  index,
                                  "tarikhLahir",
                                  date
                                )
                              }
                              outOfRangeMessage="Tarikh melebihi had"
                              disabled={loadingSubmit}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Jantina</b>
                          </td>
                          <td>:</td>
                          {/* <td>{anak.jantina === "L" ? "Lelaki" : "Perempuan"}</td> */}
                          <td>
                            <RadioGroup
                              inline
                              selectedValue={anak.jantina}
                              onChange={this.handleChangeJantinaAnakYatim}
                              name={`jantina-${index}`}
                              disabled={loadingSubmit}
                              //required
                            >
                              <Radio
                                data-index={index}
                                value="L"
                                checked={anak.jantina === "L"}
                              >
                                Lelaki
                              </Radio>
                              <Radio
                                data-index={index}
                                value="P"
                                checked={anak.jantina === "P"}
                              >
                                Perempuan
                              </Radio>
                            </RadioGroup>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Hubungan</b>
                          </td>
                          <td>:</td>
                          <td>
                            <input
                              type="text"
                              name="hubungan"
                              className="bp3-input bp3-fill"
                              placeholder="Hubungan dengan penjaga"
                              value={anak.hubungan || ""}
                              onChange={this.handleChangeAnakYatim}
                              data-index={index}
                              //required
                              autoComplete="off"
                              disabled={loadingSubmit}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Nama Sekolah</b>
                          </td>
                          <td>:</td>
                          <td>
                            <input
                              type="text"
                              name="namaSekolah"
                              className="bp3-input bp3-fill"
                              placeholder="Nama Sekolah"
                              value={anak.namaSekolah || ""}
                              onChange={this.handleChangeAnakYatim}
                              data-index={index}
                              //required
                              autoComplete="off"
                              disabled={loadingSubmit}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td />
                          <td />
                          <td>
                            <Button
                              minimal
                              small
                              intent="danger"
                              onClick={() => {
                                this.removeAnakYatim(anak._id);
                              }}
                            >
                              Hapus Rekod Anak Yatim
                            </Button>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
                <Button
                  style={{ float: "right", marginTop: 20, marginLeft: 15 }}
                  type="button"
                  onClick={closeFn}
                  disabled={loadingSubmit}
                >
                  Batal
                </Button>

                <Button
                  intent="primary"
                  style={{ float: "right", marginTop: 20 }}
                  icon="tick"
                  type="submit"
                  loading={loadingSubmit}
                >
                  Simpan
                </Button>

                {butiran && (
                  <Button
                    intent="success"
                    style={{ float: "right", marginTop: 20, marginRight: 15 }}
                    icon="tick"
                    type="button"
                    onClick={(e) => this.submit(e, true)}
                    loading={loadingSubmit}
                    value="selesai"
                  >
                    Simpan & Tanda Sebagai Selesai
                  </Button>
                )}
              </form>
            </div>
          )}
        </div>
      </Dialog>
    );
  };
}

export default Modal;
