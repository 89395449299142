// prettier-ignore

export const TOKEN_EXPIRE_MINUTE = 60;
export const TOKEN_EXPIRE_KEY = "yide_token_expire_time";
export const TOKEN_KEY = "yide_token";
export const JWT_KEY = "_yide_";
export const REPORT_KEY = "_yide_report_";
export const APP_VERSION = "3.0.0";

const API_URL_DEV = "http://localhost:4001";
const API_URL_PROD = "https://epayns.com.my/api";

export const API_URL =
  process.env.NODE_ENV === "production" ? API_URL_PROD : API_URL_DEV;

// nota: jangan ubah label ini.
// sebab dekat backend kita takde unique id atau code untuk mewakili values.
// jadi kita guna exact word untuk.
export const SEBAB_KEMATIAN = [
  "COVID-19",
  "KEMALANGAN",
  "PENYAKIT",
  "SAKIT TUA",
  "LAIN-LAIN",
];
